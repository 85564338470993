// @import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

@font-face {
font-family:'Noto Sans KR';
font-style: normal;
font-weight: 100;
src:url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2') format('woff2'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff') format('woff'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf') format('opentype');
}
@font-face {
font-family:'Noto Sans KR';
font-style: normal;
font-weight: 300;
src:url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2') format('woff2'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff') format('woff'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf') format('opentype');
}
@font-face {
font-family:'Noto Sans KR';
font-style: normal;
font-weight: 400;
src:url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2') format('woff2'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff') format('woff'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf') format('opentype');
}
@font-face {
font-family:'Noto Sans KR';
font-style: normal;
font-weight: 500;
src:url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2') format('woff2'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff') format('woff'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf') format('opentype');
}
@font-face {
font-family:'Noto Sans KR';
font-style: normal;
font-weight: 700;
src:url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2') format('woff2'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff') format('woff'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf') format('opentype');
}
@font-face {
font-family:'Noto Sans KR';
font-style: normal;
font-weight: 900;
src:url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2') format('woff2'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff') format('woff'),
	url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf') format('opentype');
}
	

@font-face {
	font-family:'ggcfStd';
	font-style: normal;
	font-weight: 900;
	src:url('/asset/common/fonts/ggcfStd-Black.ttf') format('truetype'),
		url('/asset/common/fonts/ggcfStd-Black.woff') format('woff'),
		url('/asset/common/fonts/ggcfStd-Black.otf') format('opentype');
}

@font-face {
	font-family:'ggcfStd';
	font-style: normal;
	font-weight: 400;
	src:url('/asset/common/fonts/ggcfStd-Book.ttf') format('truetype'),
		url('/asset/common/fonts/ggcfStd-Book.woff') format('woff'),
		url('/asset/common/fonts/ggcfStd-Book.otf') format('opentype');
}

* {
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details,
figure, figcaption, footer, header, hgroup,
nav, output, section,
time, mark, audio, video, select, input {
	margin: 0;
	padding: 0;
	border: 0;
	font-family: 'Noto Sans KR', Sans-serif;
	font-weight: 400;
	font-size: 16px;
	color:#333;
	vertical-align: baseline;
	-webkit-text-size-adjust: 100%;
}
html,body{
	width: 100%;
	// height: 100%;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
tr, th, td {
	vertical-align: middle;
}

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	font: 100%;
	color: #333;
	position: relative;
}
ol, ul, li {
	list-style: none;
}
ul::after,
ol::after,
.clb::after {
    content: "";
    display: block;
    clear: both;
}

li,
dl {
    float: left;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	text-decoration:none;
	color: #333;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
	&:hover{
		text-decoration: none;
	}
}
caption, legend {
	display: none;
}
input,select {
	font: 100%;
	outline: none;
	border-radius: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance:none;
	appearance:none;
}
select::-ms-expand {
   display: none;
}
input {
	border-radius: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
}
input[type="checkbox"],
input[type="radio"] {
	background: none;
	border: none;
	cursor: pointer;
}
input[type="submit"] {
	cursor: pointer;
}
strong {
	font-weight: bold;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color:transparent;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color:transparent;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color:transparent;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color:transparent;
}

input:-ms-input-placeholder { color: #ccc; }

input::-webkit-input-placeholder { color: #ccc; }

input::-moz-placeholder { color: #ccc; }

input::-moz-placeholder { color: #ccc; }

button{
	border: none;
}
button:focus{
	outline: none;
}

/*-------------------------------------------------------------------
    Color
-------------------------------------------------------------------*/

@pointColor:#003c71;
@textColor01: #666;
@textColor02: #888;
@textColor03: #999;
@textColor04: #3a3a3a;
@grey01:#ddd;
@grey02: #c4c4c4;
@grey03: #f4f4f4;
@bgColor01:#f8f8f8;
@red01: #f1386d;
@red02: #ff0000;
@blue01: #206ee6; 

@important: @pointColor;
@progress:#3c51ce;
@expected:#535f93;
@end: @grey02;
@play: #02aac0;
@display: #10b295;
@education: @blue01;
@event: #0098f1;
@news: #7d5fd2;

/*-------------------------------------------------------------------
    Variable
-------------------------------------------------------------------*/
@border01: 1px solid @grey01;
@border02: 2px solid @grey03;
@border03: 1px solid @grey03;
@border-pointColor: 1px solid @pointColor;

/*-------------------------------------------------------------------
    Mixin
-------------------------------------------------------------------*/
.bld {
    display: none ;
}

.after {
    content: ".";
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
}
.afters{
	&::after{
		.after;
	}
}

.bgo{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.fontA{
	font-family:'Noto Sans KR';
}

.fontB{
	font-family: 'ggcfStd', sans-serif;
}
.eFont{
	.fontB;
	font-weight: 900;
}

.inline-block{
	display: inline-block;
	font-size: 0;
}
.line-clamp-one{
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;	
}
.line-clamp(@line-height; @line-clamp){
	line-height: @line-height;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	max-height: @line-height * @line-clamp * 1em;
	-webkit-line-clamp: @line-clamp;
}
.list-width-single-row(@gap; @col){
	width: (100% - @gap*(@col - 1))/@col;
	margin-right: @gap;
	&:last-child{
		margin-right: 0;
	}
}

.list-width-multi-row(@gap; @col){
	width: (100% - (@gap*(@col - 1)))/@col;
	margin-right: @gap;
	&:nth-child(@{col}n){
		margin-right: 0;
	}
}
