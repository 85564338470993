/*-------------------------------------------------------------------
    파일정의 : Settings
    생성일자 : 2020-06-30 mangoajang
-------------------------------------------------------------------*/
@import 'reset_custom';

/*-------------------------------------------------------------------
    Globals
-------------------------------------------------------------------*/
@padding: 16px;
@minWidth: 320px;
@mainSectionBottom : 35px;
@subWrapBottom: 56px;
@swiperContainerBottom: 24px + 16px;
@headerH: 164px;
@listGap01: 4.1%;

.d_only{
    display: none !important;
}
.dt_only{
    display: none !important;
}

.m_only{
    display: block !important;
}

.wrap01{
    min-width: @minWidth;
    padding: 0 @padding;
    .afters;
}
.wrap02{
    .wrap01;
}
.wrap05{
    padding: 0 16.8%;
    margin: 0 auto;
    .afters;
}
.wrap06{
    .wrap01;
    padding: 0;
}
.navigationBtnCommon{
    width: 24px;
    height: 24px;
    border:@border-pointColor;
    .bgo;
    background-color: #fff;
    background-size: 6px;
    cursor: pointer;
    &.swiper-button-disabled,&.disabled{
        border: @border01;
    }
}
.navigationPrev{
    background-image: url(/asset/images/btn-arrow-onlyline-prev.png);
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-prev-dis.png);
    }
}
.navigationNext{
    background-image: url(/asset/images/btn-arrow-onlyline-next.png);
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-next-dis.png);
    }
}
.navigationPrevFirst{
    background-image: url(/asset/images/icn-w-pagination-line-arrow-first.png);
    background-size: 10px;
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-first-dis.png);
    }
}
.navigationNextLast{
    background-image: url(/asset/images/icn-w-pagination-line-arrow-last.png);
    background-size: 10px;
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-last-dis.png);
    }
}

@pvts-fontsize: 10px;
@pvts-lineheight: 1.6;
@pvts-tbordercolor: @grey01;
@pvts-tbgcolor: @bgColor01;
@pvts-tpadding: 12px 6px;
.post_view_txt_section{
    p,span,strong,b,u,a,i,div{
        line-height: @pvts-lineheight;
        font-weight: 500;
        font-size: @pvts-fontsize !important;
    }
    strong,b{
        font-weight: bold;
    }
    a{
        text-decoration: underline;
    }
    img,iframe,video{
        max-width: 100%;
    }
    table{
        width: 100%;
        margin: 10px 0;
        table-layout: fixed;
        th,td{
            font-size: @pvts-fontsize;
            border: 1px solid @pvts-tbordercolor;
            padding: @pvts-tpadding;
            word-break: keep-all;
            font-weight: 500;
            text-align: center;
        }
        th{
            font-weight: bold;
            background-color: @pvts-tbgcolor;
            p,span,strong,b,u,a,i,div{
                font-size: inherit;
                font-weight: inherit;
                word-break: keep-all;
                text-align: inherit;
            }
            strong,b{
                font-weight: bold;
            }
        } 
        td{
            line-height: @pvts-lineheight;
            p,span,strong,b,u,a,i,div{
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                word-break: keep-all;
                text-align: inherit;
            }
            strong,b{
                font-weight: bold;
            }
        }
    }
    //editor class
    .note-float-right,.note-float-left{
        margin: 10px;
    }
}

.badge_box{
    .badge{
        &.play{ //공연
            background-color: @play;
        }
        &.exhibition{ //전시
            background-color: @display;
        }
        &.event{ //행사
             background-color: @event;
        }
        &.education{ //교육
            background: @education;
        }
        &.news{ //소식
            background: @news;
        }
        &.important{ //중요
            background-color: @important;
        }
        &.ongoing{ //ongoing
            background-color: @important;
            .eFont;
        }
        &.progress{ //진행
            background-color: @progress;
        }
        &.expected{ //예정
            background-color: @expected;
        }
        &.end{ //종료
            background-color: @end;
        } 
    }
}


/*-------------------------------------------------------------------
    Plugin
-------------------------------------------------------------------*/
.swiper-container{
    padding-bottom: @swiperContainerBottom;
    .swiper-pagination{
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1px;
        .swiper-pagination-bullet{
            width: 10px;
            height: 10px;
            border: 1px solid white;
            background-color: white;
            opacity: 1;
            position: relative;
            margin: 0 2px;
            &:after{
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: @pointColor;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
            &.swiper-pagination-bullet-active{
                border: @border-pointColor;
            }
        }
    }
}

.sld_navigation{
    width: 200px;
    .afters;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 1;
    .btn_sld_prev,.btn_sld_next{
        .navigationBtnCommon;
    }
    .btn_sld_prev{
        float: left;
        .navigationPrev
    }
    .btn_sld_next{
        float: right;
        .navigationNext
    }
}
.sld_next,.sld_prev{
    .navigationBtnCommon
}
.sld_next{
    .navigationNext;
}
.sld_prev{
    .navigationPrev;
}
@root-font-size: 14px;
.ui-datepicker{
    width: 18em;
    font-size: @root-font-size;
    border-radius: 0;
    border: 1px solid #e8e8e8;
    padding: 0;
    .ui-widget-header{
        font-size: @root-font-size;
        border: none;
        border-radius: 0;
        background-color: white;
        padding: 0.813em 0.75em;
        background-color: @pointColor;
        .ui-datepicker-title{
            font-size: @root-font-size;
            span{
                .eFont;
                font-size: 1.5em;
                color: white;
            }
        }
        .ui-datepicker-next,.ui-datepicker-prev{
            font-size: @root-font-size;
            border: @border-pointColor;
            border-radius: 0;
            .bgo;
            background-size: 6px;
            background-color: white;
            width: 2.5em;
            height: 2.5em;
            top: 0.5em;
            span{
                .bld;
            }
        }
        .ui-datepicker-next{
            .navigationNext;
            right: 0.75em;
        }
        .ui-datepicker-prev{
            .navigationPrev;
            left: 0.75em;
            
        }
    }
    .ui-datepicker-calendar{
        font-size: @root-font-size;
        width: calc(100% - 0.625em*2);
        margin: 0.625em;
        thead{
            th{
                font-size: @root-font-size;
                background-color: @grey03;
                padding: .4em .3em;
                span{
                    display: block;
                    font-size: 0.813em;
                    .eFont;
                    color: @pointColor;
                    text-transform: uppercase;
                    line-height: 1;
                }
                span[title="Sunday"]{
                    color: @red01;
                }
                span[title="Saturday"]{
                    color: @blue01;
                }
            }
        }
        tbody{
            td{
                font-size: @root-font-size;
                .ui-state-default{
                    font-size: @root-font-size;
                    width: 1.75em;
                    height: 1.75em;
                    border: none;
                    background-color: #fff;
                    .eFont;
                    text-align: center;
                    padding: 0.375em 0;
                    margin: 0 auto;
                    line-height: 1;
                    &.ui-state-highlight{
                        background-color: inherit;
                        color: inherit;
                    }
                    &.ui-state-active{
                        background-color: @pointColor;
                        color: white !important;
                        border-radius: 50%;
                    }
                }
                &.ui-datepicker-week-end{
                    &:first-child{
                        .ui-state-default{
                            color: @red01;
                        }
                    }
                    &:last-child{
                        .ui-state-default{
                            color: @blue01;
                        }
                    }
                } 
            }
        }
    }
}

.mfp-bottom-bar{
    .bld;
}
.mfp-arrow{
    &::after{
        border: none;
        width: 36px;
        height: 36px;
        .bgo;
        background-size: 20px;
    }
}
.mfp-arrow-right{
    &::after{
        background-image: url(/asset/images/btn-w-pagination-line-arrow-white-next-act.png);
    }
}

.mfp-arrow-left{
    &::after{
        background-image: url(/asset/images/btn-w-pagination-line-arrow-white-prev-act.png);
    }
}
.mfp-close{
    &.btn_close{
        width: 20px;
        height: 20px;
        padding-right: 0;
        right: 0;
        top: 10px;
        background: url(/asset/images/icn-menu-close-white.png) no-repeat center/18px;
    }
}
/*-------------------------------------------------------------------
    Components
-------------------------------------------------------------------*/
/*inputs*/
input[type="text"]{
    &::placeholder{
        font-weight: inherit;
        font-size: inherit;
        color: inherit;
    }
}
.select_box01{
    width: 100%;
    height: 32px;
    padding: 0 16px;
    background: @grey03 url(/asset/images/object-arrow-down.png) no-repeat right 16px center/8px;
    font-size: 11px;
    .eFont;
    color: @pointColor;
    option{
        background-color: white;
    }
}
.select_box02{
    width: 100%;
    height: 32px;
    font-size: 12px;
    font-weight: 300;
    color: @pointColor;
    background:#fff url(/asset/images/object-arrow-down.png) no-repeat right 12px center/14px;
    padding: 0 12px;
}

.check_box{
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"]+label span {
        .inline-block;
        width: 14px;
        height: 14px;
        margin: -2px 0px 0 0;
        vertical-align: -3px;
        border-radius: 50%;
        background-color: #fff;
        border: @border-pointColor;
        cursor: pointer;
    }
    input[type="checkbox"]:checked+label span {
        background: @pointColor url(/asset/images/icn-check-checked-14-px.png) no-repeat center;
    }
    >span {
        font-size: 10px;
        font-weight: bold;
        color: @pointColor;
    }
}
.check_box02{
    .afters;
    font-size: 0;
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"]+label{
        display: block;
        float: left;
        width: 20px;
        height: 26px;
    }
    input[type="checkbox"]+label span {
        .inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background:#fff url(/asset/images/icn-check-unchecked-18-px.png) no-repeat center/8px;
        border: 1px solid #d8d8d8;
        cursor: pointer;
        position: relative;
        top: 3px;
    }
    input[type="checkbox"]:checked+label span {
        border: @border-pointColor;
        background-image: url(/asset/images/icn-check-checked-14-px.png);
        background-color: @pointColor;
    }
    >span {
        width: calc(100% - 20px);
        float: left;
        font-size: 9px;
        font-weight: 300;
        letter-spacing: -.23px;
        color: @textColor01;
        
    }
    &.big{
        input[type="checkbox"]+label{
            width: 22px;
        }
        input[type="checkbox"]+label span {
            width: 18px;
            height: 18px;
            background-size: 10px;
            top: -1px;
        }
        >span{
            width: calc(100% - 22px);
            font-size: 10px;
            font-weight: bold;
            color: @pointColor;
            .cmt{
                font-size: 9px;
                font-weight: inherit;
                color: inherit;
            }
        }
    }
}
.input_box01{
    border: @border03;
    background-color: @bgColor01;
    height: 24px;
    font-size: 0;
    input,select{
        width: 100%;
        height: 100%;
        padding: 0 8px;
        background-color: @bgColor01;
        font-size: 9px;
        font-weight: 300;
        color: @pointColor;
        &::placeholder{
            color: @grey02;
        }
    }
    select{
        padding: 0 24px 0 8px;
        background: @bgColor01 url(/asset/images/object-arrow-down.png) no-repeat right 8px center/8px;
        option{
            background-color: white;
        }
    }
    &.datepicker{
        input{
            padding: 0 26px 0 8px;
            background: @bgColor01 url(/asset/images/icn-calandar-act.png) no-repeat right 8px center/10px;
            color: @pointColor;
        }
    }
    &.width_button{
        padding-right: 80px;
        position: relative;
        button, label{
            position: absolute;
            top: -1px;
            right: 0;
        }
    }
    &.homepage{
        input{
            color: @textColor01;
        }
    }
}
.file_upload_box{
    .afters;
    .input_box01{
        float: left;
        width: 100%;
        margin-bottom: 5px;
        &:last-child{
            margin-bottom: 0;
        }
        input[type="file"]{
            .bld;
        }
    }
    .button_box{
        float: left;
        .bld;
        width: 24px;
        height: 24px;
        position: relative;
        button{
            display: block;
            width: 12px;
            height: 12px;
            background-color: @pointColor;
            .bgo;
            background-size: 8px;
            font-size: 0;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            cursor: pointer;
            &.btn_refresh{
                background-image: url(/asset/images/icn-write-attachfile-refresh.png);
            }
            &.btn_delete{
                background-image: url(/asset/images/icn-write-attachfile-delete.png);
            }
        }
    }
    &.show{
        .input_box01{
            width: calc(100% - 24px);
        }
        .button_box{
            display: block;
        }
    }
}
.option_box{
    border: 1px solid @grey03;
    background-color: @bgColor01;
    padding: 0 8px;
    font-weight: 300;
    text-align: left;
    height: 24px;
    font-size: 9px;
    letter-spacing: -.5px;
    &:active,&:focus{
        border: @border-pointColor;
    }
    &.select_date{
        width: 100%;
        color: @pointColor;
        .eFont;
        padding: 0 18px 0 8px;
        background: @bgColor01 url(/asset/images/icn-calandar-act.png) no-repeat right 8px center/10px;
        &::placeholder{
            font-weight: 300;
            .fontA;
            color: inherit;
        }
    }
    &.select_options{
        .fontA;
        background: @bgColor01 url(/asset/images/object-arrow-down.png) no-repeat right 8px center/8px;
    }
    &.select_search{
        height: 24px;
        &::placeholder{
            color: @grey01;
        }
    }
}

/*buttons*/
.btn_read_more{
    padding: 3px 0;
    .inline-block;
    .inner{
        .inline-block;
        border-bottom: @border-pointColor;
        padding-bottom: 2px;
        span{
            .eFont;
            font-size: 9px;
            color: @pointColor;
            &::after{
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                .bgo;
                background-size: 100%;
                background-image: url(/asset/images/btn-readmore-linearrow.png);
                margin-left: 3px;
                vertical-align: -2px;
            }
        }
        
    }
}
// .btn_read_more_hover{
//     background-color: @pointColor;
//     .inner{
//         border-bottom: 1px solid white;
//         span{
//             color: white;
//         }
//     }
// }
.btn_map{
    padding: 2px 3px 3px;
    background-color: @pointColor;
    border: @border-pointColor;
    span{
        font-size: 9px;
        font-weight: bold;
        color: white;
        &::after{
            content: '';
            .inline-block;
            width: 5px;
            height: 5px;
            .bgo;
            background-image: url(/asset/images/icn-btn-outlink.png);
            margin-left: 2px;
            vertical-align: 4px;
        }
    }
}
.btn_culture_event_view{
    .inline-block;
    padding: 2px 2px 3px;
    border: @border-pointColor;
    span{
        font-size: 9px;
        font-weight: bold;
        letter-spacing: -1px;
        color: @pointColor;
        &::after{
            content: '';
            .inline-block;
            width: 5px;
            height: 5px;
            margin-left: 2px;
            background: url(/asset/images/icn-arrow.png) no-repeat center/100%;
            vertical-align: 1px;
        }
    }
}
.btn_register{
    .inline-block;
    min-width: 68px;
    text-align: center;
    padding: 3px 3px 4px;
    background-color: @pointColor;
    span{
        font-size: 9px;
        font-weight: 500;
        letter-spacing: -.8px;
        color: white;
        &::before{
            content: '';
            .inline-block;
            width: 9px;
            height: 9px;
            background: url(/asset/images/icn-write.png) no-repeat center/100%;
            margin-right: 2px;
            vertical-align: -1px;
        }
    }
}

.btn_rg_request{
    .inline-block;
    padding: 2px 3px 3px;
    background-color: #fff;
    border: @border-pointColor;
    text-align: center;
    span{
        font-size: 9px;
        font-weight: 500;
        letter-spacing: -.5px;
        color: @pointColor;
    }
}
.btn01{
    .inline-block;
    padding: 7px 0;
    width: 112px;
    background-color: @pointColor;
    text-align: center;
    span{
        font-size: 12px;
        font-weight: 500;
        letter-spacing: .5px;
        color: white;
    }
}
.btn02{
    width: 80px;
    padding: 4px 0 5px;
    text-align: center;
    background-color: @pointColor;
    display: inline-block;
    cursor: pointer;
    span{
        display: block;
        font-size: 9px;
        font-weight: 500;
        color: #fff;
    }
}
.btn_file_add{
    .inline-block;
    padding: 2px 2px 3px;
    background-color: white;
    border: @border-pointColor;
    cursor: pointer;
    span{
        font-size: 9px;
        font-weight: bold;
        letter-spacing: -.6px;
        color: @pointColor;
        &::before{
            content: '';
            .inline-block;
            width: 9px;
            height: 9px;
            background: url(/asset/images/icn-write-navy.png) no-repeat center/100%;
            margin-right: 2px;
            vertical-align: -1px;
        }
    }
}
.btn_download{
    .inline-block;
    padding: 6px 6px 7px;
    background-color: @pointColor;
    text-align: center;
    span{
        font-size: 10px;
        font-weight: 500;
        letter-spacing: -.42px;
        color: white;
        &::before{
            content: '';
            .inline-block;
            width: 9px;
            height: 9px;
            background: url(/asset/images/icn-attachedfile-white.png) no-repeat center/7px;
            vertical-align: -1px;
            margin-right: 5px;
        }
    }
}
.btn_file_download{
    .inline-block;
    max-width: 100%;
    border-radius: 24px;
    background-color: @bgColor01;
    padding: 6px 14px;
    span{
        display: inline-block;
        max-width: 100%;
        font-size: 9px;
        font-weight: 300;
        .line-clamp-one;
        &::before{
            content: '';
            .inline-block;
            width: 8px;
            height: 8px;
            background: url(/asset/images/icn-attachedfile.png) no-repeat center/7px;
            margin-right: 5px;
            vertical-align: -1px;
        }
    }
}
.btn_top{
    position: fixed;
    right: 16px;
    bottom: 0;
    z-index: 10;
    padding-bottom: 16px;
    span{
        display: block;
        width: 24px;
        height: 24px;
        border: 1px solid @pointColor;
        background: white url(/asset/images/icn-w-top.png) no-repeat center/6px;
        transform: scale(0);
        transition: transform .2s ease-in;
        cursor: pointer;
    }
    &.show{
        span{
            transform: scale(1);
        }
    }
    &.sticky{
        position: absolute;
        bottom: auto;
        top: -(24px+16px);
    }
}
/*card_box*/
.card_box01{
    a{
        display: block;
        .afters;
        .img_box{
            .img{
                .bgo;
                background-color: lightgrey;
            }
        }
        figcaption{
            .group{
                font-size: 8px;
                font-weight: 500;
                color: @pointColor;
                margin-bottom: 5px;
                text-indent: 2px;
                .line-clamp-one;
            }
            .title,.subtitle{
                font-weight: 300;
                line-height: 1;
            }
            .desc{
                .bld;
            }
            
        }
        &.ver1{
            .img_box{
                .img{
                    padding-top: 48.6%;
                }
            }
            figcaption{
                padding-top: 10px;
                .title,.subtitle{
                    // font-size: 20px;
                    font-size: 16px;
                    
                }
                .title{
                    letter-spacing: -.5px;
                    .line-clamp(1.3;2);
                    margin-bottom: 10px;
                }
                .subtitle{
                    font-size: 12px;
                    margin-bottom: 10px;
                    white-space: nowrap;
                }
            }
        }
        &.ver2{
            .left{
                &.img_box{
                    .img{
                        padding-top: 96.8%;
                    }
                }
            }
            figcaption{
                padding-top: 5px;
                .title,.subtitle{
                    letter-spacing: -.23px;
                }
                .title{
                    .line-clamp(1.27;2);
                    font-size: 11px;
                    margin-bottom: 5px;
                }
                .subtitle{
                    font-size: 8px;
                    // margin-bottom: 5px;
                    .line-clamp(1.38;2);
                }
            }
        }
        &.ver3{
            .left,.right{
                float: left;
            }
            .left{
                width: 43.8%;
                &.img_box{
                    .img{
                        padding-top: 127.8%;
                    }
                }
            }
            .right{
                width: 56.2%;
                padding-left: 4%;
            }
            figcaption{
                .title,.subtitle{
                    letter-spacing: -.33px;
                }
                .title{
                    .line-clamp(1.31;2);
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                .subtitle{
                    font-size: 11px;
                    margin-bottom: 15px;
                    .line-clamp(1.45;2);
                }
            }
        }
    }
}
.card_box02{
    a{
        display: block;
        .img_box{
            position: relative;
            .img{
                .bgo;
                background-color: lightgrey;
                padding-top: 140%;
            }
            .badge{
                display: block;
                min-width: 28px;
                text-align: center;
                font-size: 10px;
                color: #fff;
                font-weight: 500;
                letter-spacing: .8px;
                line-height: 1;
                padding: 3px 2px 4px;
                position: absolute;
                left: 0;
                top: 0;
            }
            .on_going_badge{
                width: 56px;
                height: 56px;
                position: absolute;
                right: 0;
                bottom: 0;
                &::before{
                    content: '';
                    width: 0px;
                    height: 0px;
                    border-top: 56px solid transparent;
                    border-right: 56px solid @pointColor;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
                span{
                    .eFont;
                    font-size: 13px;
                    line-height: .84;
                    color: #fff;
                    text-align: right;
                    position: absolute;
                    right: 5px;
                    bottom: 7px;
                }
            }
        }
        figcaption{
            padding-top: 6px;
            .title{
                font-size: 14px;
                font-weight: 300;
                .line-clamp(1.3;2);
                margin-bottom: 5px;
            }
            >ul{
                >li{
                    float: none;
                    display: inline;
                    word-break: keep-all;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 1.4;
                    color: @textColor01;
                    &::after{
                        content: '';
                        .inline-block;
                        width: 1px;
                        height: 6px;
                        background-color: @grey01;
                        margin-left: 4px;
                        vertical-align: 1px;
                    }
                    &:last-child{
                        &::after{
                            .bld;
                        }
                    }    
                }
            }
            .info_box{
                li{
                    float: none;
                    &::after{
                        .bld;
                    }
                }
            }
            .location_box{
                li{
                    margin-right: 4px;
                }
                .city{
                    &::before{
                        content: '';
                        .inline-block;
                        width: 8px;
                        height: 11px;
                        .bgo;
                        background-image: url(/asset/images/icn-ongoing-pin.png);
                        margin-right: 3px;
                        vertical-align: -2px;
                    }
                }
            }
        }
    }
}
.card_box03{
    a{
        display: block;
        .afters;
        .left,.right{
            float: left;
        }
        .left{
            width: 30.6%;
        }
        .right{
            width: 69.4%;
            padding-left: 4%;
        }
        .img_box{
            .img{
                .bgo;
                background-color: lightgrey;
                padding-top: 96.6%;
            }
        }
        figcaption{
            .title,.subtitle{
                font-weight: 300;
                line-height: 1.33;
                letter-spacing: -.25px;
            }
            .title{
                font-size: 12px;
                margin-bottom: 5px;
                .line-clamp(1.33;3);
            }
            .subtitle{
                font-size: 10px;
                .line-clamp-one;
            }
        }

    }
}
.card_box04{
    a{
        display: block;
        .img_box{
            .img{
                padding-top: 134.6%;
                .bgo;
                background-color: lightgrey;
            }
        }
        figcaption{
            padding-top: 5px;
            text-align: center;
            .title{
                .eFont;
                font-size: 13px;
                line-height: 1;
                margin-bottom: 5px;
            }
            .subtitle{
                font-size: 10px;
                font-weight: 300;
                letter-spacing: -.21px;
            }
        }
    }
}
.card_box05{
    a{
        display: block;
        .img_box{
            .img{
                padding-top: 96.4%;
                .bgo;
                background-color: lightgrey;
            }
        }
        figcaption{
            padding-top: 8px;
            .title{
                font-size: 14px;
                font-weight: 300;
                letter-spacing: -.7px;
                margin-bottom: 5px;
                .line-clamp(1.29;2)
            }
            .subtitle{
                font-size: 10px;
                font-weight: 500;
                color: @textColor01;
                letter-spacing: -.2px;
                .line-clamp(1.4;2);
            }

        }
    }
}
.card_box06{
    a{
        display: block;
        .img_box{
            .img{
                .bgo;
                background-color: lightgrey;
                padding-top: 96.4%;
            }
        }
        figcaption{
            padding-top: 10px;
            .title{
                font-size: 14px;
                font-weight: 300;
                letter-spacing: -.7px;
                .line-clamp(1.29;2);
                margin-bottom: 5px;
            }
            .subtitle{
                font-size: 10px;
                font-weight: 500;
                color: @textColor01;
                letter-spacing: -.2px;
                .line-clamp(1.4;2);
            }
        }
    }
}
/*box*/
.box01{
    display: block;
    border-bottom: @border01;
    background-color: white;
    padding: 5px 8px 6px;
    .badge_box{
        margin-bottom: 3px;
        .badge{
            padding: 0 4px 1px;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: -.8px;
            color: white;
            margin-right: 3px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .title{
        font-size: 11px;
        font-weight: 300;
        letter-spacing: -.62px;
        .line-clamp-one;
    }
    .date{
        .bld;
    }
    &.impt{
        background-color: @bgColor01;
    }
}
.box02{
    display: inline-block;
    border: 1px solid @grey01;
    background-color: @bgColor01;
    padding: 7px 0 8px;
    text-align: center;
    span{
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -.5px;
    }
}
.list_box02{
    display: block;
    width: 100%;
    padding: 10px 5px;
    border-bottom: @border01;
    .afters;
    .col{
        float: left;
    }
    .img_box{
        width: 15%;
        .img{
            width: 100%;
            padding-top: 100%;
            .bgo;
            background-color: lightgrey;
        }
    }
    .info_box{
        width: 85%;
        padding-left: 8px;
        .kind{
            font-size: 10px;
            font-weight: 500;
            letter-spacing: .11px;
            color: @pointColor;
        }
        .title{
            font-size: 11px;
            font-weight: 500;
            letter-spacing: -.3px;
            .line-clamp(1.45;2);
            margin-bottom: 3px;
        }
        ul{
            li{
                font-size: 9px;
                font-weight: 300;
                margin-right: 10px;
                &::after{
                    content: '';
                    .inline-block;
                    width: 1px;
                    height: 6px;
                    background-color: @grey03;
                    margin-left: 10px;
                }
            }
        }
    }
    .date_box{
        .bld;
    }
}
.list_box03{
    .left{
        .title{
            .eFont;
            font-size: 13px;
            margin-bottom: 10px;
        }
    }
    .right{
        >p{
            letter-spacing: -.5px;
        }
        .img_box{
            margin-bottom: 10px;
            img{
                display: block;
            }
        }
        .color_box{
            width: 150px;
            border: 1px solid @pointColor;
            .title{
                padding: 4px 10px 6px;
                background-color: @pointColor;
                p{
                    .eFont;
                    font-size: 14px;
                    color: #fff;
                }
            }
            .cont{
                padding: 15px 10px;
                background-color: #fff;
                p{
                    font-size: 10px;
                    font-weight: 500;
                    color: @pointColor;
                    line-height: 1.4;
                }
            }
        }
    }
}
.img_sld_box{
    display: block;
    height: 56px;
    img{
        width: auto;
        height: 100%;
    }
}
/*tabs*/
.tab01{
    text-align: center;
    .inner{
        border-bottom: 1px solid @grey02;
        >ul,.btn_read_more{
            vertical-align: middle
        }
        ul{
            .inline-block;
            li{
                a{
                    display: block;
                    font-size: 11px;
                    font-weight: 500;
                    color: @textColor03;
                    padding: 5px 0;
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    box-shadow: 0 0 1px transparent;
                    position: relative;
                    &::before{
                        content: "";
                        position: absolute;
                        z-index: 1;
                        left: 50%;
                        right: 50%;
                        bottom: -1px;
                        background: @pointColor;
                        height: 2px;
                        transition-property: left, right;
                        transition-duration: 0.2s;
                        transition-timing-function: ease-out;
                    }
                    &.on{
                        color: @pointColor;
                        &::before{
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
        .btn_read_more{
            margin-right: 4px;
            width: 17px;
            height: 17px;
            .bgo;
            background-image: url(/asset/images/m-btn-news-readmore.png);
            .inner{
                .bld;
            }
        }
    }
}
.tab02{
    font-size: 0;
    text-align: right;
    .inner{
        .inline-block;
        >ul,.btn_map{
            .inline-block;
            vertical-align: middle;
        }
        >ul{
            margin-right: 28px;
            li{
                margin-right: 8px;
                &::after{
                    content: '';
                    .inline-block;
                    width: 1px;
                    height: 8px;
                    background-color: @grey03;
                    margin-left: 8px;
                    vertical-align: 3px;
                }
                &:last-child{
                    margin-right: 0;
                    &::after{
                        .bld;
                    }
                }
                a{
                    font-size: 13px;
                    color: @grey01;
                    font-weight: bold;
                    &::before{
                        content: '';
                        .inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                        vertical-align: -3px;
                        .bgo;
                    }
                    &.list{
                        &::before{
                            background-image: url(/asset/images/icn-ongoing-list-dis.png);
                            background-size: 14px;
                        }
                    }
                    &.calender{
                        &::before{
                            background-image: url(/asset/images/icn-calandar-dis.png);
                            background-size: 14px;
                        }
                    }
                    &.on{
                        color: @pointColor;
                        &.list{
                            &::before{
                                background-image: url(/asset/images/icn-ongoing-list-act.png);
                            }
                        }
                        &.calender{
                            &::before{
                                background-image: url(/asset/images/icn-calandar-act.png);
                            }
                        }
                    }
                }
            }
        }
    }
}
/*panels*/
.panel01{
    .panel_tit{
        padding-bottom: 10px;
        text-align: center;
        p{
            .eFont;
            font-size: 24px;
            letter-spacing: 0.92px;
            color: @pointColor;
        }
    }
}
.panel02{
    .panel_tit{
        font-size: 15px;
        font-weight: bold;
        color: @pointColor;
        margin-bottom: 5px;
    }
    .panel_cont{
        p{
            font-size: 10px;
            font-weight: 500;
            line-height: 1.6;
            span{
                font-weight: inherit;
                line-height: inherit;
                font-size: inherit;
                &.strong{
                    font-weight: bold;
                }
                &.point{
                    font-weight: bold;
                    color: @pointColor;
                    &.eFont{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.panel03{
    border-top: @border02;
    border-bottom: @border02;
    padding: 15px 0 20px;
    text-align: center;
    .panel_tit{
        .title{
            font-size: 13px;
            font-weight: bold;
            color: @pointColor;
            .num{
                display: block;
                font-size: 15px;
                .eFont;
                color: inherit;
            }
        }
        .subtitle{
            font-size: 10px;
            font-weight: 500;
            line-height: 1.6;
            margin-top: 5px;
            word-break: keep-all;
        }
        .cmt{
            font-size: 9px;
            font-weight: 500;
            color: @textColor01;
        }
    }
    .panel_cont{
        padding-top: 20px;
    }
}
.panel04{
    .panel_tit{
        padding-bottom: 10px;
        text-align: center;
        .title{
            font-size: 14px;
            font-weight: bold;
            color: @pointColor;
        }
    }
    .panel_cont{
        border-top: @border-pointColor;
        border-bottom: @border-pointColor;
    }
}
.panel05{
    .panel_tit{
        font-size: 13px;
        font-weight: bold;
        letter-spacing: -.7px;
        margin-bottom: 8px;     
    }
}
/*tables*/
.table01{
    border-top: @border-pointColor;
    border-bottom: @border-pointColor;
    .tr{
        display: block;
        border-top: 1px solid @grey02;
        padding: 0 6px;
        .afters;
        &:first-child{
            border-top: none;
        }
        &.thead{
            .bld;
        }
        .th,.td{
            float: left;
            text-align: center;
            letter-spacing: -.6px;
            padding: 4px 0;
            font-size: 11px;
        }
        .td{
            font-weight: 300;
            .badge_box{
                .badge{
                    display: inline-block;
                    padding: 1px 8px 2px;
                    font-size: 9px;
                    color: white;
                    min-width: 28px;
                    text-align: center;
                    margin-right: 5px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            &:nth-child(2){
                text-align: left;
                .title{
                    font-weight: inherit;
                    p{
                        .inline-block;
                        max-width: 100%;
                        font-size: 11px;
                        padding-right: 10px;
                        position: relative;
                        .line-clamp-one;
                    }
                }
            }
            &.new{
                &:nth-child(2){
                    .title{
                        p{
                            &::after{
                                content: '';
                                .inline-block;
                                width: 8px;
                                height: 8px;
                                background: url(/asset/images/icn-new.png) no-repeat center/100%;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
        &.impt{
            background-color: @bgColor01;
        }
    }
}
.table02{
    border-top: @border-pointColor;
    border-bottom: @border-pointColor;
    .tr{
        display: block;
        border-top: 1px solid @grey02;
        padding: 10px 0;
        position: relative;
        &.thead{
            .bld;
        }
        &:nth-child(2){
            border-top: none;
        }
        .td{
            font-size: 11px;
            letter-spacing: -.62px;
            font-weight: 300;
            .badge_box{
                font-size: 0;
                margin-bottom: 2px;
                .badge{
                    display: inline-block;
                    padding: 0 0 1px;
                    font-size: 10px;
                    color: white;
                    letter-spacing: -.8px;
                    min-width: 28px;
                    text-align: center;
                }
            }
            &:nth-child(3){
                p{
                    font-size: inherit;
                    letter-spacing: inherit;
                    font-weight: inherit;
                    .line-clamp-one;
                }
            }
            .edit_buttons{
                li{
                    &::after{
                        content: 'I';
                        font-size: 10px;
                        color: @grey03;
                    }
                    &:last-child{
                        &::after{
                            .bld;
                        }
                    }
                    button{
                        padding: 5px;
                        font-size: 10px;
                        color: @pointColor;
                        font-weight: bold;
                        background-color: transparent;
                        cursor: pointer;
                    }
                }
            }
            
        }
    }
}
.table03{
    table-layout: fixed;
    width: 100%;
    border-top: 1px solid @pointColor;
    th,td{
        padding: 10px 5px;
        border-bottom:  1px solid @grey01;
        font-size: 11px;
        letter-spacing: -.7px;
        text-align: center;
    }
    th{
        background-color: @bgColor01;
        font-weight: 500;
    }
    td{
        font-weight: 300;
        word-break: keep-all;
        a{
            font-weight: inherit;
            letter-spacing: inherit;
            font-size: inherit;
            text-decoration: underline;
            word-break: break-all;
        }
        &.tal{
            text-align: left;
        }
    }
    &.tdrow{
        td{
            padding: 10px 10px; 
        }
    }
}
/*lists*/
.list01{
    li{
        float: none;
        font-size: 8px;
        font-weight: 300;
        margin-bottom: 5px;
        position: relative;
        padding-left: 5px;
        word-break: keep-all;
        &:last-child{
            margin-bottom: 0;
        }
        &::before{
            content: '';
            display: block;
            width: 2px;
            height: 2px;
            background-color: @grey02;
            position: absolute;
            left: 0;
            top: 8px;
        }
        a{
            font-size: inherit; //ios
            font-weight: inherit; //ios
        }
    }
}
.list02{
    li{
        float: none;
        font-size: 8px;
        font-weight: 300;
        margin-bottom: 5px;
        position: relative;
        padding-left: 6px;
        word-break: keep-all;
        &:last-child{
            margin-bottom: 0;
        }
        &::before{
            content: '';
            display: block;
            width: 2px;
            height: 2px;
            background-color: @grey02;
            position: absolute;
            left: 0;
            top: 7px;
        }
    }
}
/*pagination*/
.paging_area{
    text-align: center;
    .pagination{
        .inline-block;
        >div{
            .inline-block;
            vertical-align: middle;
        }
        .prev,.next{
            .navigationBtnCommon
        }
        .prev{
            .navigationPrev;
        }
        .next{
            .navigationNext;
        }
        .num{
            margin: 0 10px;
            a{
                .inline-block;
                width: 10px;
                height: 10px;
                border: 1px solid white;
                border-radius: 50%;
                background-color: white;
                position: relative;
                margin-right: 2px;
                &:last-child{
                    margin-right: 0;
                }
                &:after{
                    content: '';
                    display: block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: @pointColor;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
                &.on{
                    border: @border-pointColor;
                }
            }
        }
    }
    .pagination02{
        .inline-block;
        >div{
            .inline-block;
            vertical-align: middle;
        }
        .prev,.next,.prev_first,.next_last{
            .navigationBtnCommon
        }
        .prev{
            .navigationPrev;
        }
        .prev_first{
           .navigationPrevFirst;
            margin-right: 4px;
        }
        .next{
            .navigationNext;
            margin-right: 4px;
        }
        .next_last{
           .navigationNextLast;
        }
        .num{
            margin: 0 20px;
            a{
                .inline-block;
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
                span{
                    .eFont;
                    font-size: 10px;
                    color: @grey02;
                }
                &.on{
                    span{
                        color: @pointColor;
                    }
                }
            }
        }
    }
}
/*header*/
header{
    background-color: #fff;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    .wrap01{
        position: relative;
    }
    .btn_ham{
        display: block;
        width: 20px;
        height: 14px;
        position: absolute;
        right: @padding + 5px;
        top: 18px;
        z-index: 20;
        cursor: pointer;
        &::before{
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .2s ease-in;
        }
        span{
            display: block;
            width: 100%;
            height: 1px;
            background-color: @pointColor;
            position: absolute;
            left: 0;
            transition: top .2s ease-in, transform .2s ease-in, background-color .2s ease-in;
            &:nth-child(1){
                top: 0;
            } 
            &:nth-child(2){
                top: calc(50% - 1px);
            }
            &:nth-child(3){
                top: calc(100% - 1px);
            }
        }
        &.close{
            span{
                &:first-child {
                    top: 50%;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    width: 0;
                    // left: 20px;
                }

                &:last-child {
                    top: 50%;
                    transform: rotate(-45deg);
                }
            }
        }
        &.close_ver1{
            .close;
            // &::before{
            //     // background-color: @grey03;
            //     opacity: 1;
            // }
            span{
                background-color: #fff;
            }
        }
    }
    .btn_friends_close{
        visibility: hidden;
        width: 30px;
        height: 30px;
        position: absolute;
        right: @padding;
        top: 10px;
        z-index: 20;
        cursor: pointer;
        background-color: @pointColor;
        padding: 8px 5px;
        .inner{
            width: 20px;
            height: 14px;
            position: relative;
            span{
                display: block;
                width: 100%;
                height: 1px;
                background-color: white;
                position: absolute;
                left: 0;
                transition: top .3s ease-in, transform .3s ease-in;
                &:nth-child(1){
                    top: 0;
                }
                &:nth-child(2){
                    top: calc(50% - 1px);
                }
                &:nth-child(3){
                    top: calc(100% - 1px);
                }
            }
        }
       &.show{
            visibility: visible;
            span{
                &:first-child {
                    top: 50%;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    width: 0;
                    // left: 20px;
                }

                &:last-child {
                    top: 50%;
                    transform: rotate(-45deg);
                }
            }
       }
    }
    .top_gnb_area{
        .afters;
        padding: 10px 0 0;
        >div{
            float: right;
            >ul{
                .inline-block;
                margin-right: 20px + 14px + 5px;
                li{
                    margin-right: 14px;
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: -.33px;
                        color: @pointColor;
                    }
                    &.lang{
                        >a{
                            color: @grey01;
                            &::after{
                                content: ' I ';
                                color: @grey01;
                            }
                            &:last-child{
                                &::after{
                                    .bld;
                                }
                            }
                            &.on{
                                color: @pointColor;
                            }
                        }
                    }
                }
            }
        }
    }
    .main_gnb_area{
        font-size: 0;
        padding-bottom: 14px - 5px;
        nav{
            display: inline-block;
            vertical-align: text-bottom;
            ul{
                li{
                    float: none;
                    display: inline-block;
                    font-size: 0;
                    vertical-align: text-bottom;
                    // margin-right: 10px;
                    margin-right: 5px;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:first-child{
                        margin-right: 5px;
                    }
                    a{
                        display: block;
                        // font-size: 19px;
                        font-size: 23px;
                        font-weight: 500;
                        letter-spacing: -.8px;
                        color: @pointColor;
                        text-transform: capitalize;
                        &.on{
                            color: #fff;
                            background-color: @pointColor;
                        }
                    }
                    .logo{
                        a{
                            display: block;
                            // width: 40px;
                            // height: 44px;
                            width: 60px;
                            height: 64px;
                            img{
                                display: block;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .search_area{
        border-top: @border-pointColor;
        border-bottom: @border-pointColor;
        padding: 2px 0;
        .inner{
            position: relative;
            padding: 0 2px;
            .ggc{
                width: 0;
                height: 18px;
                background-color: @pointColor;
                position: absolute;
                top: 0;
                left: 0;
                transition: width .3s ease-in;
                transition-delay: .2s;
                overflow: hidden;
                span{
                    position: relative;
                    top: -7px;
                    font-size: 16px;
                    .eFont;
                    color: white;
                    line-height: 1;
                    letter-spacing: 2px;
                }
            }
            form{
                .afters;
                input[type="text"]{
                    float: left;
                }
                input[type="text"]{
                    width: calc(100% - 25px);
                    height: 18px;
                    text-align: right;
                    padding: 0 10px 0 (43px+10px);
                    font-size: 12px;
                    font-weight: 500;
                    color: @pointColor;
                    &:focus{
                        outline: none;
                    }
                }
                .btn_search{
                    float: right;
                    padding: 0; //ios
                    margin: 0; //ios
                    cursor: pointer;
                    span{
                        width: 18px;
                        height: 18px;
                        display: block;
                        background: @pointColor url(/asset/images/icn-zoom.png) no-repeat center/12px;
                    }
                }
            }
        }
        &.sticky{
            .inner{
                .ggc{
                    width: 43px;
                }
            }
        }
    }
    .friends{
        .bld;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background-color: white;
        z-index: 10;
        outline: @border-pointColor;
        padding-top: 10px;
        .check_option_box{
            padding: 5px 0;
            border-bottom: 1px solid @grey02;
        }
        .friends_list_box{
            padding-top: 10px;
            .keyword{
                .eFont;
                font-size: 24px;
                color: @pointColor;
            }
            >a{
                display: inline-block;
               font-size: 14px;
               font-weight: 500;
               color: @textColor01;
               letter-spacing: -.84px;
               margin-right: 5px;
               word-break: keep-all;
            }
        }

    }
    .site_map{
        .bld;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background-color: #0e4278;
        z-index: 10;
        .wrap01{
            padding: 0;
            .group1{
                padding: 40px @padding 16px;
                background-color: #093766;
                a{
                    .inline-block;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    &:last-child{
                        margin-right: 0;
                    }
                    span{
                        font-size: 20px;
                        font-weight: 300;
                        letter-spacing: 0.59px;
                        line-height: 1;
                        color: white;
                        text-transform: capitalize;
                    }
                    &.impt{
                        span{
                            // font-weight: bold;
                        }
                    }
                    
                }
            }
            .group2{
                padding: 16px @padding;
                background-color: #003c71;
                ul{
                    width: 100%;
                    li{
                        width: (100% - 10%)/2;
                        margin-bottom: 10px;
                        margin-right: 10%;
                        height: 44px;
                        &:nth-child(2n){
                            margin-right: 0; 
                        }
                        a{
                            .inline-block;
                            .title{
                                .inline-block;
                                .eFont;
                                font-size: 20px;
                                color: white;
                                margin-bottom: 2px;
                            }
                            .subtitle{
                                font-size: 9px;
                                font-weight: 500;
                                color: #a5d0fd;
                            }
                        }
                    }
                }
            }
            .group3{
                padding: 16px @padding;
                background-color: #0e4278;
                >dl{
                    float: none;
                    margin-bottom: 15px;
                    .afters;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    dt{
                        a{
                            font-size: 20px;
                            font-weight: 300;
                            letter-spacing: .59px;
                            color: white;
                            line-height: 1;
                        }
                    }
                    dd{
                        ul{
                            li{
                                margin-right: 5px;
                                &::after{
                                    content: '';
                                    .inline-block;
                                    width: 2px;
                                    height: 2px;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    margin-left: 5px;
                                    vertical-align: 5px;
                                }
                                &:last-child{
                                    margin-right: 0;
                                    &::after{
                                        .bld;
                                    }
                                }
                                a{
                                    font-size: 13px;
                                    font-weight: 500;
                                    color: white;
                                    letter-spacing: -.36px;
                                    position: relative;
                                }
                            }
                        }   
                    }
                }
                >ul{
                    margin-top: 25px;
                    li{
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0;
                        }
                        a{
                            font-size: 12px;
                            letter-spacing: -.33px;
                            color: white;
                        }
                        &.lang{
                            >a{
                                color: #999;
                                &::after{
                                    content: ' I ';
                                    color: #999;
                                }
                                &:last-child{
                                    &::after{
                                        .bld;
                                    }
                                }
                                &.on{
                                    color: white;
                                }
                            }
                        }
                    }
                    &.mode{
                        .login_after{
                            .bld;
                        }
                        &.login{
                            .login_before{
                                .bld;
                            }
                            .login_after{
                                display: block;
                            }
                        }
                    } 
                }
            }
        }
    }
    &.sticky{
        position: fixed;

        .main_gnb_area{
            .bld;
        }
        .search_area{
            .search_area.sticky;
        }
    }
}
/*footer*/
footer{
    border-top: @border01;
    padding: 26px 0 29px;
    text-align: center;
    position: relative;
    .g1{
        .ft_logo{
            margin-bottom: 10px;
            a{
                font-size: 0;
                display: inline-block;
                height: 43px;
                &:nth-child(1){
                    margin-right: 10px;
                }
                &:nth-child(2){
                    pointer-events: none;
                }
                img{
                    display: block;
                    margin: 0 auto;
                    height: 100%;
                }
            }
        }
    }
    .g2{
        &.left{
            margin-bottom: 10px;
            // .company{
            //     font-size: 10px;
            //     font-weight: bold;
            //     color: @pointColor;
            //     margin-bottom: 5px;
            //     a{
            //         display: inline-block;
            //         border-bottom: 1px solid #a8a8a8;
            //         margin-left: 15px;
            //         font-size: 0;
            //         vertical-align: 1px;
            //         span{
            //             font-size: 9px;
            //             letter-spacing: -.54px;
            //             color: #a8a8a8;
            //             &::after{
            //                 content: '';
            //                 display: inline-block;
            //                 width: 4px;
            //                 height: 4px;
            //                 background: url(/asset/images/icn-btn-outlink-gray.png) no-repeat center/100%;
            //                 margin-left: 2px;
            //                 vertical-align: 3px;
            //             }
            //         }
            //     }
            // }
            // address{
            //     font-style: normal;
            //     color: @textColor01;
            //     font-size: 9px;
            //     font-weight: 500;
            //     word-break: keep-all;
            //     margin-bottom: 5px;
            //     line-height: 1.67;
            //     a{
            //         font-size: inherit;
            //         font-weight: inherit;
            //         color: inherit;
            //         pointer-events: none;
            //     }
            // }
            // .copyrights{
            //     .eFont;
            //     font-size: 10px;
            //     color: @textColor01;
            // }
            address{
                // max-width: 560px;
                font-style: normal;
                font-size: 10px;
                font-weight: 500;
                line-height: 1.4;
                color: @pointColor;
                margin: 0 auto 10px;
                a,span{
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    color: inherit;
                    white-space: nowrap;
                }
            }
            .ft_list{
                display: inline-block;
                li{
                    margin-right: 8px;
                    &::after{
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 5px;
                        background-color: #e8e8e8;
                        margin-left: 5px;
                    }
                    &:last-child{
                        &::after{
                            .bld;
                        }
                    }
                    a{
                        font-size: 9px;
                        font-weight: bold;
                        color: @textColor03;
                    }
                }
            }
        }
        &.right{
            .select_box01{
                width: 138px;
            }
        }
    }

}
/*popup*/
.layer_popup{
    .bld;   
    width: 100%;
    min-width: @minWidth;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.86);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    .popup_contents{
        max-width: calc(100% - @padding*2);
        width: 100%;
        position: absolute;
        left: 50%;
        top: 100px;
        transform: translateX(-50%);
        background-color: #fff;
        .btn_close{
            width: 24px;
            height: 24px;
            background: @grey03 url(/asset/images/icn-menu-close-navy.png) no-repeat center/10px;
            cursor: pointer; 
            position: absolute;
            right: 0;
            top: 0;
        }
        .title_box{
            padding: 30px 0;
            text-align: center;
            background: @pointColor url(/asset/images/img-ggc-popup-bg.png) no-repeat center/100%;
            p{
                font-size: 20px;
                font-weight: bold;
                color: white;
            }
        }
        .contents_box{
            padding: 20px @padding 30px;
        }
    }
    &.signup_notice{
        .contents_box{
            .desc{
                margin-bottom: 20px;
                p{
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 1.6;
                    word-break: keep-all;
                }
            }
            .panel03{
                margin-bottom: 15px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
            .benefits{
                padding: 15px 0 5px;
                .panel_cont{
                    font-size: 0;
                    padding-left: 10%;
                    padding-right: 10%;
                    ul{
                        width:100%; 
                        li{
                            width: 50%;
                            margin-bottom: 15px;
                            &:nth-child(1){
                                .icon{
                                    background-image: url(/asset/images/pic-join-01.png);
                                }
                            }
                            &:nth-child(2){
                                .icon{
                                    background-image: url(/asset/images/pic-join-02.png);
                                }
                            }
                            &:nth-child(3){
                                .icon{
                                    background-image: url(/asset/images/pic-join-03.png);
                                }
                            }
                            &:nth-child(4){
                                .icon{
                                    background-image: url(/asset/images/pic-join-04.png);
                                }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            .icon{
                                .inline-block;
                                width: 48px;
                                height: 48px;
                                .bgo;
                                background-size: 100%;
                            }
                            .text{
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 1.4;
                                color: @pointColor;
                            }
                        }
                    }
                }
            }
            .application_form_guide{
                .panel_cont{
                    text-align: left;
                    dl{
                        float: none;
                        margin-bottom: 10px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        .afters;
                        dt,dd{
                            font-size: 10px;
                        }
                        dt{
                            font-weight: 500;
                            margin-bottom: 2px;
                        }
                        dd{
                            font-weight: 300;
                            ul{
                                li{
                                    float: none;
                                    font-size: 10px;
                                    font-weight: 300;
                                    padding-left: 5px;
                                    position: relative;
                                    margin-bottom: 3px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    &::before{
                                        content: '';
                                        display: block;
                                        width: 2px;
                                        height: 2px;
                                        background-color: @grey02;
                                        position: absolute;
                                        left: 0;
                                        top: 7px;
                                    }
                                }
                            }
                            a{
                                font-size: inherit; //ios
                                font-weight: inherit; //ios
                            }
                        }
                    }
                }
            }
            .closing_ment{
                margin-top: 20px;
                font-size: 11px;
                font-weight: 500;
                line-height: 1.36;
                text-align: center;
                .point{
                    color: @pointColor;
                    font-size: inherit;
                    font-weight: inherit;
                }
            }
        }
    }
    &.contents_request{
        .contents_box{
            .panel03{
                margin-bottom: 16px;
                padding-top: 0;
                border-top: none;
                &:last-child{
                    margin-bottom: 40px;
                }
                .panel_tit{
                    .title{
                        font-size: 15px;
                    }
                }
                &.form_download{
                    .panel_cont{
                        padding-top: 0;
                        .category_list{
                            .inline-block;
                            >li{
                                width: 100%/2;
                                padding: 14px 0;
                                text-align: center;
                                position: relative;
                                &::after{
                                    content: '';
                                    display: block;
                                    width: 1px;
                                    height: 104px;
                                    background-color: @grey03;
                                    position: absolute;
                                    right: 0;
                                    top: 14px;
    
                                }
                                &::before{
                                    content:'';
                                    display: block;
                                    width: calc(100% - 12px);
                                    height: 1px;
                                    background-color: @grey03;
                                    position: absolute;
                                    top: 0;
                                }
                                &:nth-child(odd){
                                    padding-right: 12px;
                                    &::before{
                                        left: 0;
                                    }
                                }
                                &:nth-child(even){
                                    padding-left: 12px;
                                    &::after{
                                        .bld;
                                    }
                                    &::before{
                                        right: 0;
                                    }
                                }
                                &:nth-child(-n+2) {
                                    &::before{
                                        .bld;
                                    }
                                }
                                .category{
                                    font-size: 12px;
                                    font-weight: 500;
                                    color: @pointColor;
                                    margin-bottom: 5px;
                                }
                                .icon{
                                    .inline-block;
                                    width: 36px;
                                    height: 36px;
                                    margin-bottom: 5px;
                                    .bgo;
                                    &.display{
                                        background-image: url(/asset/images/pic-request-01.png);
                                        background-size: 32px;
                                    }
                                    &.play{
                                        background-image: url(/asset/images/pic-request-02.png);
                                        background-size: 25px;
                                    }
                                    &.event{
                                        background-image: url(/asset/images/pic-request-03.png);
                                        background-size: 30px;
                                    }
                                    &.education{
                                        background-image: url(/asset/images/pic-request-04.png);
                                        background-size: 36px;
                                    }
                                    &.news{
                                        background-image: url(/asset/images/pic-request-05.png);
                                        background-size: 24px;
                                    }
                                    &.modification_request{
                                        background-image: url(/asset/images/pic-request-06.png);
                                        background-size: 28px;
                                    }
                                }
                                .download_list{
                                    >li{
                                        float: none;
                                        margin-bottom: 5px;
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                        a{
                                            display: block;
                                            font-size: 10px;
                                            font-weight: 500;
                                            letter-spacing: -.42px;
                                            text-decoration: underline;
                                            &::before{
                                                content: '';
                                                display: inline-block;
                                                width: 14px;
                                                height: 14px;
                                                vertical-align: -3px;
                                                margin-right: 5px;
                                                .bgo;
                                                background-size: 100%;
                                                
                                            }
                                            &.hwp{
                                                &::before{
                                                    background-image: url(/asset/images/icn-download-hangul.png);
                                                }
                                            }
                                            &.docx{
                                                &::before{
                                                    background-image: url(/asset/images/icn-download-msword.png);
                                                }
                                            }
                                        }
                                    }
                                }
                                 
                            }
                        }
                    }
                }
                &.form_upload{
                    border-bottom: none;
                    .panel_cont{
                        dl{
                            float: none;
                            margin-bottom: 10px;
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                            dt {
                                width: 100px;
                                font-size: 10px;
                                font-weight: 500;
                                margin-bottom: 5px;
                                text-align: left;
                            }
                            dd{
                                .file_upload_box{
                                    &.show{
                                        .input_box01{
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .btn_area{
                            padding-top: 25px;
                            text-align: center;
                        }
                    }
                }
                
            }
            .precaution_box{
                background-color: @bgColor01;
                padding: 15px 0 20px;
                position: relative;
                .title{
                    font-size: 10px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                &::before{
                    content: '';
                    display: block;
                    width: @padding;
                    height: 100%;
                    background-color: @bgColor01;
                    position:absolute;
                    left: -@padding;
                    top: 0;
                }
                &::after{
                    content: '';
                    display: block;
                    width: @padding;
                    height: 100%;
                    background-color: @bgColor01;
                    position:absolute;
                    right: -@padding;
                    top: 0;
                }
            }
        }
    }
    &.privacy_policy{
        .contents_box{
            max-height: 500px;
            overflow-y: auto;
            h2{
                font-size: 16px;
                font-weight: bold;
                color: @pointColor;
                margin-bottom: 10px;
                span{
                    font-size: 12px;
                    font-weight: 500;
                    color: inherit;
                    white-space: nowrap;
                }
            }
            >.desc{
                font-size: 12px;
                font-weight: 500;
                line-height: 1.75;
                margin-bottom: 20px;
                word-break: keep-all;
            }
            .g1{
                margin-bottom: 20px;
                h3{
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.38;
                    margin-bottom: 10px;
                }
                >.desc{
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 1.57;
                    margin-bottom: 5px;
                    word-break: keep-all;
                }
            }
        }   
    }
}
/*tour*/
.tour_box{
    background-color: @pointColor;
    position: relative;
    .sc_title{
        padding: 20px 0;
        h2,p{
            color: white !important;
        }
    }
    .sc_cont{
        position: relative;
        z-index: 1;
        .tour_search_box{
            background-color: @grey03;
            padding: 32px 24px 0;
            margin: 0 auto;
            text-align: center;
            &::after{
                content: '';
                width: 100%;
                height: 24px;
                background-color: #fff;
                position: absolute;
                left: 0;
                bottom: 0;
            }
            dl{
                float: none;
                margin-bottom: 16px;
                .afters;
                &:last-child{
                    margin-bottom: 0;
                }
                dt,dd{
                    float: left;
                }
                dt{
                    width: 44px;
                    font-size: 10px;
                    font-weight: bold;
                    color: @textColor01;
                    padding-right: 5px;
                    line-height: 32px;
                }
                dd{
                    width: calc(100% - 44px);
                }
            }
            .tour_input{
                width: 100%;
                height: 32px;
                color: @pointColor;
                .eFont;
                font-size: 12px;
                padding: 0 38px 0 12px;
                background:#fff url(/asset/images/icn-calandar-act.png) no-repeat right 12px center/14px;
                &::placeholder{
                    font-size: 12px;
                    font-weight: 300;
                    .fontA;
                    color: inherit;
                }
            }
            .location,.date{
                .afters;
            }
            .location{
                margin-bottom: 16px;
            }
            .date{
                margin-bottom: 24px;
                dl{
                    position: relative;
                    &:first-child{
                        &::before{
                            content: '';
                            display: block;
                            width: 12px;
                            height: 6px;
                            .bgo;
                            background-image: url(/asset/images/m-icn-arrow-onlyline-down.png);
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: -12px;
                        }
                    }
                }
            }
            .btn_tour_search{
                width: 65.3%;
                text-align: center;
                padding: 7px 0;
                background-color: @pointColor;
                cursor: pointer;
                position: relative;
                z-index: 1;
                span{
                    .eFont;
                    font-size: 14px;
                    color: #fff;
                    margin-right: 5px;
                    &::before{
                        content: '';
                        .inline-block;
                        width: 12px;
                        height: 12px;
                        .bgo;
                        background-image: url(/asset/images/zoom-white.png);
                        margin-right: 10px;
                        vertical-align: -2px;
                    }
                }
            }
        }
    }
}
.tour_box02{
    .title{
        padding: 4px 0 6px;
        text-align: center;
        background-color: @pointColor;
        p{
            .eFont;
            font-size: 14px;
            color: white;
            line-height: 1;
            .subtitle{
                font-weight: bold;
                font-size: 10px;
                letter-spacing: -.31px;
                color: inherit;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
    .tour_search_box{
        padding: 10px 0;
        border-bottom: @border01;
        .location,.date{
            margin-bottom: 5px;
        }
        .date{
            .afters;
            >.input_box01{
                width: calc((100% - 32px)/2);
                float: left;
            }
            .start_date{
                margin-right: 32px;
                position: relative;
                &::after{
                    content: '';
                    display: block;
                    width: 16px;
                    height: 2px;
                    .bgo;
                    background-image: url(/asset/images/icn-w-linearrow-ggctour-navy.png);
                    position: absolute;
                    right: -(16px + 8px);
                    bottom: 10px;
                }
            }
        }
        .btn_tour_search{
            .tour_box > .sc_cont > .tour_search_box > .btn_tour_search;
            width: 100%;
            padding: 4px 0 5px;
            span{
                font-size: 12px;
            }
        }
    }
}

/*no_result*/
.no_result_case{
    margin: 24px 0 64px;
    .guide_box{
        border: @border02;
        padding: 20px;
        .left{
            margin-bottom: 10px;
            .icon{
                width: 58px;
                height: 65px;
                .bgo;
                background-image: url(/asset/images/img-search-nodata.png);
                margin: 0 auto;
            }
        }
        .right{
            .g_title{
                font-size: 14px;
                font-weight: 500;
                line-height: 1.43;
                word-break: keep-all;
                margin-bottom: 10px;
                text-align: center;
                .word{
                    font-size: inherit;
                    font-weight: bold;
                    line-height: inherit;
                    color: @pointColor;
                    &::before{
                        content: '‘';
                        font-size: inherit;
                        font-weight: inherit;
                        color: inherit;
                    }
                    &::after{
                        content: '’';
                        font-size: inherit;
                        font-weight: inherit;
                        color: inherit;
                    }
                }
            }
            .list01{
                li{
                    font-size: 9px;
                    // &::before{
                    //     top: 10px;
                    // }
                }
            }
        }
    }
}

/*-------------------------------------------------------------------
    Pages
-------------------------------------------------------------------*/
#contentsWrap{
    padding-top: 150px;//기본값
    opacity: 0;
    &.main_wrap{
        padding-bottom: 54px - 16px;
        section{
            margin-bottom: @mainSectionBottom;
            &:last-child{
                margin-bottom: 0;
            }
            .sc_title{
                text-align: center;
                h2{
                    .eFont;
                    font-size: 24px;
                    color: @pointColor;
                }
                .subtitle{
                    font-size: 13px;
                    font-weight: 300;
                    color: @pointColor;
                }
            }
        }
        .articles{
            padding-top: 16px;
            .row{
                .afters;
                margin-bottom: 22px;
                &:last-child{
                    margin-bottom: 0;
                }
                >div{
                    margin-right: 4%;
                    &:last-child{
                        margin-right: 0;
                    }
                }
                .col7{
                    width: 100%;
                    margin-bottom: 22px;
                }
                .col3{
                    .afters;
                    .card_box01{
                        float: left;
                        .list-width-single-row(@listGap01; 3);
                    }
                }
                .col5{
                    width: 100%;
                    margin-bottom: 14px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .news{
            .sc_cont{
                padding-top: 10px;
                .tab01{
                    .inner{
                        ul{
                            width: calc(100% - 28px);
                            li{
                                width: 25%;
                            }
                        }
                    }
                }
                .tab01_cont{
                    padding-top: 16px;
                    >div{
                        border-top: @border01;
                    }
                }
            }
        }
        .on_going{
            .sc_cont{
                .tab02{
                    .inner{
                        >ul{
                            .bld;
                        }
                    }
                }
                .tab02_cont{
                    padding-top: 10px;
                    >div{
                        .swiper-slide{
                            .list-width-single-row(@listGap01; 2);
                        }
                    }
                }
            }
        }
        .ggc_guide{
            .sc_cont{
                padding-top: 10px;
                .keyword_box{
                    border: @border01;
                    margin-bottom: 16px;
                    padding: 12px;
                    position: relative;
                    .question{
                        font-size: 18px;
                        font-weight: 300;
                        letter-spacing: -.8px;
                        line-height: 1.44;
                        color: @pointColor;
                        .typing_box{
                            display: inline-block;
                            position: relative;
                            font-size: inherit;
                            letter-spacing: inherit;
                            line-height: inherit;
                            padding-right: 3px;
                            >span{
                                font-size: inherit;
                                font-weight: 500;
                                color: white;
                                letter-spacing: inherit;
                                line-height: inherit;
                            }
                            .typing_keyword{
                                opacity: 0;
                            }
                            .keyword{
                                background-color: @pointColor;
                                position: absolute;
                                left: 0;
                                top: 0;
                                &.on{
                                    display: inline-block; 
                                    animation-name: cursor; 
                                    animation-duration: 0.3s; 
                                    animation-iteration-count: infinite; 
                                }
                            }
                        }
                    }
                    // .btn_read_more{
                    //     top: 50%;
                    //     right: 23px;
                    //     transform: translateY(-50%);
                    // }
                }
                .swiper-container{
                    padding-bottom: @swiperContainerBottom - 8px;
                    .swiper-slide{
                        .card_box03{
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
        .ggc_book{
            .sc_cont{
                padding-top: 20px;
                .sld_box_area{
                    margin: 0 auto;
                    position: relative;
                    .swiper-container{
                        width: 70%;
                        padding-bottom: 0;
                        .swiper-slide{
                            width: 100%/2;
                            padding: 0 10px;
                        }    
                    }
                    .sld_prev,.sld_next{
                        position: absolute;
                        top: 35%;
                    }
                    .sld_prev{
                        left: 0;    
                    }
                    .sld_next{
                        right: 0;
                    }
                }
            }
        }
        .ggc_issue{
            // .sc_title{
            //     .keyword_list{
            //         width: 200px;
            //         margin: 5px auto 0;
            //         border-top: @border01;
            //         border-bottom: @border01;
            //         position: relative;
            //         padding: 4px 0;
            //         .swiper-container{
            //             padding-bottom: 0;
            //             .swiper-slide{
            //                 .keyword{
            //                     font-size: 13px;
            //                     font-weight: 300;
            //                     color: @pointColor;
            //                 }
            //             }
            //         }
            //         .btn_sld_prev,.btn_sld_next{
            //             position: absolute;
            //             top: 0;
            //             width: 12px;
            //             height: 100%;
            //             .bgo;
            //             background-size: 8px;
            //             background-color: #fff;
            //             z-index: 1;
            //             cursor: pointer;
            //         }
            //         .btn_sld_prev{
            //             left: 0;
            //             .navigationPrev;
            //         }
            //         .btn_sld_next{
            //             right: 0;
            //             .navigationNext;
            //         }
            //     }
            // }
            .sc_cont{
                .keyword_list{
                    width: 200px;
                    margin: 5px auto 0;
                    border-top: @border01;
                    border-bottom: @border01;
                    position: relative;
                    padding: 4px 0;
                    text-align: center;
                    .keyword{
                        font-size: 13px;
                        font-weight: 300;
                        color: @pointColor;
                    }
                }
                // padding-top: 24px;
                // .sld_box_area{
                //     .afters;
                //     .card_box03{
                //         margin-bottom: 8px;
                //     }
                //     .paging_area{
                //         padding-top: 16px - 8px;
                //     }
                // }
                // .swiper-container{
                //     padding-bottom: @swiperContainerBottom - 8px;
                //     .swiper-slide{
                //         .card_box03{
                //             margin-bottom: 8px;
                //         }
                //     }
                // }
                .swiper-container{
                    padding-bottom: @swiperContainerBottom - 8px;
                    .swiper-slide{
                        .sld_section{
                            padding-top: 24px;
                            .card_box03{
                                margin-bottom: 8px;
                            }
                        }
                    }
                }

            }
        }
        .inside_gyeonggido{
            margin-bottom: @mainSectionBottom - 16px;
            .sc_cont{
                padding-top: 20px;
                font-size: 0;
                .box02{
                    .list-width-multi-row(2%; 4);
                    margin-bottom: 10px;
                }
            }
        }
        .whats_new{
            .sc_cont{
                padding-top: 20px;
                display: flex;
                flex-wrap: wrap;
                .afters;
                .card_box05{
                    .list-width-multi-row(@listGap01; 2);
                    margin-bottom: 16px;
                }
            }
        }
        
    }
    &.sub_wrap{
        padding-bottom: @subWrapBottom;
        .title_section{
            padding: 25px 0;
            text-align: center;
            h2{
                font-size: 20px;
                font-weight: 500;
                letter-spacing: -.5px;
                color: @pointColor;
                line-height: 1;
                text-transform: capitalize;
            }
        }
        .tab01{
            .inner{
                ul{
                    li{
                        a{
                            font-size: 10px;
                        }
                    }
                }
            }
        }
        .options_box{
            margin-bottom: 12px;
            .total_list{
                margin-bottom: 5px;
                p{
                    font-size: 10px;
                    font-weight: 500;
                    letter-spacing: -.42px;
                    >span{
                        .inline-block;
                        font-size: inherit;
                        font-weight: inherit;
                        letter-spacing: inherit;
                        color: @grey02;
                        margin-left: 2px;
                    }
                }
            }
            .options_select{
                .option_box{
                    vertical-align: middle;
                }
                ul{
                    width: 100%;
                    margin-bottom: 5px;
                    li{
                        margin-right: 5px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
                .select_search_box{
                    font-size: 0;
                    .select_search{
                        width: calc(100% - 24px);
                    }
                    .btn_search{
                        .inline-block;
                        vertical-align: middle;
                        width: 24px;
                        height: 24px;
                        background: @pointColor url(/asset/images/icn-zoom.png) no-repeat center/12px;
                    }
                }
            }
        }
        .paging_area{
            margin-top: 16px;
        }
        .btn_area{
            padding-top: 16px;
            font-size: 0;
            text-align: center;
        }
        &.post_list{
            .sld_list_section{
                margin-top: 20px;
                margin-bottom: 34px;
            }
            .archive_list_section{
                position: relative;
                .target_point{
                    display: block;
                    width: 1px;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    top: -90px;
                }
                .options_box{
                    .afters;
                    font-size: 0;
                    margin-bottom: 12px;
                    ul{
                        float: right;
                        li{
                            width: 92px;
                            margin-right: 5px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
                .list_cont{
                    display: flex;
                    flex-wrap: wrap;
                    .card_box06{
                        .list-width-multi-row(@listGap01; 2);
                        margin-bottom: 16px;
                    }
                    
                    
                }
            }
        }
        &.post_list01{
            .sld_list_section{
                .tab01{
                    .inner{
                        ul{
                            width: 100%;
                            li{
                                width: 50%;
                            }
                        }
                    }
                }
                .tab01_cont{
                    padding-top: 16px;
                    position: relative;
                    .top_btn_area{
                        font-size: 0;
                        text-align: right;
                        margin-bottom: 10px;
                    }
                    .swiper-container{
                        .swiper-wrapper{
                            .swiper-slide{
                                .list-width-single-row(@listGap01; 2);
                            }
                        }
                    }
                    .btn_register{
                        position: absolute;
                        left: 0;
                        top: 16px;
                        z-index: 1;
                    }
                }
            }
            .archive_list_section{
                .list_title{
                    text-align: center;
                    margin-bottom: 10px;
                    h3{
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: -.5px;
                        color: @pointColor;
                        line-height: 1;
                        margin-bottom: 5px;
                    }
                    p{
                        font-size: 13px;
                        font-weight: 300;
                        color: @pointColor;
                    }
                }
            }
        }
        &.post_list02{
            .sld_list_section{
                .swiper-container{
                    .swiper-wrapper{
                        .swiper-slide{
                            .card_box06{
                                .img_box{
                                    .img{
                                        padding-top: 48.6%;
                                    }
                                }
                                figcaption{
                                    .title{
                                        font-size: 16px;
                                        letter-spacing: -.33px;
                                        .line-clamp(1.31; 2);
                                    }
                                    .subtitle{
                                        font-size: 11px;
                                        font-weight: 300;
                                        .line-clamp(1.45; 2);
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.tour{
                .tour_box02{
                    margin-bottom: 16px;
                }
            }
            
        }
        &.post_view{
            h3{
                font-size: 18px;
                font-weight: bold;
                color: @pointColor;
                letter-spacing: -.45px;
                margin-bottom: 20px;
                text-align: center;
            }
            h4{
                .eFont;
                font-size: 16px;
                color: @pointColor;
                margin-bottom: 10px;
            }
            .sns_box{
                text-align: center;
                font-size: 0;
                ul{
                    .inline-block;
                    li{
                        margin-right: 8px;
                        &:last-child{
                            margin-right: 0;
                        }
                        a{
                            display: block;
                            width: 28px;
                            height: 28px;
                            border-radius: 50%;
                            background-color: @pointColor;
                            font-size: 0;
                            text-align: center;
                            .bgo;
                            &.twitter{
                                background-image: url(/asset/images/icn-article-sharetwt.png);
                                background-size: 15px;
                            }
                            &.facebook{
                                background-image: url(/asset/images/icn-article-sharefb.png);
                                background-size: 8px;
                            }
                            &.print{
                                background-image: url(/asset/images/icn-article-print.png);
                                background-size: 14px;
                            }
                            &.like{
                                background-image: url(/asset/images/icn-article-like-default.png);
                                background-position: top 4px center;
                                background-size: 10px;
                                position: relative;
                                span{
                                    font-size: 12px;
                                    color: #fff;
                                    .eFont;
                                    position: absolute;
                                    display: block;
                                    width: 100%;
                                    bottom: 2px;
                                }
                                &.on{
                                    background-image: url(/asset/images/icn-article-like-act.png);
                                }
                            }
                        }
                    }
                }
            }
        }
        &.post_view01{
            .post_view_area{
                .view_top{
                    border-top: @border-pointColor;
                    padding: 32px 0;
                    .title_box{
                        margin-bottom: 15px;
                        text-align: center;
                        .badge_box{
                            margin-bottom: 5px;
                            .badge{
                                display: inline-block;
                                padding: 0 4px 1px;
                                min-width: 28px;
                                font-size: 10px;
                                font-weight: 500;
                                color: #fff;
                                vertical-align: middle;
                                margin-right: 5px;
                                &:last-child{
                                    margin-right: 0;
                                }
                                &.ongoing{
                                    padding: 1px 4px 2px;
                                }
                            }
                        }
                        .post_title{
                            font-size: 20px;
                            font-weight: 300;
                            margin-bottom: 5px;
                            word-break: keep-all;
                        }
                        .post_date{
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                    .img_box{
                        margin-bottom: 15px;
                        overflow: hidden;
                        img{
                            display: block;
                            margin: 0 auto;
                            height: 180px;
                        }
                    }
                }
                .view_bottom{
                    .info_box,.map_box{
                        border-top: @border01;
                    }
                    .info_box{
                        padding: 15px 0;
                        .cont{
                            .left,.right{
                                dl{
                                    float: none;
                                    margin-bottom: 5px;
                                    .afters;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    dt,dd{
                                        float: left;
                                    }
                                    dt{
                                        width: 55px;
                                        font-size: 10px;
                                        font-weight: bold;
                                    }
                                    dd{
                                        width: calc(100% - 55px);
                                        font-size: 10px;
                                        font-weight: 300;
                                        word-break: keep-all;
                                        line-height: 1.8;
                                        a{
                                            font-size: inherit;
                                            font-weight: inherit;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .map_box{
                        padding: 30px 0 36px;
                        text-align: center;
                        .wrap04{
                            max-width: 82.6%;
                            margin: 0 auto;
                            .map{
                                position: relative;
                                padding-top: 57.6%;
                                margin-bottom: 10px;
                                iframe{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }
                            }
                            .address{
                                .inline-block;
                                float: none;
                                .afters;
                                dt,dd{
                                    float: left;
                                    font-size: 10px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
            .gallery_area{
                padding: 16px 0 24px;
                background-color: @bgColor01;
                text-align: center;
                .sld_box_area{
                    position: relative;
                    .swiper-container{
                        padding-bottom: 0;
                        .swiper-wrapper{
                            .swiper-slide{
                                width: auto;
                                margin-right: 10px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .sld_next,.sld_prev{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%); 
                    }
                    .sld_prev{
                        left: -(24px + 14px);
                    }
                    .sld_next{
                        right: -(24px + 14px);
                    }
                }
                
            }
            .recommend_area{
                padding-top: 35px;
                .swiper-container{
                    .swiper-wrapper{
                        .swiper-slide{
                            .list-width-single-row(@listGap01; 2);
                        }
                    }
                }
            }
        }
        &.post_view02{
            padding-bottom: @subWrapBottom - 16px;
            .post_view_area{
                padding-top: 16px;
                border-bottom: @border01;
                margin-bottom: 40px;
                .wrap02{
                    padding: 0;
                }
                .post_top{
                    .img_box{
                        margin-bottom: 16px;
                        .img{
                            padding-top: 48.5%;
                            .bgo;
                            background-color: lightgrey;
                        }
                    }
                    .title_box{
                        margin-bottom: 20px;
                        text-align: center;
                        >p{
                            line-height: 1;
                        }
                        .group{
                            font-size: 10px;
                            font-weight: 500;
                            color: @pointColor;
                            margin-bottom: 5px;
                        }
                        .title{
                            font-size: 20px;
                            font-weight: 300;
                            line-height: 1.25;
                            word-break: keep-all;
                            letter-spacing: -.83px;
                            margin-bottom: 5px;
                        }
                        .subtitle{
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                }
                .post_mid{
                    border-top: @border01;
                    .post_box{
                        padding: 30px 0;
                        .post_view_txt_section;
                        .copyright_phrase{
                            margin-top: 16px;
                            font-size: 9px;
                            font-weight: 500;
                            color: #4a4a4a;
                            text-align: center;
                        }
                    }
                    .info_box{
                        border-top: @border01;
                        padding: 15px 0 25px;
                        .row{
                            margin-bottom: 10px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            h4{
                                margin-bottom: 5px;
                            }
                            .cont{
                                font-size: 0;
                                >dl{
                                    float: none;
                                    .afters;
                                    dt,dd{
                                        float: left;
                                        font-size: 10px;
                                    }
                                    dt{
                                        width: 50px;
                                        font-weight: bold;
                                        margin-bottom: 5px;
                                    }
                                    dd{
                                        width: calc(100% - 50px);
                                        font-weight: 300;
                                        line-height: 1.57;
                                        >a{
                                            font-size: inherit;
                                            font-weight: inherit;
                                            line-height: inherit;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                                >ul{
                                    li{
                                        float: none;
                                        margin-bottom: 5px;
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                        .ui-sortable-handle{
                                            display: flex;
                                            font-size: 10px;
                                            font-weight: 300;
                                            line-height: 1.57;
                                            margin-bottom: 5px;
                                            &:last-child{
                                                margin-bottom: 0;
                                            }
                                            span{
                                                display: block;
                                                font-size: 10px;
                                                font-weight: bold;
                                                white-space: nowrap;
                                                margin-right: 5px;
                                            }
                                            >a{
                                                font-size: inherit;
                                                font-weight: inherit;
                                                line-height: inherit;
                                            }
                                        }
                                    }
                                }
                                .pebble_box{
                                    .inline-block;
                                    padding: 3px 7px;
                                    border-radius: 12px;
                                    background-color: #e8e8e8;
                                    margin-right: 5px;
                                    margin-bottom: 5px;
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                    span{
                                        font-size: 10px;
                                        font-weight: 500;
                                        &::before{
                                            font-size: inherit;
                                            font-weight: inherit;
                                            display: inline;
                                        }
                                    }
                                }
                                .hashtag{
                                    font-size: 0;
                                    .pebble_box{
                                        span{
                                            &::before{
                                                content: '#';
                                            }
                                        }
                                    }
                                }
                                .at{
                                    font-size: 0;
                                    .pebble_box{
                                        span{
                                            &::before{
                                                content: '@';
                                            }
                                        }
                                    }
                                }
                            }
                            &.sns_box{
                                margin-top: 20px;
                                text-align: left;
                            }
                        }
                    }
                }
                .post_bot{
                    border-top: @border01;
                    padding: 16px 0;
                    .afters;
                    .left,.right{
                        float: left;
                    }
                    .img_box{
                        width: 64px;
                        padding: 5px;
                        border: 1px solid @grey02;
                        .img{
                            width: 100%;
                            padding-top: 100%;
                            .bgo;
                            background-color: lightgrey;
                        }
                    }
                    .right{
                        width: calc(100% - 64px);
                        padding-left: 10px;
                        dl{
                            float: none;
                            .afters;
                            dt,dd{
                                float: left;
                                font-size: 10px;
                            }
                            dt{
                                font-weight: bold;
                                color: @pointColor;
                                margin-right: 2px;
                                &::after{
                                    content: '/';
                                    font-weight: inherit;
                                    color: inherit;
                                }
    
                            }
                            dd{
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
            .recommend_area{
                .row{
                    margin-bottom: 5px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    h3{
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    .sld_box_area{
                        .afters;
                        .card_box06{
                            float: left;
                            .list-width-multi-row(@listGap01;2);
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        &.search_result{
            .wrap02{
                padding-top: 16px;
            }
            .panel01{
                margin-bottom: 40px;
                &:last-child{
                    margin-bottom: 0;
                }
                .panel_cont{
                    .list_boxes{
                        border-top: @border-pointColor;
                        border-bottom: @border-pointColor;
                        padding: 2px 0;
                        margin-bottom: 15px;
                        .list_box02{
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                    
                }
            }
        }
        &.culture_events{
            .tab01{
                .inner{
                    ul{
                        width: 100%;
                        li{
                            width: 100% /5;
                        }
                    }
                }
            }
            .tab_cont{
                padding-top: 16px;
                .options_box{
                    .options_select{
                        ul{
                            li{
                                width: calc((100% - 5px*2)/3);
                            }
                        }
                    }
                }
                .lists_box{
                    display: flex;
                    flex-wrap: wrap;
                    .card_box02{
                        .list-width-multi-row(@listGap01; 2);
                        margin-bottom: 16px;

                    }
                }
            }
        }
        &.news_list{
            .tab01{
                .inner{
                    ul{
                        width: 100%;
                        li{
                            width: 100% /5;
                        }
                    }
                }
            }
            .tab_cont{
                .tab_title_box{
                    padding: 20px 0;
                    text-align: center;
                    h3{
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 1;
                    }
                }
                .options_box{
                    position: relative;
                    .options_select{
                        ul{
                            li{
                                width: calc((100% - 5px)/2);
                            }
                        }
                    }
                    .btn_register{
                        position: absolute;
                        right: 0;
                        top: -6px;
                    }
                }
                .lists_box{
                    .table01{
                        .tr{
                            .th,.td{
                                &:nth-child(1){
                                    .bld;
                                }
                                &:nth-child(2){
                                    width: 100%
                                }
                                &:nth-child(3){
                                    .bld;
                                }
                                &:nth-child(4){
                                    .bld;
                                }
                                &:nth-child(5){
                                    .bld;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.news_view{
            .post_view_area{
                border-top: @border-pointColor;
                border-bottom: @border-pointColor;
                .title_box{
                    padding: 16px 0 20px;
                    border-bottom: @border01;
                    text-align: center;
                    .title_list{
                        &::after{
                            content: '';
                            display: block;
                            width: 20px;
                            height: 1px;
                            background-color: #333;
                            margin: 10px auto;
                        }
                        .badge_box{
                            margin-bottom: 5px;
                            .badge{
                                .inline-block;
                                padding: 3px 3px;
                                min-width: 28px;
                                font-size: 9px;
                                color: #fff;
                                line-height: 1;
                                text-align: center;
                                margin-right: 4px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                        .post_title{
                            margin: 0 auto;
                            font-size: 20px;
                            font-weight: 300;
                            line-height: 1.2;
                            letter-spacing: -.83px;
                            word-break: keep-all;
                            margin-bottom: 5px;
                        }
                        .group{
                            font-size: 12px;
                            font-weight: 300;
                        }   
                    }
                    .info_list{
                        .inline-block;
                        li{
                            margin-right: 20px;
                            position: relative;
                            &::after{
                                content: '';
                                display: block;
                                width: 1px;
                                height: 8px;
                                background-color: #e8e8e8;
                                position: absolute;
                                right: -10px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &:last-child{
                                margin-right: 0;
                                &::after{
                                    .bld;
                                }
                            }
                            dl{
                                float: none;
                                .afters;
                                dt,dd{
                                    float: left;
                                }
                                dt{
                                    font-size: 9px;
                                    font-weight: 500;
                                    padding-right: 5px;
                                }
                                dd{
                                    font-size: 10px;
                                    font-weight: 300;
                                }
                                &.period{
                                    dd{
                                        color: @pointColor;
                                        font-weight: 500;
                                    }
                                }
                            }

                        }
                    }

                }
                .post_view_box{
                    padding: 20px 0 25px;
                    .post_box{
                        .post_view_txt_section;
                    }
                    .cf_file_box{
                        border: 2px solid @grey03;
                        margin-top: 20px;
                        padding: 12px;
                        .title{
                            font-size: 10px;
                            font-weight: 500;
                            margin-bottom: 5px;
                        }
                        .download_list{
                            .btn_file_download{
                                margin-bottom: 5px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.article{
            .search_box01{
                border: @border02;
                padding: 5px 10px 8px;
                text-align: center;
                margin: 0 auto 15px;
                >div{
                    .options_box>.options_select;
                    .afters;
                    ul{
                        float: left;
                        width: auto;
                        margin-right: 5px;
                        margin-bottom: 0;
                        li{
                            width: 81px;
                        }
                    }
                    .select_search_box{
                        float: left;
                        margin-top: 3px;
                        width: calc(100% - 81px - 5px);
                    }
                }
            }
            .tab01{
                .inner{
                    ul{
                        width: 100%;
                        li{
                            width: 100% /6;
                        }
                    }
                }
            }
            .tab_cont{
                padding-top: 12px;
                .options_box{
                    margin-bottom: 10px;
                    .afters;
                    .left{
                        float: left;
                    }
                    .right{
                        float: right;
                    }
                    .total_list{
                        margin-bottom: 0;
                        margin-top: 10px;
                    }
                }
                .lists_box{
                    .table02{
                        .th,.td{
                            &:nth-child(1){
                               .bld;
                            }
                            &:nth-child(2){
                                width: 100%;
                            }
                            &:nth-child(3){
                                width: 100%;
                            }
                            &:nth-child(4){
                                .bld;
                            }
                            &:nth-child(5){
                                .bld;
                            }
                            &:nth-child(6){
                                position: absolute;
                                top: 0;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
        &.write_form{
            .tab01{
                .inner{
                    ul{
                        width: 100%;
                        li{
                            width: 100% /5;
                        }
                    }
                }
            }
            .tab_cont{
                padding-top: 16px;
                .form_box{
                    padding: 10px 0 20px;
                    .row{
                        margin-bottom: 10px;
                        .col{
                            margin-bottom: 10px;
                            &:nth-child(even){
                                margin-bottom: 0;
                            }
                        }
                        dl{
                            float: none;
                            dt{
                                margin-bottom: 5px;
                                position: relative;
                                >span{
                                    font-weight: 500;
                                    font-size: 11px;
                                    color: #3a3a3a;    
                                }
                                &.ls_wide{
                                    >span{
                                        letter-spacing: 2.93px;
                                    }
                                }
                                .cmt{
                                    display: block;
                                    font-size: 9px;
                                    font-weight: bold;
                                    color: @pointColor;
                                    letter-spacing: normal;
                                    word-break: keep-all;
                                }
                                .required_cmt{
                                    font-size: 9px;
                                    font-weight: 500;
                                    color: @textColor04;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    &::before{
                                        content: '*';
                                        font-weight: 500;
                                        color: @red02;
                                    }
                                }
                            }
                            dd{
                                margin-bottom: 5px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                &.period{
                                    .afters;
                                    .input_box01{
                                        float: left;
                                        position: relative;
                                        width: calc((100% - 13px) / 2);
                                    }
                                    .start{
                                        margin-right: 12px;
                                        &::after{
                                            content: '';
                                            display: block;
                                            width: 6px;
                                            height: 1px;
                                            background-color: @grey02;
                                            position: absolute;
                                            right: -10px;
                                            top: 11px;
                                        }
                                    }
                                }
                                &.multi{
                                    .afters;
                                    >.input_box01{
                                        float: left;
                                        width: calc((100% - 11px)/2);
                                        &:first-child{
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                            &.required{
                                dt{
                                    &::after{
                                        content: '*';
                                        font-size: 11px;
                                        font-weight: 500;
                                        color: @red02;
                                    }
                                }
                            }
                        }
                    }
                    .agreement_box{
                        border: @border02;
                        padding: 15px 16px;
                        .all_check_box{
                            .check_box02{
                                text-indent: -2px;
                            }
                        }
                    }
                    .precaution_box{
                        border: @border02;
                        padding: 15px 15px 20px;
                        position: relative;
                        .title{
                            font-size: 10px;
                            font-weight: 500;
                            margin-bottom: 10px;
                        }
                    } 
                }
            }
        }
        &.introduce{
            .title_section{
                margin-top: 16px;
                padding: 30px 0;
                background: #07294e url(/asset/images/img-ggc-intro-bg.jpg) no-repeat center/ auto 100%;
                h2{
                    color: white;
                }
            }
            .contents_section{
                border-top: @border-pointColor;
                padding-top: 25px;
                .panel02{
                    margin-bottom: 24px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .ggc_introduce{
                    .panel_cont{
                        p{
                            margin-bottom: 5px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .ggc_service{
                    .panel_cont{
                        padding-top: 10px;
                        .img_box{
                            font-size: 0;
                            text-align: center;
                            img{
                                width: 100%;
                                
                            }
                        }
                        .left{
                            margin-bottom: 24px;
                        }
                        .right{
                            .img_box{
                                width: 74.2%;
                                margin: 0 auto;
                            }
                        }
                        
                    }
                }
                .ggc_logo{
                    .panel_cont{
                        padding-top: 5px;
                        .list_box03{
                            margin-bottom: 10px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            &:nth-child(1){
                                .img_box{
                                    img{
                                        height: 81px;
                                    }
                                }
                            }
                            &:nth-child(2){
                                .img_box{
                                    img{
                                        height: 33px;
                                    }
                                }
                            }
                           .right{
                               font-size: 0;
                               .btn_download{
                                   width: calc((100% - 12px)/2);
                                   margin-right: 12px;
                                   &:last-child{
                                       margin-right: 0;
                                   }
                               }
                           } 
                        }    
                    }
                } 
            }
        }
        &.open_api{
            .wrap06{
                padding: 0 @padding; 
            }
            .tab01{
                .inner{
                    ul{
                        width: 100%;
                        li{
                            width: 50%;
                        }
                    }
                }
            }
            .tab_title_box02{
                padding: 20px 0 20px;
                text-align: center;
                h3{
                    font-size: 15px;
                    font-weight: bold;
                    color: @pointColor;
                    margin-bottom: 4px;
                }
                .subtitle{
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 1.6;
                    color: @textColor04;
                    word-break: keep-all;
                }
            }
            .btn_area{
                display: flex;
                justify-content: flex-end;
                padding-top: 0;
            }
            .panel05{
                margin-bottom: 20px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*-------------------------------------------------------------------
    Lang : en
-------------------------------------------------------------------*/
.en{
    header{
        .top_gnb_area{
            >div{
                >ul{
                    margin-right: 0;
                }
            }
        }
        .main_gnb_area{
            nav{
                ul{
                    li{
                        a{
                            .eFont;
                        }
                    }
                }
            }
        }
        .site_map{
            .wrap01{
                .group1{
                    a{
                        span{
                            .eFont;
                            font-weight: 400;
                            font-size: 21px;
                        }
                        &.impt{
                            span{
                                font-weight: 900;
                            }
                        }
                    }
                }
                .group3{
                    dl{
                        dt{
                            a{
                                .eFont;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    footer{
        .g2{
            &.left{
                address{
                    max-width: 80%;
                }
            }
        }
    }
    .card_box01{
        a{
            &.ver2{
                figcaption{
                    .subtitle{
                        font-size: 10px;
                    }
                }
            }
            &.ver3{
                figcaption{
                    .title{
                        .line-clamp(1.27; 3);
                    }
                    .subtitle{
                        .line-clamp(1.45; 2);
                    }
                }
            }
        }
    }
    .card_box03{
        a{
            figcaption{
                .subtitle{
                    white-space: normal;
                    .line-clamp(1.44; 2);
                }
            }
        }
    }
    .card_box05{
        a{
            figcaption{
                .title{
                    .line-clamp(1.29; 3);
                }
            }
        }
    }
    .panel02{
        .panel_tit{
            .eFont;
        }
    }
    #contentsWrap{
        &.sub_wrap{
            .title_section{
                h2{
                    .eFont;
                }
            }
            &.introduce{
                .title_section{
                    h2{
                        text-transform: none;
                    }
                }
                .contents_section{
                    .ggc_introduce{
                        .panel_cont{
                            p{
                                margin-bottom: 0;
                                &.bold{
                                    font-size: 11px;
                                    font-weight: bold;
                                    span{
                                        &.eFont{
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ggc_service{
                        .panel_cont{
                            .col{
                                margin-bottom: 15px;
                                .afters;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                .left{
                                    margin-bottom: 10px;
                                    img{
                                        display: block;
                                        margin: 0 auto 10px;
                                        width: 30%;
                                    }
                                }
                            }
                        }
                    }
                }   
            }
        }
    }
}

/*-------------------------------------------------------------------
    Keyframes
-------------------------------------------------------------------*/
@keyframes cursor{ 
    0%{border-right: 2px solid #fff} 
    50%{border-right: 2px solid @pointColor} 
    100%{border-right: 2px solid #fff} 
}
