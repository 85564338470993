/*-------------------------------------------------------------------
    파일정의 : Settings
    생성일자 : 2020-06-30 mangoajang
-------------------------------------------------------------------*/
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2') format('woff2'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff') format('woff'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf') format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2') format('woff2'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff') format('woff'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2') format('woff2'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff') format('woff'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2') format('woff2'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff') format('woff'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2') format('woff2'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff') format('woff'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2') format('woff2'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff') format('woff'), url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf') format('opentype');
}
@font-face {
  font-family: 'ggcfStd';
  font-style: normal;
  font-weight: 900;
  src: url('/asset/common/fonts/ggcfStd-Black.ttf') format('truetype'), url('/asset/common/fonts/ggcfStd-Black.woff') format('woff'), url('/asset/common/fonts/ggcfStd-Black.otf') format('opentype');
}
@font-face {
  font-family: 'ggcfStd';
  font-style: normal;
  font-weight: 400;
  src: url('/asset/common/fonts/ggcfStd-Book.ttf') format('truetype'), url('/asset/common/fonts/ggcfStd-Book.woff') format('woff'), url('/asset/common/fonts/ggcfStd-Book.otf') format('opentype');
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figure,
figcaption,
footer,
header,
hgroup,
nav,
output,
section,
time,
mark,
audio,
video,
select,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Noto Sans KR', Sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #333;
  vertical-align: baseline;
  -webkit-text-size-adjust: 100%;
}
html,
body {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
tr,
th,
td {
  vertical-align: middle;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  font: 100%;
  color: #333;
  position: relative;
}
ol,
ul,
li {
  list-style: none;
}
ul::after,
ol::after,
.clb::after {
  content: "";
  display: block;
  clear: both;
}
li,
dl {
  float: left;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
  color: #333;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
a:hover {
  text-decoration: none;
}
caption,
legend {
  display: none;
}
input,
select {
  font: 100%;
  outline: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
input {
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}
input[type="checkbox"],
input[type="radio"] {
  background: none;
  border: none;
  cursor: pointer;
}
input[type="submit"] {
  cursor: pointer;
}
strong {
  font-weight: bold;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}
input:-ms-input-placeholder {
  color: #ccc;
}
input::-webkit-input-placeholder {
  color: #ccc;
}
input::-moz-placeholder {
  color: #ccc;
}
input::-moz-placeholder {
  color: #ccc;
}
button {
  border: none;
}
button:focus {
  outline: none;
}
/*-------------------------------------------------------------------
    Color
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------
    Variable
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------
    Mixin
-------------------------------------------------------------------*/
.bld {
  display: none ;
}
.after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.afters::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.bgo {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.fontA {
  font-family: 'Noto Sans KR';
}
.fontB {
  font-family: 'ggcfStd', sans-serif;
}
.eFont {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
}
.inline-block {
  display: inline-block;
  font-size: 0;
}
.line-clamp-one {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/*-------------------------------------------------------------------
    Globals
-------------------------------------------------------------------*/
.d_only {
  display: none !important;
}
.dt_only {
  display: none !important;
}
.m_only {
  display: block !important;
}
.wrap01 {
  min-width: 320px;
  padding: 0 16px;
}
.wrap01::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.wrap02 {
  min-width: 320px;
  padding: 0 16px;
}
.wrap02::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.wrap05 {
  padding: 0 16.8%;
  margin: 0 auto;
}
.wrap05::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.wrap06 {
  min-width: 320px;
  padding: 0 16px;
  padding: 0;
}
.wrap06::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.navigationBtnCommon {
  width: 24px;
  height: 24px;
  border: 1px solid #003c71;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  background-size: 6px;
  cursor: pointer;
}
.navigationBtnCommon.swiper-button-disabled,
.navigationBtnCommon.disabled {
  border: 1px solid #ddd;
}
.navigationPrev {
  background-image: url(/asset/images/btn-arrow-onlyline-prev.png);
}
.navigationPrev:focus,
.navigationPrev.active {
  outline: none;
}
.navigationPrev.swiper-button-disabled,
.navigationPrev.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-prev-dis.png);
}
.navigationNext {
  background-image: url(/asset/images/btn-arrow-onlyline-next.png);
}
.navigationNext:focus,
.navigationNext.active {
  outline: none;
}
.navigationNext.swiper-button-disabled,
.navigationNext.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-next-dis.png);
}
.navigationPrevFirst {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-first.png);
  background-size: 10px;
}
.navigationPrevFirst:focus,
.navigationPrevFirst.active {
  outline: none;
}
.navigationPrevFirst.swiper-button-disabled,
.navigationPrevFirst.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-first-dis.png);
}
.navigationNextLast {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-last.png);
  background-size: 10px;
}
.navigationNextLast:focus,
.navigationNextLast.active {
  outline: none;
}
.navigationNextLast.swiper-button-disabled,
.navigationNextLast.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-last-dis.png);
}
.post_view_txt_section p,
.post_view_txt_section span,
.post_view_txt_section strong,
.post_view_txt_section b,
.post_view_txt_section u,
.post_view_txt_section a,
.post_view_txt_section i,
.post_view_txt_section div {
  line-height: 1.6;
  font-weight: 500;
  font-size: 10px !important;
}
.post_view_txt_section strong,
.post_view_txt_section b {
  font-weight: bold;
}
.post_view_txt_section a {
  text-decoration: underline;
}
.post_view_txt_section img,
.post_view_txt_section iframe,
.post_view_txt_section video {
  max-width: 100%;
}
.post_view_txt_section table {
  width: 100%;
  margin: 10px 0;
  table-layout: fixed;
}
.post_view_txt_section table th,
.post_view_txt_section table td {
  font-size: 10px;
  border: 1px solid #ddd;
  padding: 12px 6px;
  word-break: keep-all;
  font-weight: 500;
  text-align: center;
}
.post_view_txt_section table th {
  font-weight: bold;
  background-color: #f8f8f8;
}
.post_view_txt_section table th p,
.post_view_txt_section table th span,
.post_view_txt_section table th strong,
.post_view_txt_section table th b,
.post_view_txt_section table th u,
.post_view_txt_section table th a,
.post_view_txt_section table th i,
.post_view_txt_section table th div {
  font-size: inherit;
  font-weight: inherit;
  word-break: keep-all;
  text-align: inherit;
}
.post_view_txt_section table th strong,
.post_view_txt_section table th b {
  font-weight: bold;
}
.post_view_txt_section table td {
  line-height: 1.6;
}
.post_view_txt_section table td p,
.post_view_txt_section table td span,
.post_view_txt_section table td strong,
.post_view_txt_section table td b,
.post_view_txt_section table td u,
.post_view_txt_section table td a,
.post_view_txt_section table td i,
.post_view_txt_section table td div {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  word-break: keep-all;
  text-align: inherit;
}
.post_view_txt_section table td strong,
.post_view_txt_section table td b {
  font-weight: bold;
}
.post_view_txt_section .note-float-right,
.post_view_txt_section .note-float-left {
  margin: 10px;
}
.badge_box .badge.play {
  background-color: #02aac0;
}
.badge_box .badge.exhibition {
  background-color: #10b295;
}
.badge_box .badge.event {
  background-color: #0098f1;
}
.badge_box .badge.education {
  background: #206ee6;
}
.badge_box .badge.news {
  background: #7d5fd2;
}
.badge_box .badge.important {
  background-color: #003c71;
}
.badge_box .badge.ongoing {
  background-color: #003c71;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
}
.badge_box .badge.progress {
  background-color: #3c51ce;
}
.badge_box .badge.expected {
  background-color: #535f93;
}
.badge_box .badge.end {
  background-color: #c4c4c4;
}
/*-------------------------------------------------------------------
    Plugin
-------------------------------------------------------------------*/
.swiper-container {
  padding-bottom: 40px;
}
.swiper-container .swiper-pagination {
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1px;
}
.swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: 1px solid white;
  background-color: white;
  opacity: 1;
  position: relative;
  margin: 0 2px;
}
.swiper-container .swiper-pagination .swiper-pagination-bullet:after {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #003c71;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.swiper-container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 1px solid #003c71;
}
.sld_navigation {
  width: 200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 1;
}
.sld_navigation::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.sld_navigation .btn_sld_prev,
.sld_navigation .btn_sld_next {
  width: 24px;
  height: 24px;
  border: 1px solid #003c71;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  background-size: 6px;
  cursor: pointer;
}
.sld_navigation .btn_sld_prev.swiper-button-disabled,
.sld_navigation .btn_sld_next.swiper-button-disabled,
.sld_navigation .btn_sld_prev.disabled,
.sld_navigation .btn_sld_next.disabled {
  border: 1px solid #ddd;
}
.sld_navigation .btn_sld_prev {
  float: left;
  background-image: url(/asset/images/btn-arrow-onlyline-prev.png);
}
.sld_navigation .btn_sld_prev:focus,
.sld_navigation .btn_sld_prev.active {
  outline: none;
}
.sld_navigation .btn_sld_prev.swiper-button-disabled,
.sld_navigation .btn_sld_prev.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-prev-dis.png);
}
.sld_navigation .btn_sld_next {
  float: right;
  background-image: url(/asset/images/btn-arrow-onlyline-next.png);
}
.sld_navigation .btn_sld_next:focus,
.sld_navigation .btn_sld_next.active {
  outline: none;
}
.sld_navigation .btn_sld_next.swiper-button-disabled,
.sld_navigation .btn_sld_next.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-next-dis.png);
}
.sld_next,
.sld_prev {
  width: 24px;
  height: 24px;
  border: 1px solid #003c71;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  background-size: 6px;
  cursor: pointer;
}
.sld_next.swiper-button-disabled,
.sld_prev.swiper-button-disabled,
.sld_next.disabled,
.sld_prev.disabled {
  border: 1px solid #ddd;
}
.sld_next {
  background-image: url(/asset/images/btn-arrow-onlyline-next.png);
}
.sld_next:focus,
.sld_next.active {
  outline: none;
}
.sld_next.swiper-button-disabled,
.sld_next.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-next-dis.png);
}
.sld_prev {
  background-image: url(/asset/images/btn-arrow-onlyline-prev.png);
}
.sld_prev:focus,
.sld_prev.active {
  outline: none;
}
.sld_prev.swiper-button-disabled,
.sld_prev.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-prev-dis.png);
}
.ui-datepicker {
  width: 18em;
  font-size: 14px;
  border-radius: 0;
  border: 1px solid #e8e8e8;
  padding: 0;
}
.ui-datepicker .ui-widget-header {
  font-size: 14px;
  border: none;
  border-radius: 0;
  background-color: white;
  padding: 0.813em 0.75em;
  background-color: #003c71;
}
.ui-datepicker .ui-widget-header .ui-datepicker-title {
  font-size: 14px;
}
.ui-datepicker .ui-widget-header .ui-datepicker-title span {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 1.5em;
  color: white;
}
.ui-datepicker .ui-widget-header .ui-datepicker-next,
.ui-datepicker .ui-widget-header .ui-datepicker-prev {
  font-size: 14px;
  border: 1px solid #003c71;
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 6px;
  background-color: white;
  width: 2.5em;
  height: 2.5em;
  top: 0.5em;
}
.ui-datepicker .ui-widget-header .ui-datepicker-next span,
.ui-datepicker .ui-widget-header .ui-datepicker-prev span {
  display: none ;
}
.ui-datepicker .ui-widget-header .ui-datepicker-next {
  background-image: url(/asset/images/btn-arrow-onlyline-next.png);
  right: 0.75em;
}
.ui-datepicker .ui-widget-header .ui-datepicker-next:focus,
.ui-datepicker .ui-widget-header .ui-datepicker-next.active {
  outline: none;
}
.ui-datepicker .ui-widget-header .ui-datepicker-next.swiper-button-disabled,
.ui-datepicker .ui-widget-header .ui-datepicker-next.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-next-dis.png);
}
.ui-datepicker .ui-widget-header .ui-datepicker-prev {
  background-image: url(/asset/images/btn-arrow-onlyline-prev.png);
  left: 0.75em;
}
.ui-datepicker .ui-widget-header .ui-datepicker-prev:focus,
.ui-datepicker .ui-widget-header .ui-datepicker-prev.active {
  outline: none;
}
.ui-datepicker .ui-widget-header .ui-datepicker-prev.swiper-button-disabled,
.ui-datepicker .ui-widget-header .ui-datepicker-prev.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-prev-dis.png);
}
.ui-datepicker .ui-datepicker-calendar {
  font-size: 14px;
  width: calc(100% - 0.625em*2);
  margin: 0.625em;
}
.ui-datepicker .ui-datepicker-calendar thead th {
  font-size: 14px;
  background-color: #f4f4f4;
  padding: 0.4em 0.3em;
}
.ui-datepicker .ui-datepicker-calendar thead th span {
  display: block;
  font-size: 0.813em;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  color: #003c71;
  text-transform: uppercase;
  line-height: 1;
}
.ui-datepicker .ui-datepicker-calendar thead th span[title="Sunday"] {
  color: #f1386d;
}
.ui-datepicker .ui-datepicker-calendar thead th span[title="Saturday"] {
  color: #206ee6;
}
.ui-datepicker .ui-datepicker-calendar tbody td {
  font-size: 14px;
}
.ui-datepicker .ui-datepicker-calendar tbody td .ui-state-default {
  font-size: 14px;
  width: 1.75em;
  height: 1.75em;
  border: none;
  background-color: #fff;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  text-align: center;
  padding: 0.375em 0;
  margin: 0 auto;
  line-height: 1;
}
.ui-datepicker .ui-datepicker-calendar tbody td .ui-state-default.ui-state-highlight {
  background-color: inherit;
  color: inherit;
}
.ui-datepicker .ui-datepicker-calendar tbody td .ui-state-default.ui-state-active {
  background-color: #003c71;
  color: white !important;
  border-radius: 50%;
}
.ui-datepicker .ui-datepicker-calendar tbody td.ui-datepicker-week-end:first-child .ui-state-default {
  color: #f1386d;
}
.ui-datepicker .ui-datepicker-calendar tbody td.ui-datepicker-week-end:last-child .ui-state-default {
  color: #206ee6;
}
.mfp-bottom-bar {
  display: none ;
}
.mfp-arrow::after {
  border: none;
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 20px;
}
.mfp-arrow-right::after {
  background-image: url(/asset/images/btn-w-pagination-line-arrow-white-next-act.png);
}
.mfp-arrow-left::after {
  background-image: url(/asset/images/btn-w-pagination-line-arrow-white-prev-act.png);
}
.mfp-close.btn_close {
  width: 20px;
  height: 20px;
  padding-right: 0;
  right: 0;
  top: 10px;
  background: url(/asset/images/icn-menu-close-white.png) no-repeat center / 18px;
}
/*-------------------------------------------------------------------
    Components
-------------------------------------------------------------------*/
/*inputs*/
input[type="text"]::placeholder {
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
}
.select_box01 {
  width: 100%;
  height: 32px;
  padding: 0 16px;
  background: #f4f4f4 url(/asset/images/object-arrow-down.png) no-repeat right 16px center / 8px;
  font-size: 11px;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  color: #003c71;
}
.select_box01 option {
  background-color: white;
}
.select_box02 {
  width: 100%;
  height: 32px;
  font-size: 12px;
  font-weight: 300;
  color: #003c71;
  background: #fff url(/asset/images/object-arrow-down.png) no-repeat right 12px center / 14px;
  padding: 0 12px;
}
.check_box input[type="checkbox"] {
  display: none;
}
.check_box input[type="checkbox"] + label span {
  display: inline-block;
  font-size: 0;
  width: 14px;
  height: 14px;
  margin: -2px 0px 0 0;
  vertical-align: -3px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #003c71;
  cursor: pointer;
}
.check_box input[type="checkbox"]:checked + label span {
  background: #003c71 url(/asset/images/icn-check-checked-14-px.png) no-repeat center;
}
.check_box > span {
  font-size: 10px;
  font-weight: bold;
  color: #003c71;
}
.check_box02 {
  font-size: 0;
}
.check_box02::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.check_box02 input[type="checkbox"] {
  display: none;
}
.check_box02 input[type="checkbox"] + label {
  display: block;
  float: left;
  width: 20px;
  height: 26px;
}
.check_box02 input[type="checkbox"] + label span {
  display: inline-block;
  font-size: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff url(/asset/images/icn-check-unchecked-18-px.png) no-repeat center / 8px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  position: relative;
  top: 3px;
}
.check_box02 input[type="checkbox"]:checked + label span {
  border: 1px solid #003c71;
  background-image: url(/asset/images/icn-check-checked-14-px.png);
  background-color: #003c71;
}
.check_box02 > span {
  width: calc(100% - 20px);
  float: left;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: -0.23px;
  color: #666;
}
.check_box02.big input[type="checkbox"] + label {
  width: 22px;
}
.check_box02.big input[type="checkbox"] + label span {
  width: 18px;
  height: 18px;
  background-size: 10px;
  top: -1px;
}
.check_box02.big > span {
  width: calc(100% - 22px);
  font-size: 10px;
  font-weight: bold;
  color: #003c71;
}
.check_box02.big > span .cmt {
  font-size: 9px;
  font-weight: inherit;
  color: inherit;
}
.input_box01 {
  border: 1px solid #f4f4f4;
  background-color: #f8f8f8;
  height: 24px;
  font-size: 0;
}
.input_box01 input,
.input_box01 select {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  background-color: #f8f8f8;
  font-size: 9px;
  font-weight: 300;
  color: #003c71;
}
.input_box01 input::placeholder,
.input_box01 select::placeholder {
  color: #c4c4c4;
}
.input_box01 select {
  padding: 0 24px 0 8px;
  background: #f8f8f8 url(/asset/images/object-arrow-down.png) no-repeat right 8px center / 8px;
}
.input_box01 select option {
  background-color: white;
}
.input_box01.datepicker input {
  padding: 0 26px 0 8px;
  background: #f8f8f8 url(/asset/images/icn-calandar-act.png) no-repeat right 8px center / 10px;
  color: #003c71;
}
.input_box01.width_button {
  padding-right: 80px;
  position: relative;
}
.input_box01.width_button button,
.input_box01.width_button label {
  position: absolute;
  top: -1px;
  right: 0;
}
.input_box01.homepage input {
  color: #666;
}
.file_upload_box::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.file_upload_box .input_box01 {
  float: left;
  width: 100%;
  margin-bottom: 5px;
}
.file_upload_box .input_box01:last-child {
  margin-bottom: 0;
}
.file_upload_box .input_box01 input[type="file"] {
  display: none ;
}
.file_upload_box .button_box {
  float: left;
  display: none ;
  width: 24px;
  height: 24px;
  position: relative;
}
.file_upload_box .button_box button {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #003c71;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 8px;
  font-size: 0;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.file_upload_box .button_box button.btn_refresh {
  background-image: url(/asset/images/icn-write-attachfile-refresh.png);
}
.file_upload_box .button_box button.btn_delete {
  background-image: url(/asset/images/icn-write-attachfile-delete.png);
}
.file_upload_box.show .input_box01 {
  width: calc(100% - 24px);
}
.file_upload_box.show .button_box {
  display: block;
}
.option_box {
  border: 1px solid #f4f4f4;
  background-color: #f8f8f8;
  padding: 0 8px;
  font-weight: 300;
  text-align: left;
  height: 24px;
  font-size: 9px;
  letter-spacing: -0.5px;
}
.option_box:active,
.option_box:focus {
  border: 1px solid #003c71;
}
.option_box.select_date {
  width: 100%;
  color: #003c71;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  padding: 0 18px 0 8px;
  background: #f8f8f8 url(/asset/images/icn-calandar-act.png) no-repeat right 8px center / 10px;
}
.option_box.select_date::placeholder {
  font-weight: 300;
  font-family: 'Noto Sans KR';
  color: inherit;
}
.option_box.select_options {
  font-family: 'Noto Sans KR';
  background: #f8f8f8 url(/asset/images/object-arrow-down.png) no-repeat right 8px center / 8px;
}
.option_box.select_search {
  height: 24px;
}
.option_box.select_search::placeholder {
  color: #ddd;
}
/*buttons*/
.btn_read_more {
  padding: 3px 0;
  display: inline-block;
  font-size: 0;
}
.btn_read_more .inner {
  display: inline-block;
  font-size: 0;
  border-bottom: 1px solid #003c71;
  padding-bottom: 2px;
}
.btn_read_more .inner span {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 9px;
  color: #003c71;
}
.btn_read_more .inner span::after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100%;
  background-image: url(/asset/images/btn-readmore-linearrow.png);
  margin-left: 3px;
  vertical-align: -2px;
}
.btn_map {
  padding: 2px 3px 3px;
  background-color: #003c71;
  border: 1px solid #003c71;
}
.btn_map span {
  font-size: 9px;
  font-weight: bold;
  color: white;
}
.btn_map span::after {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 5px;
  height: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/asset/images/icn-btn-outlink.png);
  margin-left: 2px;
  vertical-align: 4px;
}
.btn_culture_event_view {
  display: inline-block;
  font-size: 0;
  padding: 2px 2px 3px;
  border: 1px solid #003c71;
}
.btn_culture_event_view span {
  font-size: 9px;
  font-weight: bold;
  letter-spacing: -1px;
  color: #003c71;
}
.btn_culture_event_view span::after {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 5px;
  height: 5px;
  margin-left: 2px;
  background: url(/asset/images/icn-arrow.png) no-repeat center / 100%;
  vertical-align: 1px;
}
.btn_register {
  display: inline-block;
  font-size: 0;
  min-width: 68px;
  text-align: center;
  padding: 3px 3px 4px;
  background-color: #003c71;
}
.btn_register span {
  font-size: 9px;
  font-weight: 500;
  letter-spacing: -0.8px;
  color: white;
}
.btn_register span::before {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 9px;
  height: 9px;
  background: url(/asset/images/icn-write.png) no-repeat center / 100%;
  margin-right: 2px;
  vertical-align: -1px;
}
.btn_rg_request {
  display: inline-block;
  font-size: 0;
  padding: 2px 3px 3px;
  background-color: #fff;
  border: 1px solid #003c71;
  text-align: center;
}
.btn_rg_request span {
  font-size: 9px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #003c71;
}
.btn01 {
  display: inline-block;
  font-size: 0;
  padding: 7px 0;
  width: 112px;
  background-color: #003c71;
  text-align: center;
}
.btn01 span {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: white;
}
.btn02 {
  width: 80px;
  padding: 4px 0 5px;
  text-align: center;
  background-color: #003c71;
  display: inline-block;
  cursor: pointer;
}
.btn02 span {
  display: block;
  font-size: 9px;
  font-weight: 500;
  color: #fff;
}
.btn_file_add {
  display: inline-block;
  font-size: 0;
  padding: 2px 2px 3px;
  background-color: white;
  border: 1px solid #003c71;
  cursor: pointer;
}
.btn_file_add span {
  font-size: 9px;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #003c71;
}
.btn_file_add span::before {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 9px;
  height: 9px;
  background: url(/asset/images/icn-write-navy.png) no-repeat center / 100%;
  margin-right: 2px;
  vertical-align: -1px;
}
.btn_download {
  display: inline-block;
  font-size: 0;
  padding: 6px 6px 7px;
  background-color: #003c71;
  text-align: center;
}
.btn_download span {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.42px;
  color: white;
}
.btn_download span::before {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 9px;
  height: 9px;
  background: url(/asset/images/icn-attachedfile-white.png) no-repeat center / 7px;
  vertical-align: -1px;
  margin-right: 5px;
}
.btn_file_download {
  display: inline-block;
  font-size: 0;
  max-width: 100%;
  border-radius: 24px;
  background-color: #f8f8f8;
  padding: 6px 14px;
}
.btn_file_download span {
  display: inline-block;
  max-width: 100%;
  font-size: 9px;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.btn_file_download span::before {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 8px;
  height: 8px;
  background: url(/asset/images/icn-attachedfile.png) no-repeat center / 7px;
  margin-right: 5px;
  vertical-align: -1px;
}
.btn_top {
  position: fixed;
  right: 16px;
  bottom: 0;
  z-index: 10;
  padding-bottom: 16px;
}
.btn_top span {
  display: block;
  width: 24px;
  height: 24px;
  border: 1px solid #003c71;
  background: white url(/asset/images/icn-w-top.png) no-repeat center / 6px;
  transform: scale(0);
  transition: transform 0.2s ease-in;
  cursor: pointer;
}
.btn_top.show span {
  transform: scale(1);
}
.btn_top.sticky {
  position: absolute;
  bottom: auto;
  top: -40px;
}
/*card_box*/
.card_box01 a {
  display: block;
}
.card_box01 a::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.card_box01 a .img_box .img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: lightgrey;
}
.card_box01 a figcaption .group {
  font-size: 8px;
  font-weight: 500;
  color: #003c71;
  margin-bottom: 5px;
  text-indent: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card_box01 a figcaption .title,
.card_box01 a figcaption .subtitle {
  font-weight: 300;
  line-height: 1;
}
.card_box01 a figcaption .desc {
  display: none ;
}
.card_box01 a.ver1 .img_box .img {
  padding-top: 48.6%;
}
.card_box01 a.ver1 figcaption {
  padding-top: 10px;
}
.card_box01 a.ver1 figcaption .title,
.card_box01 a.ver1 figcaption .subtitle {
  font-size: 16px;
}
.card_box01 a.ver1 figcaption .title {
  letter-spacing: -0.5px;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.6em;
  -webkit-line-clamp: 2;
  margin-bottom: 10px;
}
.card_box01 a.ver1 figcaption .subtitle {
  font-size: 12px;
  margin-bottom: 10px;
  white-space: nowrap;
}
.card_box01 a.ver2 .left.img_box .img {
  padding-top: 96.8%;
}
.card_box01 a.ver2 figcaption {
  padding-top: 5px;
}
.card_box01 a.ver2 figcaption .title,
.card_box01 a.ver2 figcaption .subtitle {
  letter-spacing: -0.23px;
}
.card_box01 a.ver2 figcaption .title {
  line-height: 1.27;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.54em;
  -webkit-line-clamp: 2;
  font-size: 11px;
  margin-bottom: 5px;
}
.card_box01 a.ver2 figcaption .subtitle {
  font-size: 8px;
  line-height: 1.38;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.76em;
  -webkit-line-clamp: 2;
}
.card_box01 a.ver3 .left,
.card_box01 a.ver3 .right {
  float: left;
}
.card_box01 a.ver3 .left {
  width: 43.8%;
}
.card_box01 a.ver3 .left.img_box .img {
  padding-top: 127.8%;
}
.card_box01 a.ver3 .right {
  width: 56.2%;
  padding-left: 4%;
}
.card_box01 a.ver3 figcaption .title,
.card_box01 a.ver3 figcaption .subtitle {
  letter-spacing: -0.33px;
}
.card_box01 a.ver3 figcaption .title {
  line-height: 1.31;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.62em;
  -webkit-line-clamp: 2;
  font-size: 16px;
  margin-bottom: 5px;
}
.card_box01 a.ver3 figcaption .subtitle {
  font-size: 11px;
  margin-bottom: 15px;
  line-height: 1.45;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.9em;
  -webkit-line-clamp: 2;
}
.card_box02 a {
  display: block;
}
.card_box02 a .img_box {
  position: relative;
}
.card_box02 a .img_box .img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: lightgrey;
  padding-top: 140%;
}
.card_box02 a .img_box .badge {
  display: block;
  min-width: 28px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1;
  padding: 3px 2px 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.card_box02 a .img_box .on_going_badge {
  width: 56px;
  height: 56px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.card_box02 a .img_box .on_going_badge::before {
  content: '';
  width: 0px;
  height: 0px;
  border-top: 56px solid transparent;
  border-right: 56px solid #003c71;
  position: absolute;
  right: 0;
  bottom: 0;
}
.card_box02 a .img_box .on_going_badge span {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 13px;
  line-height: 0.84;
  color: #fff;
  text-align: right;
  position: absolute;
  right: 5px;
  bottom: 7px;
}
.card_box02 a figcaption {
  padding-top: 6px;
}
.card_box02 a figcaption .title {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.6em;
  -webkit-line-clamp: 2;
  margin-bottom: 5px;
}
.card_box02 a figcaption > ul > li {
  float: none;
  display: inline;
  word-break: keep-all;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.4;
  color: #666;
}
.card_box02 a figcaption > ul > li::after {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 1px;
  height: 6px;
  background-color: #ddd;
  margin-left: 4px;
  vertical-align: 1px;
}
.card_box02 a figcaption > ul > li:last-child::after {
  display: none ;
}
.card_box02 a figcaption .info_box li {
  float: none;
}
.card_box02 a figcaption .info_box li::after {
  display: none ;
}
.card_box02 a figcaption .location_box li {
  margin-right: 4px;
}
.card_box02 a figcaption .location_box .city::before {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 8px;
  height: 11px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/asset/images/icn-ongoing-pin.png);
  margin-right: 3px;
  vertical-align: -2px;
}
.card_box03 a {
  display: block;
}
.card_box03 a::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.card_box03 a .left,
.card_box03 a .right {
  float: left;
}
.card_box03 a .left {
  width: 30.6%;
}
.card_box03 a .right {
  width: 69.4%;
  padding-left: 4%;
}
.card_box03 a .img_box .img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: lightgrey;
  padding-top: 96.6%;
}
.card_box03 a figcaption .title,
.card_box03 a figcaption .subtitle {
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: -0.25px;
}
.card_box03 a figcaption .title {
  font-size: 12px;
  margin-bottom: 5px;
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 3.99em;
  -webkit-line-clamp: 3;
}
.card_box03 a figcaption .subtitle {
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card_box04 a {
  display: block;
}
.card_box04 a .img_box .img {
  padding-top: 134.6%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: lightgrey;
}
.card_box04 a figcaption {
  padding-top: 5px;
  text-align: center;
}
.card_box04 a figcaption .title {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 5px;
}
.card_box04 a figcaption .subtitle {
  font-size: 10px;
  font-weight: 300;
  letter-spacing: -0.21px;
}
.card_box05 a {
  display: block;
}
.card_box05 a .img_box .img {
  padding-top: 96.4%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: lightgrey;
}
.card_box05 a figcaption {
  padding-top: 8px;
}
.card_box05 a figcaption .title {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.7px;
  margin-bottom: 5px;
  line-height: 1.29;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.58em;
  -webkit-line-clamp: 2;
}
.card_box05 a figcaption .subtitle {
  font-size: 10px;
  font-weight: 500;
  color: #666;
  letter-spacing: -0.2px;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.8em;
  -webkit-line-clamp: 2;
}
.card_box06 a {
  display: block;
}
.card_box06 a .img_box .img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: lightgrey;
  padding-top: 96.4%;
}
.card_box06 a figcaption {
  padding-top: 10px;
}
.card_box06 a figcaption .title {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.7px;
  line-height: 1.29;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.58em;
  -webkit-line-clamp: 2;
  margin-bottom: 5px;
}
.card_box06 a figcaption .subtitle {
  font-size: 10px;
  font-weight: 500;
  color: #666;
  letter-spacing: -0.2px;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.8em;
  -webkit-line-clamp: 2;
}
/*box*/
.box01 {
  display: block;
  border-bottom: 1px solid #ddd;
  background-color: white;
  padding: 5px 8px 6px;
}
.box01 .badge_box {
  margin-bottom: 3px;
}
.box01 .badge_box .badge {
  padding: 0 4px 1px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.8px;
  color: white;
  margin-right: 3px;
}
.box01 .badge_box .badge:last-child {
  margin-right: 0;
}
.box01 .title {
  font-size: 11px;
  font-weight: 300;
  letter-spacing: -0.62px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.box01 .date {
  display: none ;
}
.box01.impt {
  background-color: #f8f8f8;
}
.box02 {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  padding: 7px 0 8px;
  text-align: center;
}
.box02 span {
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.5px;
}
.list_box02 {
  display: block;
  width: 100%;
  padding: 10px 5px;
  border-bottom: 1px solid #ddd;
}
.list_box02::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.list_box02 .col {
  float: left;
}
.list_box02 .img_box {
  width: 15%;
}
.list_box02 .img_box .img {
  width: 100%;
  padding-top: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: lightgrey;
}
.list_box02 .info_box {
  width: 85%;
  padding-left: 8px;
}
.list_box02 .info_box .kind {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.11px;
  color: #003c71;
}
.list_box02 .info_box .title {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 1.45;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.9em;
  -webkit-line-clamp: 2;
  margin-bottom: 3px;
}
.list_box02 .info_box ul li {
  font-size: 9px;
  font-weight: 300;
  margin-right: 10px;
}
.list_box02 .info_box ul li::after {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 1px;
  height: 6px;
  background-color: #f4f4f4;
  margin-left: 10px;
}
.list_box02 .date_box {
  display: none ;
}
.list_box03 .left .title {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 13px;
  margin-bottom: 10px;
}
.list_box03 .right > p {
  letter-spacing: -0.5px;
}
.list_box03 .right .img_box {
  margin-bottom: 10px;
}
.list_box03 .right .img_box img {
  display: block;
}
.list_box03 .right .color_box {
  width: 150px;
  border: 1px solid #003c71;
}
.list_box03 .right .color_box .title {
  padding: 4px 10px 6px;
  background-color: #003c71;
}
.list_box03 .right .color_box .title p {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
}
.list_box03 .right .color_box .cont {
  padding: 15px 10px;
  background-color: #fff;
}
.list_box03 .right .color_box .cont p {
  font-size: 10px;
  font-weight: 500;
  color: #003c71;
  line-height: 1.4;
}
.img_sld_box {
  display: block;
  height: 56px;
}
.img_sld_box img {
  width: auto;
  height: 100%;
}
/*tabs*/
.tab01 {
  text-align: center;
}
.tab01 .inner {
  border-bottom: 1px solid #c4c4c4;
}
.tab01 .inner > ul,
.tab01 .inner .btn_read_more {
  vertical-align: middle;
}
.tab01 .inner ul {
  display: inline-block;
  font-size: 0;
}
.tab01 .inner ul li a {
  display: block;
  font-size: 11px;
  font-weight: 500;
  color: #999;
  padding: 5px 0;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
}
.tab01 .inner ul li a::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  right: 50%;
  bottom: -1px;
  background: #003c71;
  height: 2px;
  transition-property: left, right;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.tab01 .inner ul li a.on {
  color: #003c71;
}
.tab01 .inner ul li a.on::before {
  left: 0;
  right: 0;
}
.tab01 .inner .btn_read_more {
  margin-right: 4px;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/asset/images/m-btn-news-readmore.png);
}
.tab01 .inner .btn_read_more .inner {
  display: none ;
}
.tab02 {
  font-size: 0;
  text-align: right;
}
.tab02 .inner {
  display: inline-block;
  font-size: 0;
}
.tab02 .inner > ul,
.tab02 .inner .btn_map {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}
.tab02 .inner > ul {
  margin-right: 28px;
}
.tab02 .inner > ul li {
  margin-right: 8px;
}
.tab02 .inner > ul li::after {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 1px;
  height: 8px;
  background-color: #f4f4f4;
  margin-left: 8px;
  vertical-align: 3px;
}
.tab02 .inner > ul li:last-child {
  margin-right: 0;
}
.tab02 .inner > ul li:last-child::after {
  display: none ;
}
.tab02 .inner > ul li a {
  font-size: 13px;
  color: #ddd;
  font-weight: bold;
}
.tab02 .inner > ul li a::before {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  vertical-align: -3px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.tab02 .inner > ul li a.list::before {
  background-image: url(/asset/images/icn-ongoing-list-dis.png);
  background-size: 14px;
}
.tab02 .inner > ul li a.calender::before {
  background-image: url(/asset/images/icn-calandar-dis.png);
  background-size: 14px;
}
.tab02 .inner > ul li a.on {
  color: #003c71;
}
.tab02 .inner > ul li a.on.list::before {
  background-image: url(/asset/images/icn-ongoing-list-act.png);
}
.tab02 .inner > ul li a.on.calender::before {
  background-image: url(/asset/images/icn-calandar-act.png);
}
/*panels*/
.panel01 .panel_tit {
  padding-bottom: 10px;
  text-align: center;
}
.panel01 .panel_tit p {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 24px;
  letter-spacing: 0.92px;
  color: #003c71;
}
.panel02 .panel_tit {
  font-size: 15px;
  font-weight: bold;
  color: #003c71;
  margin-bottom: 5px;
}
.panel02 .panel_cont p {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
}
.panel02 .panel_cont p span {
  font-weight: inherit;
  line-height: inherit;
  font-size: inherit;
}
.panel02 .panel_cont p span.strong {
  font-weight: bold;
}
.panel02 .panel_cont p span.point {
  font-weight: bold;
  color: #003c71;
}
.panel02 .panel_cont p span.point.eFont {
  font-size: 14px;
}
.panel03 {
  border-top: 2px solid #f4f4f4;
  border-bottom: 2px solid #f4f4f4;
  padding: 15px 0 20px;
  text-align: center;
}
.panel03 .panel_tit .title {
  font-size: 13px;
  font-weight: bold;
  color: #003c71;
}
.panel03 .panel_tit .title .num {
  display: block;
  font-size: 15px;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  color: inherit;
}
.panel03 .panel_tit .subtitle {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  margin-top: 5px;
  word-break: keep-all;
}
.panel03 .panel_tit .cmt {
  font-size: 9px;
  font-weight: 500;
  color: #666;
}
.panel03 .panel_cont {
  padding-top: 20px;
}
.panel04 .panel_tit {
  padding-bottom: 10px;
  text-align: center;
}
.panel04 .panel_tit .title {
  font-size: 14px;
  font-weight: bold;
  color: #003c71;
}
.panel04 .panel_cont {
  border-top: 1px solid #003c71;
  border-bottom: 1px solid #003c71;
}
.panel05 .panel_tit {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.7px;
  margin-bottom: 8px;
}
/*tables*/
.table01 {
  border-top: 1px solid #003c71;
  border-bottom: 1px solid #003c71;
}
.table01 .tr {
  display: block;
  border-top: 1px solid #c4c4c4;
  padding: 0 6px;
}
.table01 .tr::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.table01 .tr:first-child {
  border-top: none;
}
.table01 .tr.thead {
  display: none ;
}
.table01 .tr .th,
.table01 .tr .td {
  float: left;
  text-align: center;
  letter-spacing: -0.6px;
  padding: 4px 0;
  font-size: 11px;
}
.table01 .tr .td {
  font-weight: 300;
}
.table01 .tr .td .badge_box .badge {
  display: inline-block;
  padding: 1px 8px 2px;
  font-size: 9px;
  color: white;
  min-width: 28px;
  text-align: center;
  margin-right: 5px;
}
.table01 .tr .td .badge_box .badge:last-child {
  margin-right: 0;
}
.table01 .tr .td:nth-child(2) {
  text-align: left;
}
.table01 .tr .td:nth-child(2) .title {
  font-weight: inherit;
}
.table01 .tr .td:nth-child(2) .title p {
  display: inline-block;
  font-size: 0;
  max-width: 100%;
  font-size: 11px;
  padding-right: 10px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table01 .tr .td.new:nth-child(2) .title p::after {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 8px;
  height: 8px;
  background: url(/asset/images/icn-new.png) no-repeat center / 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.table01 .tr.impt {
  background-color: #f8f8f8;
}
.table02 {
  border-top: 1px solid #003c71;
  border-bottom: 1px solid #003c71;
}
.table02 .tr {
  display: block;
  border-top: 1px solid #c4c4c4;
  padding: 10px 0;
  position: relative;
}
.table02 .tr.thead {
  display: none ;
}
.table02 .tr:nth-child(2) {
  border-top: none;
}
.table02 .tr .td {
  font-size: 11px;
  letter-spacing: -0.62px;
  font-weight: 300;
}
.table02 .tr .td .badge_box {
  font-size: 0;
  margin-bottom: 2px;
}
.table02 .tr .td .badge_box .badge {
  display: inline-block;
  padding: 0 0 1px;
  font-size: 10px;
  color: white;
  letter-spacing: -0.8px;
  min-width: 28px;
  text-align: center;
}
.table02 .tr .td:nth-child(3) p {
  font-size: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table02 .tr .td .edit_buttons li::after {
  content: 'I';
  font-size: 10px;
  color: #f4f4f4;
}
.table02 .tr .td .edit_buttons li:last-child::after {
  display: none ;
}
.table02 .tr .td .edit_buttons li button {
  padding: 5px;
  font-size: 10px;
  color: #003c71;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
}
.table03 {
  table-layout: fixed;
  width: 100%;
  border-top: 1px solid #003c71;
}
.table03 th,
.table03 td {
  padding: 10px 5px;
  border-bottom: 1px solid #ddd;
  font-size: 11px;
  letter-spacing: -0.7px;
  text-align: center;
}
.table03 th {
  background-color: #f8f8f8;
  font-weight: 500;
}
.table03 td {
  font-weight: 300;
  word-break: keep-all;
}
.table03 td a {
  font-weight: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-decoration: underline;
  word-break: break-all;
}
.table03 td.tal {
  text-align: left;
}
.table03.tdrow td {
  padding: 10px 10px;
}
/*lists*/
.list01 li {
  float: none;
  font-size: 8px;
  font-weight: 300;
  margin-bottom: 5px;
  position: relative;
  padding-left: 5px;
  word-break: keep-all;
}
.list01 li:last-child {
  margin-bottom: 0;
}
.list01 li::before {
  content: '';
  display: block;
  width: 2px;
  height: 2px;
  background-color: #c4c4c4;
  position: absolute;
  left: 0;
  top: 8px;
}
.list01 li a {
  font-size: inherit;
  font-weight: inherit;
}
.list02 li {
  float: none;
  font-size: 8px;
  font-weight: 300;
  margin-bottom: 5px;
  position: relative;
  padding-left: 6px;
  word-break: keep-all;
}
.list02 li:last-child {
  margin-bottom: 0;
}
.list02 li::before {
  content: '';
  display: block;
  width: 2px;
  height: 2px;
  background-color: #c4c4c4;
  position: absolute;
  left: 0;
  top: 7px;
}
/*pagination*/
.paging_area {
  text-align: center;
}
.paging_area .pagination {
  display: inline-block;
  font-size: 0;
}
.paging_area .pagination > div {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}
.paging_area .pagination .prev,
.paging_area .pagination .next {
  width: 24px;
  height: 24px;
  border: 1px solid #003c71;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  background-size: 6px;
  cursor: pointer;
}
.paging_area .pagination .prev.swiper-button-disabled,
.paging_area .pagination .next.swiper-button-disabled,
.paging_area .pagination .prev.disabled,
.paging_area .pagination .next.disabled {
  border: 1px solid #ddd;
}
.paging_area .pagination .prev {
  background-image: url(/asset/images/btn-arrow-onlyline-prev.png);
}
.paging_area .pagination .prev:focus,
.paging_area .pagination .prev.active {
  outline: none;
}
.paging_area .pagination .prev.swiper-button-disabled,
.paging_area .pagination .prev.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-prev-dis.png);
}
.paging_area .pagination .next {
  background-image: url(/asset/images/btn-arrow-onlyline-next.png);
}
.paging_area .pagination .next:focus,
.paging_area .pagination .next.active {
  outline: none;
}
.paging_area .pagination .next.swiper-button-disabled,
.paging_area .pagination .next.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-next-dis.png);
}
.paging_area .pagination .num {
  margin: 0 10px;
}
.paging_area .pagination .num a {
  display: inline-block;
  font-size: 0;
  width: 10px;
  height: 10px;
  border: 1px solid white;
  border-radius: 50%;
  background-color: white;
  position: relative;
  margin-right: 2px;
}
.paging_area .pagination .num a:last-child {
  margin-right: 0;
}
.paging_area .pagination .num a:after {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #003c71;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.paging_area .pagination .num a.on {
  border: 1px solid #003c71;
}
.paging_area .pagination02 {
  display: inline-block;
  font-size: 0;
}
.paging_area .pagination02 > div {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}
.paging_area .pagination02 .prev,
.paging_area .pagination02 .next,
.paging_area .pagination02 .prev_first,
.paging_area .pagination02 .next_last {
  width: 24px;
  height: 24px;
  border: 1px solid #003c71;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  background-size: 6px;
  cursor: pointer;
}
.paging_area .pagination02 .prev.swiper-button-disabled,
.paging_area .pagination02 .next.swiper-button-disabled,
.paging_area .pagination02 .prev_first.swiper-button-disabled,
.paging_area .pagination02 .next_last.swiper-button-disabled,
.paging_area .pagination02 .prev.disabled,
.paging_area .pagination02 .next.disabled,
.paging_area .pagination02 .prev_first.disabled,
.paging_area .pagination02 .next_last.disabled {
  border: 1px solid #ddd;
}
.paging_area .pagination02 .prev {
  background-image: url(/asset/images/btn-arrow-onlyline-prev.png);
}
.paging_area .pagination02 .prev:focus,
.paging_area .pagination02 .prev.active {
  outline: none;
}
.paging_area .pagination02 .prev.swiper-button-disabled,
.paging_area .pagination02 .prev.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-prev-dis.png);
}
.paging_area .pagination02 .prev_first {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-first.png);
  background-size: 10px;
  margin-right: 4px;
}
.paging_area .pagination02 .prev_first:focus,
.paging_area .pagination02 .prev_first.active {
  outline: none;
}
.paging_area .pagination02 .prev_first.swiper-button-disabled,
.paging_area .pagination02 .prev_first.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-first-dis.png);
}
.paging_area .pagination02 .next {
  background-image: url(/asset/images/btn-arrow-onlyline-next.png);
  margin-right: 4px;
}
.paging_area .pagination02 .next:focus,
.paging_area .pagination02 .next.active {
  outline: none;
}
.paging_area .pagination02 .next.swiper-button-disabled,
.paging_area .pagination02 .next.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-next-dis.png);
}
.paging_area .pagination02 .next_last {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-last.png);
  background-size: 10px;
}
.paging_area .pagination02 .next_last:focus,
.paging_area .pagination02 .next_last.active {
  outline: none;
}
.paging_area .pagination02 .next_last.swiper-button-disabled,
.paging_area .pagination02 .next_last.disabled {
  background-image: url(/asset/images/icn-w-pagination-line-arrow-last-dis.png);
}
.paging_area .pagination02 .num {
  margin: 0 20px;
}
.paging_area .pagination02 .num a {
  display: inline-block;
  font-size: 0;
  margin-right: 10px;
}
.paging_area .pagination02 .num a:last-child {
  margin-right: 0;
}
.paging_area .pagination02 .num a span {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 10px;
  color: #c4c4c4;
}
.paging_area .pagination02 .num a.on span {
  color: #003c71;
}
/*header*/
header {
  background-color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}
header .wrap01 {
  position: relative;
}
header .btn_ham {
  display: block;
  width: 20px;
  height: 14px;
  position: absolute;
  right: 21px;
  top: 18px;
  z-index: 20;
  cursor: pointer;
}
header .btn_ham::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease-in;
}
header .btn_ham span {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #003c71;
  position: absolute;
  left: 0;
  transition: top 0.2s ease-in, transform 0.2s ease-in, background-color 0.2s ease-in;
}
header .btn_ham span:nth-child(1) {
  top: 0;
}
header .btn_ham span:nth-child(2) {
  top: calc(50% - 1px);
}
header .btn_ham span:nth-child(3) {
  top: calc(100% - 1px);
}
header .btn_ham.close span:first-child {
  top: 50%;
  transform: rotate(45deg);
}
header .btn_ham.close span:nth-child(2) {
  width: 0;
}
header .btn_ham.close span:last-child {
  top: 50%;
  transform: rotate(-45deg);
}
header .btn_ham.close_ver1 span:first-child {
  top: 50%;
  transform: rotate(45deg);
}
header .btn_ham.close_ver1 span:nth-child(2) {
  width: 0;
}
header .btn_ham.close_ver1 span:last-child {
  top: 50%;
  transform: rotate(-45deg);
}
header .btn_ham.close_ver1 span {
  background-color: #fff;
}
header .btn_friends_close {
  visibility: hidden;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 16px;
  top: 10px;
  z-index: 20;
  cursor: pointer;
  background-color: #003c71;
  padding: 8px 5px;
}
header .btn_friends_close .inner {
  width: 20px;
  height: 14px;
  position: relative;
}
header .btn_friends_close .inner span {
  display: block;
  width: 100%;
  height: 1px;
  background-color: white;
  position: absolute;
  left: 0;
  transition: top 0.3s ease-in, transform 0.3s ease-in;
}
header .btn_friends_close .inner span:nth-child(1) {
  top: 0;
}
header .btn_friends_close .inner span:nth-child(2) {
  top: calc(50% - 1px);
}
header .btn_friends_close .inner span:nth-child(3) {
  top: calc(100% - 1px);
}
header .btn_friends_close.show {
  visibility: visible;
}
header .btn_friends_close.show span:first-child {
  top: 50%;
  transform: rotate(45deg);
}
header .btn_friends_close.show span:nth-child(2) {
  width: 0;
}
header .btn_friends_close.show span:last-child {
  top: 50%;
  transform: rotate(-45deg);
}
header .top_gnb_area {
  padding: 10px 0 0;
}
header .top_gnb_area::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
header .top_gnb_area > div {
  float: right;
}
header .top_gnb_area > div > ul {
  display: inline-block;
  font-size: 0;
  margin-right: 39px;
}
header .top_gnb_area > div > ul li {
  margin-right: 14px;
}
header .top_gnb_area > div > ul li:last-child {
  margin-right: 0;
}
header .top_gnb_area > div > ul li a {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.33px;
  color: #003c71;
}
header .top_gnb_area > div > ul li.lang > a {
  color: #ddd;
}
header .top_gnb_area > div > ul li.lang > a::after {
  content: ' I ';
  color: #ddd;
}
header .top_gnb_area > div > ul li.lang > a:last-child::after {
  display: none ;
}
header .top_gnb_area > div > ul li.lang > a.on {
  color: #003c71;
}
header .main_gnb_area {
  font-size: 0;
  padding-bottom: 9px;
}
header .main_gnb_area nav {
  display: inline-block;
  vertical-align: text-bottom;
}
header .main_gnb_area nav ul li {
  float: none;
  display: inline-block;
  font-size: 0;
  vertical-align: text-bottom;
  margin-right: 5px;
}
header .main_gnb_area nav ul li:last-child {
  margin-right: 0;
}
header .main_gnb_area nav ul li:first-child {
  margin-right: 5px;
}
header .main_gnb_area nav ul li a {
  display: block;
  font-size: 23px;
  font-weight: 500;
  letter-spacing: -0.8px;
  color: #003c71;
  text-transform: capitalize;
}
header .main_gnb_area nav ul li a.on {
  color: #fff;
  background-color: #003c71;
}
header .main_gnb_area nav ul li .logo a {
  display: block;
  width: 60px;
  height: 64px;
}
header .main_gnb_area nav ul li .logo a img {
  display: block;
  height: 100%;
}
header .search_area {
  border-top: 1px solid #003c71;
  border-bottom: 1px solid #003c71;
  padding: 2px 0;
}
header .search_area .inner {
  position: relative;
  padding: 0 2px;
}
header .search_area .inner .ggc {
  width: 0;
  height: 18px;
  background-color: #003c71;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.3s ease-in;
  transition-delay: 0.2s;
  overflow: hidden;
}
header .search_area .inner .ggc span {
  position: relative;
  top: -7px;
  font-size: 16px;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  color: white;
  line-height: 1;
  letter-spacing: 2px;
}
header .search_area .inner form::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
header .search_area .inner form input[type="text"] {
  float: left;
}
header .search_area .inner form input[type="text"] {
  width: calc(100% - 25px);
  height: 18px;
  text-align: right;
  padding: 0 10px 0 53px;
  font-size: 12px;
  font-weight: 500;
  color: #003c71;
}
header .search_area .inner form input[type="text"]:focus {
  outline: none;
}
header .search_area .inner form .btn_search {
  float: right;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
header .search_area .inner form .btn_search span {
  width: 18px;
  height: 18px;
  display: block;
  background: #003c71 url(/asset/images/icn-zoom.png) no-repeat center / 12px;
}
header .search_area.sticky .inner .ggc {
  width: 43px;
}
header .friends {
  display: none ;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  z-index: 10;
  outline: 1px solid #003c71;
  padding-top: 10px;
}
header .friends .check_option_box {
  padding: 5px 0;
  border-bottom: 1px solid #c4c4c4;
}
header .friends .friends_list_box {
  padding-top: 10px;
}
header .friends .friends_list_box .keyword {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 24px;
  color: #003c71;
}
header .friends .friends_list_box > a {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  letter-spacing: -0.84px;
  margin-right: 5px;
  word-break: keep-all;
}
header .site_map {
  display: none ;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #0e4278;
  z-index: 10;
}
header .site_map .wrap01 {
  padding: 0;
}
header .site_map .wrap01 .group1 {
  padding: 40px 16px 16px;
  background-color: #093766;
}
header .site_map .wrap01 .group1 a {
  display: inline-block;
  font-size: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}
header .site_map .wrap01 .group1 a:last-child {
  margin-right: 0;
}
header .site_map .wrap01 .group1 a span {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.59px;
  line-height: 1;
  color: white;
  text-transform: capitalize;
}
header .site_map .wrap01 .group2 {
  padding: 16px 16px;
  background-color: #003c71;
}
header .site_map .wrap01 .group2 ul {
  width: 100%;
}
header .site_map .wrap01 .group2 ul li {
  width: 45%;
  margin-bottom: 10px;
  margin-right: 10%;
  height: 44px;
}
header .site_map .wrap01 .group2 ul li:nth-child(2n) {
  margin-right: 0;
}
header .site_map .wrap01 .group2 ul li a {
  display: inline-block;
  font-size: 0;
}
header .site_map .wrap01 .group2 ul li a .title {
  display: inline-block;
  font-size: 0;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 20px;
  color: white;
  margin-bottom: 2px;
}
header .site_map .wrap01 .group2 ul li a .subtitle {
  font-size: 9px;
  font-weight: 500;
  color: #a5d0fd;
}
header .site_map .wrap01 .group3 {
  padding: 16px 16px;
  background-color: #0e4278;
}
header .site_map .wrap01 .group3 > dl {
  float: none;
  margin-bottom: 15px;
}
header .site_map .wrap01 .group3 > dl::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
header .site_map .wrap01 .group3 > dl:last-of-type {
  margin-bottom: 0;
}
header .site_map .wrap01 .group3 > dl dt a {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.59px;
  color: white;
  line-height: 1;
}
header .site_map .wrap01 .group3 > dl dd ul li {
  margin-right: 5px;
}
header .site_map .wrap01 .group3 > dl dd ul li::after {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #fff;
  margin-left: 5px;
  vertical-align: 5px;
}
header .site_map .wrap01 .group3 > dl dd ul li:last-child {
  margin-right: 0;
}
header .site_map .wrap01 .group3 > dl dd ul li:last-child::after {
  display: none ;
}
header .site_map .wrap01 .group3 > dl dd ul li a {
  font-size: 13px;
  font-weight: 500;
  color: white;
  letter-spacing: -0.36px;
  position: relative;
}
header .site_map .wrap01 .group3 > ul {
  margin-top: 25px;
}
header .site_map .wrap01 .group3 > ul li {
  margin-right: 10px;
}
header .site_map .wrap01 .group3 > ul li:last-child {
  margin-right: 0;
}
header .site_map .wrap01 .group3 > ul li a {
  font-size: 12px;
  letter-spacing: -0.33px;
  color: white;
}
header .site_map .wrap01 .group3 > ul li.lang > a {
  color: #999;
}
header .site_map .wrap01 .group3 > ul li.lang > a::after {
  content: ' I ';
  color: #999;
}
header .site_map .wrap01 .group3 > ul li.lang > a:last-child::after {
  display: none ;
}
header .site_map .wrap01 .group3 > ul li.lang > a.on {
  color: white;
}
header .site_map .wrap01 .group3 > ul.mode .login_after {
  display: none ;
}
header .site_map .wrap01 .group3 > ul.mode.login .login_before {
  display: none ;
}
header .site_map .wrap01 .group3 > ul.mode.login .login_after {
  display: block;
}
header.sticky {
  position: fixed;
}
header.sticky .main_gnb_area {
  display: none ;
}
header.sticky .search_area .inner .ggc {
  width: 43px;
}
/*footer*/
footer {
  border-top: 1px solid #ddd;
  padding: 26px 0 29px;
  text-align: center;
  position: relative;
}
footer .g1 .ft_logo {
  margin-bottom: 10px;
}
footer .g1 .ft_logo a {
  font-size: 0;
  display: inline-block;
  height: 43px;
}
footer .g1 .ft_logo a:nth-child(1) {
  margin-right: 10px;
}
footer .g1 .ft_logo a:nth-child(2) {
  pointer-events: none;
}
footer .g1 .ft_logo a img {
  display: block;
  margin: 0 auto;
  height: 100%;
}
footer .g2.left {
  margin-bottom: 10px;
}
footer .g2.left address {
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.4;
  color: #003c71;
  margin: 0 auto 10px;
}
footer .g2.left address a,
footer .g2.left address span {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  white-space: nowrap;
}
footer .g2.left .ft_list {
  display: inline-block;
}
footer .g2.left .ft_list li {
  margin-right: 8px;
}
footer .g2.left .ft_list li::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 5px;
  background-color: #e8e8e8;
  margin-left: 5px;
}
footer .g2.left .ft_list li:last-child::after {
  display: none ;
}
footer .g2.left .ft_list li a {
  font-size: 9px;
  font-weight: bold;
  color: #999;
}
footer .g2.right .select_box01 {
  width: 138px;
}
/*popup*/
.layer_popup {
  display: none ;
  width: 100%;
  min-width: 320px;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.86);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}
.layer_popup .popup_contents {
  max-width: calc(100% - 16px*2);
  width: 100%;
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  background-color: #fff;
}
.layer_popup .popup_contents .btn_close {
  width: 24px;
  height: 24px;
  background: #f4f4f4 url(/asset/images/icn-menu-close-navy.png) no-repeat center / 10px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
.layer_popup .popup_contents .title_box {
  padding: 30px 0;
  text-align: center;
  background: #003c71 url(/asset/images/img-ggc-popup-bg.png) no-repeat center / 100%;
}
.layer_popup .popup_contents .title_box p {
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.layer_popup .popup_contents .contents_box {
  padding: 20px 16px 30px;
}
.layer_popup.signup_notice .contents_box .desc {
  margin-bottom: 20px;
}
.layer_popup.signup_notice .contents_box .desc p {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  word-break: keep-all;
}
.layer_popup.signup_notice .contents_box .panel03 {
  margin-bottom: 15px;
}
.layer_popup.signup_notice .contents_box .panel03:last-of-type {
  margin-bottom: 0;
}
.layer_popup.signup_notice .contents_box .benefits {
  padding: 15px 0 5px;
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont {
  font-size: 0;
  padding-left: 10%;
  padding-right: 10%;
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont ul {
  width: 100%;
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont ul li {
  width: 50%;
  margin-bottom: 15px;
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont ul li:nth-child(1) .icon {
  background-image: url(/asset/images/pic-join-01.png);
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont ul li:nth-child(2) .icon {
  background-image: url(/asset/images/pic-join-02.png);
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont ul li:nth-child(3) .icon {
  background-image: url(/asset/images/pic-join-03.png);
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont ul li:nth-child(4) .icon {
  background-image: url(/asset/images/pic-join-04.png);
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont ul li:last-child {
  margin-right: 0;
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont ul li .icon {
  display: inline-block;
  font-size: 0;
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100%;
}
.layer_popup.signup_notice .contents_box .benefits .panel_cont ul li .text {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.4;
  color: #003c71;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont {
  text-align: left;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl {
  float: none;
  margin-bottom: 10px;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl:last-child {
  margin-bottom: 0;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl dt,
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl dd {
  font-size: 10px;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl dt {
  font-weight: 500;
  margin-bottom: 2px;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl dd {
  font-weight: 300;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl dd ul li {
  float: none;
  font-size: 10px;
  font-weight: 300;
  padding-left: 5px;
  position: relative;
  margin-bottom: 3px;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl dd ul li:last-child {
  margin-bottom: 0;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl dd ul li::before {
  content: '';
  display: block;
  width: 2px;
  height: 2px;
  background-color: #c4c4c4;
  position: absolute;
  left: 0;
  top: 7px;
}
.layer_popup.signup_notice .contents_box .application_form_guide .panel_cont dl dd a {
  font-size: inherit;
  font-weight: inherit;
}
.layer_popup.signup_notice .contents_box .closing_ment {
  margin-top: 20px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.36;
  text-align: center;
}
.layer_popup.signup_notice .contents_box .closing_ment .point {
  color: #003c71;
  font-size: inherit;
  font-weight: inherit;
}
.layer_popup.contents_request .contents_box .panel03 {
  margin-bottom: 16px;
  padding-top: 0;
  border-top: none;
}
.layer_popup.contents_request .contents_box .panel03:last-child {
  margin-bottom: 40px;
}
.layer_popup.contents_request .contents_box .panel03 .panel_tit .title {
  font-size: 15px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont {
  padding-top: 0;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list {
  display: inline-block;
  font-size: 0;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li {
  width: 50%;
  padding: 14px 0;
  text-align: center;
  position: relative;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li::after {
  content: '';
  display: block;
  width: 1px;
  height: 104px;
  background-color: #f4f4f4;
  position: absolute;
  right: 0;
  top: 14px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li::before {
  content: '';
  display: block;
  width: calc(100% - 12px);
  height: 1px;
  background-color: #f4f4f4;
  position: absolute;
  top: 0;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li:nth-child(odd) {
  padding-right: 12px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li:nth-child(odd)::before {
  left: 0;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li:nth-child(even) {
  padding-left: 12px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li:nth-child(even)::after {
  display: none ;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li:nth-child(even)::before {
  right: 0;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li:nth-child(-n+2)::before {
  display: none ;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .category {
  font-size: 12px;
  font-weight: 500;
  color: #003c71;
  margin-bottom: 5px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .icon {
  display: inline-block;
  font-size: 0;
  width: 36px;
  height: 36px;
  margin-bottom: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .icon.display {
  background-image: url(/asset/images/pic-request-01.png);
  background-size: 32px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .icon.play {
  background-image: url(/asset/images/pic-request-02.png);
  background-size: 25px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .icon.event {
  background-image: url(/asset/images/pic-request-03.png);
  background-size: 30px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .icon.education {
  background-image: url(/asset/images/pic-request-04.png);
  background-size: 36px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .icon.news {
  background-image: url(/asset/images/pic-request-05.png);
  background-size: 24px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .icon.modification_request {
  background-image: url(/asset/images/pic-request-06.png);
  background-size: 28px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .download_list > li {
  float: none;
  margin-bottom: 5px;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .download_list > li:last-child {
  margin-bottom: 0;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .download_list > li a {
  display: block;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.42px;
  text-decoration: underline;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .download_list > li a::before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: -3px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100%;
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .download_list > li a.hwp::before {
  background-image: url(/asset/images/icn-download-hangul.png);
}
.layer_popup.contents_request .contents_box .panel03.form_download .panel_cont .category_list > li .download_list > li a.docx::before {
  background-image: url(/asset/images/icn-download-msword.png);
}
.layer_popup.contents_request .contents_box .panel03.form_upload {
  border-bottom: none;
}
.layer_popup.contents_request .contents_box .panel03.form_upload .panel_cont dl {
  float: none;
  margin-bottom: 10px;
}
.layer_popup.contents_request .contents_box .panel03.form_upload .panel_cont dl:last-of-type {
  margin-bottom: 0;
}
.layer_popup.contents_request .contents_box .panel03.form_upload .panel_cont dl dt {
  width: 100px;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
}
.layer_popup.contents_request .contents_box .panel03.form_upload .panel_cont dl dd .file_upload_box.show .input_box01 {
  width: 100%;
}
.layer_popup.contents_request .contents_box .panel03.form_upload .panel_cont .btn_area {
  padding-top: 25px;
  text-align: center;
}
.layer_popup.contents_request .contents_box .precaution_box {
  background-color: #f8f8f8;
  padding: 15px 0 20px;
  position: relative;
}
.layer_popup.contents_request .contents_box .precaution_box .title {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 10px;
}
.layer_popup.contents_request .contents_box .precaution_box::before {
  content: '';
  display: block;
  width: 16px;
  height: 100%;
  background-color: #f8f8f8;
  position: absolute;
  left: -16px;
  top: 0;
}
.layer_popup.contents_request .contents_box .precaution_box::after {
  content: '';
  display: block;
  width: 16px;
  height: 100%;
  background-color: #f8f8f8;
  position: absolute;
  right: -16px;
  top: 0;
}
.layer_popup.privacy_policy .contents_box {
  max-height: 500px;
  overflow-y: auto;
}
.layer_popup.privacy_policy .contents_box h2 {
  font-size: 16px;
  font-weight: bold;
  color: #003c71;
  margin-bottom: 10px;
}
.layer_popup.privacy_policy .contents_box h2 span {
  font-size: 12px;
  font-weight: 500;
  color: inherit;
  white-space: nowrap;
}
.layer_popup.privacy_policy .contents_box > .desc {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.75;
  margin-bottom: 20px;
  word-break: keep-all;
}
.layer_popup.privacy_policy .contents_box .g1 {
  margin-bottom: 20px;
}
.layer_popup.privacy_policy .contents_box .g1 h3 {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.38;
  margin-bottom: 10px;
}
.layer_popup.privacy_policy .contents_box .g1 > .desc {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.57;
  margin-bottom: 5px;
  word-break: keep-all;
}
/*tour*/
.tour_box {
  background-color: #003c71;
  position: relative;
}
.tour_box .sc_title {
  padding: 20px 0;
}
.tour_box .sc_title h2,
.tour_box .sc_title p {
  color: white !important;
}
.tour_box .sc_cont {
  position: relative;
  z-index: 1;
}
.tour_box .sc_cont .tour_search_box {
  background-color: #f4f4f4;
  padding: 32px 24px 0;
  margin: 0 auto;
  text-align: center;
}
.tour_box .sc_cont .tour_search_box::after {
  content: '';
  width: 100%;
  height: 24px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
}
.tour_box .sc_cont .tour_search_box dl {
  float: none;
  margin-bottom: 16px;
}
.tour_box .sc_cont .tour_search_box dl::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.tour_box .sc_cont .tour_search_box dl:last-child {
  margin-bottom: 0;
}
.tour_box .sc_cont .tour_search_box dl dt,
.tour_box .sc_cont .tour_search_box dl dd {
  float: left;
}
.tour_box .sc_cont .tour_search_box dl dt {
  width: 44px;
  font-size: 10px;
  font-weight: bold;
  color: #666;
  padding-right: 5px;
  line-height: 32px;
}
.tour_box .sc_cont .tour_search_box dl dd {
  width: calc(100% - 44px);
}
.tour_box .sc_cont .tour_search_box .tour_input {
  width: 100%;
  height: 32px;
  color: #003c71;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 12px;
  padding: 0 38px 0 12px;
  background: #fff url(/asset/images/icn-calandar-act.png) no-repeat right 12px center / 14px;
}
.tour_box .sc_cont .tour_search_box .tour_input::placeholder {
  font-size: 12px;
  font-weight: 300;
  font-family: 'Noto Sans KR';
  color: inherit;
}
.tour_box .sc_cont .tour_search_box .location::after,
.tour_box .sc_cont .tour_search_box .date::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.tour_box .sc_cont .tour_search_box .location {
  margin-bottom: 16px;
}
.tour_box .sc_cont .tour_search_box .date {
  margin-bottom: 24px;
}
.tour_box .sc_cont .tour_search_box .date dl {
  position: relative;
}
.tour_box .sc_cont .tour_search_box .date dl:first-child::before {
  content: '';
  display: block;
  width: 12px;
  height: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/asset/images/m-icn-arrow-onlyline-down.png);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -12px;
}
.tour_box .sc_cont .tour_search_box .btn_tour_search {
  width: 65.3%;
  text-align: center;
  padding: 7px 0;
  background-color: #003c71;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.tour_box .sc_cont .tour_search_box .btn_tour_search span {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
  margin-right: 5px;
}
.tour_box .sc_cont .tour_search_box .btn_tour_search span::before {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/asset/images/zoom-white.png);
  margin-right: 10px;
  vertical-align: -2px;
}
.tour_box02 .title {
  padding: 4px 0 6px;
  text-align: center;
  background-color: #003c71;
}
.tour_box02 .title p {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 14px;
  color: white;
  line-height: 1;
}
.tour_box02 .title p .subtitle {
  font-weight: bold;
  font-size: 10px;
  letter-spacing: -0.31px;
  color: inherit;
  display: inline-block;
  margin-left: 10px;
}
.tour_box02 .tour_search_box {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}
.tour_box02 .tour_search_box .location,
.tour_box02 .tour_search_box .date {
  margin-bottom: 5px;
}
.tour_box02 .tour_search_box .date::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.tour_box02 .tour_search_box .date > .input_box01 {
  width: calc((100% - 32px)/2);
  float: left;
}
.tour_box02 .tour_search_box .date .start_date {
  margin-right: 32px;
  position: relative;
}
.tour_box02 .tour_search_box .date .start_date::after {
  content: '';
  display: block;
  width: 16px;
  height: 2px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/asset/images/icn-w-linearrow-ggctour-navy.png);
  position: absolute;
  right: -24px;
  bottom: 10px;
}
.tour_box02 .tour_search_box .btn_tour_search {
  width: 65.3%;
  text-align: center;
  padding: 7px 0;
  background-color: #003c71;
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 4px 0 5px;
}
.tour_box02 .tour_search_box .btn_tour_search span {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
  margin-right: 5px;
}
.tour_box02 .tour_search_box .btn_tour_search span::before {
  content: '';
  display: inline-block;
  font-size: 0;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/asset/images/zoom-white.png);
  margin-right: 10px;
  vertical-align: -2px;
}
.tour_box02 .tour_search_box .btn_tour_search span {
  font-size: 12px;
}
/*no_result*/
.no_result_case {
  margin: 24px 0 64px;
}
.no_result_case .guide_box {
  border: 2px solid #f4f4f4;
  padding: 20px;
}
.no_result_case .guide_box .left {
  margin-bottom: 10px;
}
.no_result_case .guide_box .left .icon {
  width: 58px;
  height: 65px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/asset/images/img-search-nodata.png);
  margin: 0 auto;
}
.no_result_case .guide_box .right .g_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  word-break: keep-all;
  margin-bottom: 10px;
  text-align: center;
}
.no_result_case .guide_box .right .g_title .word {
  font-size: inherit;
  font-weight: bold;
  line-height: inherit;
  color: #003c71;
}
.no_result_case .guide_box .right .g_title .word::before {
  content: '‘';
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.no_result_case .guide_box .right .g_title .word::after {
  content: '’';
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.no_result_case .guide_box .right .list01 li {
  font-size: 9px;
}
/*-------------------------------------------------------------------
    Pages
-------------------------------------------------------------------*/
#contentsWrap {
  padding-top: 150px;
  opacity: 0;
}
#contentsWrap.main_wrap {
  padding-bottom: 38px;
}
#contentsWrap.main_wrap section {
  margin-bottom: 35px;
}
#contentsWrap.main_wrap section:last-child {
  margin-bottom: 0;
}
#contentsWrap.main_wrap section .sc_title {
  text-align: center;
}
#contentsWrap.main_wrap section .sc_title h2 {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 24px;
  color: #003c71;
}
#contentsWrap.main_wrap section .sc_title .subtitle {
  font-size: 13px;
  font-weight: 300;
  color: #003c71;
}
#contentsWrap.main_wrap .articles {
  padding-top: 16px;
}
#contentsWrap.main_wrap .articles .row {
  margin-bottom: 22px;
}
#contentsWrap.main_wrap .articles .row::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.main_wrap .articles .row:last-child {
  margin-bottom: 0;
}
#contentsWrap.main_wrap .articles .row > div {
  margin-right: 4%;
}
#contentsWrap.main_wrap .articles .row > div:last-child {
  margin-right: 0;
}
#contentsWrap.main_wrap .articles .row .col7 {
  width: 100%;
  margin-bottom: 22px;
}
#contentsWrap.main_wrap .articles .row .col3::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.main_wrap .articles .row .col3 .card_box01 {
  float: left;
  width: 30.6%;
  margin-right: 4.1%;
}
#contentsWrap.main_wrap .articles .row .col3 .card_box01:last-child {
  margin-right: 0;
}
#contentsWrap.main_wrap .articles .row .col5 {
  width: 100%;
  margin-bottom: 14px;
}
#contentsWrap.main_wrap .articles .row .col5:last-child {
  margin-bottom: 0;
}
#contentsWrap.main_wrap .news .sc_cont {
  padding-top: 10px;
}
#contentsWrap.main_wrap .news .sc_cont .tab01 .inner ul {
  width: calc(100% - 28px);
}
#contentsWrap.main_wrap .news .sc_cont .tab01 .inner ul li {
  width: 25%;
}
#contentsWrap.main_wrap .news .sc_cont .tab01_cont {
  padding-top: 16px;
}
#contentsWrap.main_wrap .news .sc_cont .tab01_cont > div {
  border-top: 1px solid #ddd;
}
#contentsWrap.main_wrap .on_going .sc_cont .tab02 .inner > ul {
  display: none ;
}
#contentsWrap.main_wrap .on_going .sc_cont .tab02_cont {
  padding-top: 10px;
}
#contentsWrap.main_wrap .on_going .sc_cont .tab02_cont > div .swiper-slide {
  width: 47.95%;
  margin-right: 4.1%;
}
#contentsWrap.main_wrap .on_going .sc_cont .tab02_cont > div .swiper-slide:last-child {
  margin-right: 0;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont {
  padding-top: 10px;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont .keyword_box {
  border: 1px solid #ddd;
  margin-bottom: 16px;
  padding: 12px;
  position: relative;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont .keyword_box .question {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.8px;
  line-height: 1.44;
  color: #003c71;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont .keyword_box .question .typing_box {
  display: inline-block;
  position: relative;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  padding-right: 3px;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont .keyword_box .question .typing_box > span {
  font-size: inherit;
  font-weight: 500;
  color: white;
  letter-spacing: inherit;
  line-height: inherit;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont .keyword_box .question .typing_box .typing_keyword {
  opacity: 0;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont .keyword_box .question .typing_box .keyword {
  background-color: #003c71;
  position: absolute;
  left: 0;
  top: 0;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont .keyword_box .question .typing_box .keyword.on {
  display: inline-block;
  animation-name: cursor;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont .swiper-container {
  padding-bottom: 32px;
}
#contentsWrap.main_wrap .ggc_guide .sc_cont .swiper-container .swiper-slide .card_box03 {
  margin-bottom: 8px;
}
#contentsWrap.main_wrap .ggc_book .sc_cont {
  padding-top: 20px;
}
#contentsWrap.main_wrap .ggc_book .sc_cont .sld_box_area {
  margin: 0 auto;
  position: relative;
}
#contentsWrap.main_wrap .ggc_book .sc_cont .sld_box_area .swiper-container {
  width: 70%;
  padding-bottom: 0;
}
#contentsWrap.main_wrap .ggc_book .sc_cont .sld_box_area .swiper-container .swiper-slide {
  width: 50%;
  padding: 0 10px;
}
#contentsWrap.main_wrap .ggc_book .sc_cont .sld_box_area .sld_prev,
#contentsWrap.main_wrap .ggc_book .sc_cont .sld_box_area .sld_next {
  position: absolute;
  top: 35%;
}
#contentsWrap.main_wrap .ggc_book .sc_cont .sld_box_area .sld_prev {
  left: 0;
}
#contentsWrap.main_wrap .ggc_book .sc_cont .sld_box_area .sld_next {
  right: 0;
}
#contentsWrap.main_wrap .ggc_issue .sc_cont .keyword_list {
  width: 200px;
  margin: 5px auto 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: relative;
  padding: 4px 0;
  text-align: center;
}
#contentsWrap.main_wrap .ggc_issue .sc_cont .keyword_list .keyword {
  font-size: 13px;
  font-weight: 300;
  color: #003c71;
}
#contentsWrap.main_wrap .ggc_issue .sc_cont .swiper-container {
  padding-bottom: 32px;
}
#contentsWrap.main_wrap .ggc_issue .sc_cont .swiper-container .swiper-slide .sld_section {
  padding-top: 24px;
}
#contentsWrap.main_wrap .ggc_issue .sc_cont .swiper-container .swiper-slide .sld_section .card_box03 {
  margin-bottom: 8px;
}
#contentsWrap.main_wrap .inside_gyeonggido {
  margin-bottom: 19px;
}
#contentsWrap.main_wrap .inside_gyeonggido .sc_cont {
  padding-top: 20px;
  font-size: 0;
}
#contentsWrap.main_wrap .inside_gyeonggido .sc_cont .box02 {
  width: 23.5%;
  margin-right: 2%;
  margin-bottom: 10px;
}
#contentsWrap.main_wrap .inside_gyeonggido .sc_cont .box02:nth-child(4n) {
  margin-right: 0;
}
#contentsWrap.main_wrap .whats_new .sc_cont {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
#contentsWrap.main_wrap .whats_new .sc_cont::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.main_wrap .whats_new .sc_cont .card_box05 {
  width: 47.95%;
  margin-right: 4.1%;
  margin-bottom: 16px;
}
#contentsWrap.main_wrap .whats_new .sc_cont .card_box05:nth-child(2n) {
  margin-right: 0;
}
#contentsWrap.sub_wrap {
  padding-bottom: 56px;
}
#contentsWrap.sub_wrap .title_section {
  padding: 25px 0;
  text-align: center;
}
#contentsWrap.sub_wrap .title_section h2 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #003c71;
  line-height: 1;
  text-transform: capitalize;
}
#contentsWrap.sub_wrap .tab01 .inner ul li a {
  font-size: 10px;
}
#contentsWrap.sub_wrap .options_box {
  margin-bottom: 12px;
}
#contentsWrap.sub_wrap .options_box .total_list {
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap .options_box .total_list p {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.42px;
}
#contentsWrap.sub_wrap .options_box .total_list p > span {
  display: inline-block;
  font-size: 0;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  color: #c4c4c4;
  margin-left: 2px;
}
#contentsWrap.sub_wrap .options_box .options_select .option_box {
  vertical-align: middle;
}
#contentsWrap.sub_wrap .options_box .options_select ul {
  width: 100%;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap .options_box .options_select ul li {
  margin-right: 5px;
}
#contentsWrap.sub_wrap .options_box .options_select ul li:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap .options_box .options_select .select_search_box {
  font-size: 0;
}
#contentsWrap.sub_wrap .options_box .options_select .select_search_box .select_search {
  width: calc(100% - 24px);
}
#contentsWrap.sub_wrap .options_box .options_select .select_search_box .btn_search {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: #003c71 url(/asset/images/icn-zoom.png) no-repeat center / 12px;
}
#contentsWrap.sub_wrap .paging_area {
  margin-top: 16px;
}
#contentsWrap.sub_wrap .btn_area {
  padding-top: 16px;
  font-size: 0;
  text-align: center;
}
#contentsWrap.sub_wrap.post_list .sld_list_section {
  margin-top: 20px;
  margin-bottom: 34px;
}
#contentsWrap.sub_wrap.post_list .archive_list_section {
  position: relative;
}
#contentsWrap.sub_wrap.post_list .archive_list_section .target_point {
  display: block;
  width: 1px;
  height: 1px;
  position: absolute;
  left: 0;
  top: -90px;
}
#contentsWrap.sub_wrap.post_list .archive_list_section .options_box {
  font-size: 0;
  margin-bottom: 12px;
}
#contentsWrap.sub_wrap.post_list .archive_list_section .options_box::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.post_list .archive_list_section .options_box ul {
  float: right;
}
#contentsWrap.sub_wrap.post_list .archive_list_section .options_box ul li {
  width: 92px;
  margin-right: 5px;
}
#contentsWrap.sub_wrap.post_list .archive_list_section .options_box ul li:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.post_list .archive_list_section .list_cont {
  display: flex;
  flex-wrap: wrap;
}
#contentsWrap.sub_wrap.post_list .archive_list_section .list_cont .card_box06 {
  width: 47.95%;
  margin-right: 4.1%;
  margin-bottom: 16px;
}
#contentsWrap.sub_wrap.post_list .archive_list_section .list_cont .card_box06:nth-child(2n) {
  margin-right: 0;
}
#contentsWrap.sub_wrap.post_list01 .sld_list_section .tab01 .inner ul {
  width: 100%;
}
#contentsWrap.sub_wrap.post_list01 .sld_list_section .tab01 .inner ul li {
  width: 50%;
}
#contentsWrap.sub_wrap.post_list01 .sld_list_section .tab01_cont {
  padding-top: 16px;
  position: relative;
}
#contentsWrap.sub_wrap.post_list01 .sld_list_section .tab01_cont .top_btn_area {
  font-size: 0;
  text-align: right;
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.post_list01 .sld_list_section .tab01_cont .swiper-container .swiper-wrapper .swiper-slide {
  width: 47.95%;
  margin-right: 4.1%;
}
#contentsWrap.sub_wrap.post_list01 .sld_list_section .tab01_cont .swiper-container .swiper-wrapper .swiper-slide:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.post_list01 .sld_list_section .tab01_cont .btn_register {
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 1;
}
#contentsWrap.sub_wrap.post_list01 .archive_list_section .list_title {
  text-align: center;
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.post_list01 .archive_list_section .list_title h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #003c71;
  line-height: 1;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_list01 .archive_list_section .list_title p {
  font-size: 13px;
  font-weight: 300;
  color: #003c71;
}
#contentsWrap.sub_wrap.post_list02 .sld_list_section .swiper-container .swiper-wrapper .swiper-slide .card_box06 .img_box .img {
  padding-top: 48.6%;
}
#contentsWrap.sub_wrap.post_list02 .sld_list_section .swiper-container .swiper-wrapper .swiper-slide .card_box06 figcaption .title {
  font-size: 16px;
  letter-spacing: -0.33px;
  line-height: 1.31;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.62em;
  -webkit-line-clamp: 2;
}
#contentsWrap.sub_wrap.post_list02 .sld_list_section .swiper-container .swiper-wrapper .swiper-slide .card_box06 figcaption .subtitle {
  font-size: 11px;
  font-weight: 300;
  line-height: 1.45;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.9em;
  -webkit-line-clamp: 2;
  white-space: normal;
}
#contentsWrap.sub_wrap.post_list02.tour .tour_box02 {
  margin-bottom: 16px;
}
#contentsWrap.sub_wrap.post_view h3 {
  font-size: 18px;
  font-weight: bold;
  color: #003c71;
  letter-spacing: -0.45px;
  margin-bottom: 20px;
  text-align: center;
}
#contentsWrap.sub_wrap.post_view h4 {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 16px;
  color: #003c71;
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.post_view .sns_box {
  text-align: center;
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view .sns_box ul {
  display: inline-block;
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view .sns_box ul li {
  margin-right: 8px;
}
#contentsWrap.sub_wrap.post_view .sns_box ul li:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.post_view .sns_box ul li a {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #003c71;
  font-size: 0;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#contentsWrap.sub_wrap.post_view .sns_box ul li a.twitter {
  background-image: url(/asset/images/icn-article-sharetwt.png);
  background-size: 15px;
}
#contentsWrap.sub_wrap.post_view .sns_box ul li a.facebook {
  background-image: url(/asset/images/icn-article-sharefb.png);
  background-size: 8px;
}
#contentsWrap.sub_wrap.post_view .sns_box ul li a.print {
  background-image: url(/asset/images/icn-article-print.png);
  background-size: 14px;
}
#contentsWrap.sub_wrap.post_view .sns_box ul li a.like {
  background-image: url(/asset/images/icn-article-like-default.png);
  background-position: top 4px center;
  background-size: 10px;
  position: relative;
}
#contentsWrap.sub_wrap.post_view .sns_box ul li a.like span {
  font-size: 12px;
  color: #fff;
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  position: absolute;
  display: block;
  width: 100%;
  bottom: 2px;
}
#contentsWrap.sub_wrap.post_view .sns_box ul li a.like.on {
  background-image: url(/asset/images/icn-article-like-act.png);
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top {
  border-top: 1px solid #003c71;
  padding: 32px 0;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top .title_box {
  margin-bottom: 15px;
  text-align: center;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top .title_box .badge_box {
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top .title_box .badge_box .badge {
  display: inline-block;
  padding: 0 4px 1px;
  min-width: 28px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  vertical-align: middle;
  margin-right: 5px;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top .title_box .badge_box .badge:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top .title_box .badge_box .badge.ongoing {
  padding: 1px 4px 2px;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top .title_box .post_title {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 5px;
  word-break: keep-all;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top .title_box .post_date {
  font-size: 12px;
  font-weight: 300;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top .img_box {
  margin-bottom: 15px;
  overflow: hidden;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_top .img_box img {
  display: block;
  margin: 0 auto;
  height: 180px;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .map_box {
  border-top: 1px solid #ddd;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box {
  padding: 15px 0;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .left dl,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .right dl {
  float: none;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .left dl::after,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .right dl::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .left dl:last-child,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .right dl:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .left dl dt,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .right dl dt,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .left dl dd,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .right dl dd {
  float: left;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .left dl dt,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .right dl dt {
  width: 55px;
  font-size: 10px;
  font-weight: bold;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .left dl dd,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .right dl dd {
  width: calc(100% - 55px);
  font-size: 10px;
  font-weight: 300;
  word-break: keep-all;
  line-height: 1.8;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .left dl dd a,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .info_box .cont .right dl dd a {
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .map_box {
  padding: 30px 0 36px;
  text-align: center;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .map_box .wrap04 {
  max-width: 82.6%;
  margin: 0 auto;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .map_box .wrap04 .map {
  position: relative;
  padding-top: 57.6%;
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .map_box .wrap04 .map iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .map_box .wrap04 .address {
  display: inline-block;
  font-size: 0;
  float: none;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .map_box .wrap04 .address::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .map_box .wrap04 .address dt,
#contentsWrap.sub_wrap.post_view01 .post_view_area .view_bottom .map_box .wrap04 .address dd {
  float: left;
  font-size: 10px;
  font-weight: 500;
}
#contentsWrap.sub_wrap.post_view01 .gallery_area {
  padding: 16px 0 24px;
  background-color: #f8f8f8;
  text-align: center;
}
#contentsWrap.sub_wrap.post_view01 .gallery_area .sld_box_area {
  position: relative;
}
#contentsWrap.sub_wrap.post_view01 .gallery_area .sld_box_area .swiper-container {
  padding-bottom: 0;
}
#contentsWrap.sub_wrap.post_view01 .gallery_area .sld_box_area .swiper-container .swiper-wrapper .swiper-slide {
  width: auto;
  margin-right: 10px;
}
#contentsWrap.sub_wrap.post_view01 .gallery_area .sld_box_area .swiper-container .swiper-wrapper .swiper-slide:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.post_view01 .gallery_area .sld_box_area .sld_next,
#contentsWrap.sub_wrap.post_view01 .gallery_area .sld_box_area .sld_prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#contentsWrap.sub_wrap.post_view01 .gallery_area .sld_box_area .sld_prev {
  left: -38px;
}
#contentsWrap.sub_wrap.post_view01 .gallery_area .sld_box_area .sld_next {
  right: -38px;
}
#contentsWrap.sub_wrap.post_view01 .recommend_area {
  padding-top: 35px;
}
#contentsWrap.sub_wrap.post_view01 .recommend_area .swiper-container .swiper-wrapper .swiper-slide {
  width: 47.95%;
  margin-right: 4.1%;
}
#contentsWrap.sub_wrap.post_view01 .recommend_area .swiper-container .swiper-wrapper .swiper-slide:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.post_view02 {
  padding-bottom: 40px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area {
  padding-top: 16px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .wrap02 {
  padding: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_top .img_box {
  margin-bottom: 16px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_top .img_box .img {
  padding-top: 48.5%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: lightgrey;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_top .title_box {
  margin-bottom: 20px;
  text-align: center;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_top .title_box > p {
  line-height: 1;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_top .title_box .group {
  font-size: 10px;
  font-weight: 500;
  color: #003c71;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_top .title_box .title {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.25;
  word-break: keep-all;
  letter-spacing: -0.83px;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_top .title_box .subtitle {
  font-size: 12px;
  font-weight: 300;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid {
  border-top: 1px solid #ddd;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box {
  padding: 30px 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box p,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box span,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box strong,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box b,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box u,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box a,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box i,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box div {
  line-height: 1.6;
  font-weight: 500;
  font-size: 10px !important;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box strong,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box b {
  font-weight: bold;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box a {
  text-decoration: underline;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box img,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box iframe,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box video {
  max-width: 100%;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table {
  width: 100%;
  margin: 10px 0;
  table-layout: fixed;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td {
  font-size: 10px;
  border: 1px solid #ddd;
  padding: 12px 6px;
  word-break: keep-all;
  font-weight: 500;
  text-align: center;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th {
  font-weight: bold;
  background-color: #f8f8f8;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th p,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th span,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th strong,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th b,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th u,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th a,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th i,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th div {
  font-size: inherit;
  font-weight: inherit;
  word-break: keep-all;
  text-align: inherit;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th strong,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table th b {
  font-weight: bold;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td {
  line-height: 1.6;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td p,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td span,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td strong,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td b,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td u,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td a,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td i,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td div {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  word-break: keep-all;
  text-align: inherit;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td strong,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box table td b {
  font-weight: bold;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box .note-float-right,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box .note-float-left {
  margin: 10px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .post_box .copyright_phrase {
  margin-top: 16px;
  font-size: 9px;
  font-weight: 500;
  color: #4a4a4a;
  text-align: center;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box {
  border-top: 1px solid #ddd;
  padding: 15px 0 25px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row {
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row h4 {
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont {
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > dl {
  float: none;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > dl::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > dl dt,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > dl dd {
  float: left;
  font-size: 10px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > dl dt {
  width: 50px;
  font-weight: bold;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > dl dd {
  width: calc(100% - 50px);
  font-weight: 300;
  line-height: 1.57;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > dl dd > a {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: underline;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > ul li {
  float: none;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > ul li:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > ul li .ui-sortable-handle {
  display: flex;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.57;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > ul li .ui-sortable-handle:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > ul li .ui-sortable-handle span {
  display: block;
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 5px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont > ul li .ui-sortable-handle > a {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont .pebble_box {
  display: inline-block;
  font-size: 0;
  padding: 3px 7px;
  border-radius: 12px;
  background-color: #e8e8e8;
  margin-right: 5px;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont .pebble_box:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont .pebble_box span {
  font-size: 10px;
  font-weight: 500;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont .pebble_box span::before {
  font-size: inherit;
  font-weight: inherit;
  display: inline;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont .hashtag {
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont .hashtag .pebble_box span::before {
  content: '#';
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont .at {
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row .cont .at .pebble_box span::before {
  content: '@';
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_mid .info_box .row.sns_box {
  margin-top: 20px;
  text-align: left;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot {
  border-top: 1px solid #ddd;
  padding: 16px 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .left,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .right {
  float: left;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .img_box {
  width: 64px;
  padding: 5px;
  border: 1px solid #c4c4c4;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .img_box .img {
  width: 100%;
  padding-top: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: lightgrey;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .right {
  width: calc(100% - 64px);
  padding-left: 10px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .right dl {
  float: none;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .right dl::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .right dl dt,
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .right dl dd {
  float: left;
  font-size: 10px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .right dl dt {
  font-weight: bold;
  color: #003c71;
  margin-right: 2px;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .right dl dt::after {
  content: '/';
  font-weight: inherit;
  color: inherit;
}
#contentsWrap.sub_wrap.post_view02 .post_view_area .post_bot .right dl dd {
  font-weight: 500;
}
#contentsWrap.sub_wrap.post_view02 .recommend_area .row {
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.post_view02 .recommend_area .row:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.post_view02 .recommend_area .row h3 {
  text-align: center;
  margin-bottom: 15px;
}
#contentsWrap.sub_wrap.post_view02 .recommend_area .row .sld_box_area::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.post_view02 .recommend_area .row .sld_box_area .card_box06 {
  float: left;
  width: 47.95%;
  margin-right: 4.1%;
  margin-bottom: 20px;
}
#contentsWrap.sub_wrap.post_view02 .recommend_area .row .sld_box_area .card_box06:nth-child(2n) {
  margin-right: 0;
}
#contentsWrap.sub_wrap.search_result .wrap02 {
  padding-top: 16px;
}
#contentsWrap.sub_wrap.search_result .panel01 {
  margin-bottom: 40px;
}
#contentsWrap.sub_wrap.search_result .panel01:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.search_result .panel01 .panel_cont .list_boxes {
  border-top: 1px solid #003c71;
  border-bottom: 1px solid #003c71;
  padding: 2px 0;
  margin-bottom: 15px;
}
#contentsWrap.sub_wrap.search_result .panel01 .panel_cont .list_boxes .list_box02:last-child {
  border-bottom: none;
}
#contentsWrap.sub_wrap.culture_events .tab01 .inner ul {
  width: 100%;
}
#contentsWrap.sub_wrap.culture_events .tab01 .inner ul li {
  width: 20%;
}
#contentsWrap.sub_wrap.culture_events .tab_cont {
  padding-top: 16px;
}
#contentsWrap.sub_wrap.culture_events .tab_cont .options_box .options_select ul li {
  width: calc((100% - 5px*2)/3);
}
#contentsWrap.sub_wrap.culture_events .tab_cont .lists_box {
  display: flex;
  flex-wrap: wrap;
}
#contentsWrap.sub_wrap.culture_events .tab_cont .lists_box .card_box02 {
  width: 47.95%;
  margin-right: 4.1%;
  margin-bottom: 16px;
}
#contentsWrap.sub_wrap.culture_events .tab_cont .lists_box .card_box02:nth-child(2n) {
  margin-right: 0;
}
#contentsWrap.sub_wrap.news_list .tab01 .inner ul {
  width: 100%;
}
#contentsWrap.sub_wrap.news_list .tab01 .inner ul li {
  width: 20%;
}
#contentsWrap.sub_wrap.news_list .tab_cont .tab_title_box {
  padding: 20px 0;
  text-align: center;
}
#contentsWrap.sub_wrap.news_list .tab_cont .tab_title_box h3 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
}
#contentsWrap.sub_wrap.news_list .tab_cont .options_box {
  position: relative;
}
#contentsWrap.sub_wrap.news_list .tab_cont .options_box .options_select ul li {
  width: calc((100% - 5px)/2);
}
#contentsWrap.sub_wrap.news_list .tab_cont .options_box .btn_register {
  position: absolute;
  right: 0;
  top: -6px;
}
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .th:nth-child(1),
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .td:nth-child(1) {
  display: none ;
}
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .th:nth-child(2),
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .td:nth-child(2) {
  width: 100%;
}
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .th:nth-child(3),
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .td:nth-child(3) {
  display: none ;
}
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .th:nth-child(4),
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .td:nth-child(4) {
  display: none ;
}
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .th:nth-child(5),
#contentsWrap.sub_wrap.news_list .tab_cont .lists_box .table01 .tr .td:nth-child(5) {
  display: none ;
}
#contentsWrap.sub_wrap.news_view .post_view_area {
  border-top: 1px solid #003c71;
  border-bottom: 1px solid #003c71;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box {
  padding: 16px 0 20px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .title_list::after {
  content: '';
  display: block;
  width: 20px;
  height: 1px;
  background-color: #333;
  margin: 10px auto;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .title_list .badge_box {
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .title_list .badge_box .badge {
  display: inline-block;
  font-size: 0;
  padding: 3px 3px;
  min-width: 28px;
  font-size: 9px;
  color: #fff;
  line-height: 1;
  text-align: center;
  margin-right: 4px;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .title_list .badge_box .badge:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .title_list .post_title {
  margin: 0 auto;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.83px;
  word-break: keep-all;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .title_list .group {
  font-size: 12px;
  font-weight: 300;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list {
  display: inline-block;
  font-size: 0;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li {
  margin-right: 20px;
  position: relative;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li::after {
  content: '';
  display: block;
  width: 1px;
  height: 8px;
  background-color: #e8e8e8;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li:last-child::after {
  display: none ;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li dl {
  float: none;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li dl::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li dl dt,
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li dl dd {
  float: left;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li dl dt {
  font-size: 9px;
  font-weight: 500;
  padding-right: 5px;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li dl dd {
  font-size: 10px;
  font-weight: 300;
}
#contentsWrap.sub_wrap.news_view .post_view_area .title_box .info_list li dl.period dd {
  color: #003c71;
  font-weight: 500;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box {
  padding: 20px 0 25px;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box p,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box span,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box strong,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box b,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box u,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box a,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box i,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box div {
  line-height: 1.6;
  font-weight: 500;
  font-size: 10px !important;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box strong,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box b {
  font-weight: bold;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box a {
  text-decoration: underline;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box img,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box iframe,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box video {
  max-width: 100%;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table {
  width: 100%;
  margin: 10px 0;
  table-layout: fixed;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td {
  font-size: 10px;
  border: 1px solid #ddd;
  padding: 12px 6px;
  word-break: keep-all;
  font-weight: 500;
  text-align: center;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th {
  font-weight: bold;
  background-color: #f8f8f8;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th p,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th span,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th strong,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th b,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th u,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th a,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th i,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th div {
  font-size: inherit;
  font-weight: inherit;
  word-break: keep-all;
  text-align: inherit;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th strong,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table th b {
  font-weight: bold;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td {
  line-height: 1.6;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td p,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td span,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td strong,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td b,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td u,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td a,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td i,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td div {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  word-break: keep-all;
  text-align: inherit;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td strong,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box table td b {
  font-weight: bold;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box .note-float-right,
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .post_box .note-float-left {
  margin: 10px;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .cf_file_box {
  border: 2px solid #f4f4f4;
  margin-top: 20px;
  padding: 12px;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .cf_file_box .title {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .cf_file_box .download_list .btn_file_download {
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.news_view .post_view_area .post_view_box .cf_file_box .download_list .btn_file_download:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.article .search_box01 {
  border: 2px solid #f4f4f4;
  padding: 5px 10px 8px;
  text-align: center;
  margin: 0 auto 15px;
}
#contentsWrap.sub_wrap.article .search_box01 > div .option_box {
  vertical-align: middle;
}
#contentsWrap.sub_wrap.article .search_box01 > div ul {
  width: 100%;
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.article .search_box01 > div ul li {
  margin-right: 5px;
}
#contentsWrap.sub_wrap.article .search_box01 > div ul li:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.article .search_box01 > div .select_search_box {
  font-size: 0;
}
#contentsWrap.sub_wrap.article .search_box01 > div .select_search_box .select_search {
  width: calc(100% - 24px);
}
#contentsWrap.sub_wrap.article .search_box01 > div .select_search_box .btn_search {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: #003c71 url(/asset/images/icn-zoom.png) no-repeat center / 12px;
}
#contentsWrap.sub_wrap.article .search_box01 > div::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.article .search_box01 > div ul {
  float: left;
  width: auto;
  margin-right: 5px;
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.article .search_box01 > div ul li {
  width: 81px;
}
#contentsWrap.sub_wrap.article .search_box01 > div .select_search_box {
  float: left;
  margin-top: 3px;
  width: calc(100% - 81px - 5px);
}
#contentsWrap.sub_wrap.article .tab01 .inner ul {
  width: 100%;
}
#contentsWrap.sub_wrap.article .tab01 .inner ul li {
  width: 16.66666667%;
}
#contentsWrap.sub_wrap.article .tab_cont {
  padding-top: 12px;
}
#contentsWrap.sub_wrap.article .tab_cont .options_box {
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.article .tab_cont .options_box::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.article .tab_cont .options_box .left {
  float: left;
}
#contentsWrap.sub_wrap.article .tab_cont .options_box .right {
  float: right;
}
#contentsWrap.sub_wrap.article .tab_cont .options_box .total_list {
  margin-bottom: 0;
  margin-top: 10px;
}
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .th:nth-child(1),
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .td:nth-child(1) {
  display: none ;
}
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .th:nth-child(2),
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .td:nth-child(2) {
  width: 100%;
}
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .th:nth-child(3),
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .td:nth-child(3) {
  width: 100%;
}
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .th:nth-child(4),
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .td:nth-child(4) {
  display: none ;
}
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .th:nth-child(5),
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .td:nth-child(5) {
  display: none ;
}
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .th:nth-child(6),
#contentsWrap.sub_wrap.article .tab_cont .lists_box .table02 .td:nth-child(6) {
  position: absolute;
  top: 0;
  right: 0;
}
#contentsWrap.sub_wrap.write_form .tab01 .inner ul {
  width: 100%;
}
#contentsWrap.sub_wrap.write_form .tab01 .inner ul li {
  width: 20%;
}
#contentsWrap.sub_wrap.write_form .tab_cont {
  padding-top: 16px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box {
  padding: 10px 0 20px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row {
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row .col {
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row .col:nth-child(even) {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl {
  float: none;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dt {
  margin-bottom: 5px;
  position: relative;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dt > span {
  font-weight: 500;
  font-size: 11px;
  color: #3a3a3a;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dt.ls_wide > span {
  letter-spacing: 2.93px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dt .cmt {
  display: block;
  font-size: 9px;
  font-weight: bold;
  color: #003c71;
  letter-spacing: normal;
  word-break: keep-all;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dt .required_cmt {
  font-size: 9px;
  font-weight: 500;
  color: #3a3a3a;
  position: absolute;
  right: 0;
  top: 0;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dt .required_cmt::before {
  content: '*';
  font-weight: 500;
  color: #ff0000;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dd {
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dd:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dd.period::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dd.period .input_box01 {
  float: left;
  position: relative;
  width: calc((100% - 13px) / 2);
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dd.period .start {
  margin-right: 12px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dd.period .start::after {
  content: '';
  display: block;
  width: 6px;
  height: 1px;
  background-color: #c4c4c4;
  position: absolute;
  right: -10px;
  top: 11px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dd.multi::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dd.multi > .input_box01 {
  float: left;
  width: calc((100% - 11px)/2);
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl dd.multi > .input_box01:first-child {
  margin-right: 10px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .row dl.required dt::after {
  content: '*';
  font-size: 11px;
  font-weight: 500;
  color: #ff0000;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .agreement_box {
  border: 2px solid #f4f4f4;
  padding: 15px 16px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .agreement_box .all_check_box .check_box02 {
  text-indent: -2px;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .precaution_box {
  border: 2px solid #f4f4f4;
  padding: 15px 15px 20px;
  position: relative;
}
#contentsWrap.sub_wrap.write_form .tab_cont .form_box .precaution_box .title {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.introduce .title_section {
  margin-top: 16px;
  padding: 30px 0;
  background: #07294e url(/asset/images/img-ggc-intro-bg.jpg) no-repeat center / auto 100%;
}
#contentsWrap.sub_wrap.introduce .title_section h2 {
  color: white;
}
#contentsWrap.sub_wrap.introduce .contents_section {
  border-top: 1px solid #003c71;
  padding-top: 25px;
}
#contentsWrap.sub_wrap.introduce .contents_section .panel02 {
  margin-bottom: 24px;
}
#contentsWrap.sub_wrap.introduce .contents_section .panel02:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_introduce .panel_cont p {
  margin-bottom: 5px;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_introduce .panel_cont p:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont {
  padding-top: 10px;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont .img_box {
  font-size: 0;
  text-align: center;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont .img_box img {
  width: 100%;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont .left {
  margin-bottom: 24px;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont .right .img_box {
  width: 74.2%;
  margin: 0 auto;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_logo .panel_cont {
  padding-top: 5px;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_logo .panel_cont .list_box03 {
  margin-bottom: 10px;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_logo .panel_cont .list_box03:last-child {
  margin-bottom: 0;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_logo .panel_cont .list_box03:nth-child(1) .img_box img {
  height: 81px;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_logo .panel_cont .list_box03:nth-child(2) .img_box img {
  height: 33px;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_logo .panel_cont .list_box03 .right {
  font-size: 0;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_logo .panel_cont .list_box03 .right .btn_download {
  width: calc((100% - 12px)/2);
  margin-right: 12px;
}
#contentsWrap.sub_wrap.introduce .contents_section .ggc_logo .panel_cont .list_box03 .right .btn_download:last-child {
  margin-right: 0;
}
#contentsWrap.sub_wrap.open_api .wrap06 {
  padding: 0 16px;
}
#contentsWrap.sub_wrap.open_api .tab01 .inner ul {
  width: 100%;
}
#contentsWrap.sub_wrap.open_api .tab01 .inner ul li {
  width: 50%;
}
#contentsWrap.sub_wrap.open_api .tab_title_box02 {
  padding: 20px 0 20px;
  text-align: center;
}
#contentsWrap.sub_wrap.open_api .tab_title_box02 h3 {
  font-size: 15px;
  font-weight: bold;
  color: #003c71;
  margin-bottom: 4px;
}
#contentsWrap.sub_wrap.open_api .tab_title_box02 .subtitle {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  color: #3a3a3a;
  word-break: keep-all;
}
#contentsWrap.sub_wrap.open_api .btn_area {
  display: flex;
  justify-content: flex-end;
  padding-top: 0;
}
#contentsWrap.sub_wrap.open_api .panel05 {
  margin-bottom: 20px;
}
#contentsWrap.sub_wrap.open_api .panel05:last-of-type {
  margin-bottom: 0;
}
/*-------------------------------------------------------------------
    Lang : en
-------------------------------------------------------------------*/
.en header .top_gnb_area > div > ul {
  margin-right: 0;
}
.en header .main_gnb_area nav ul li a {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
}
.en header .site_map .wrap01 .group1 a span {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-weight: 400;
  font-size: 21px;
}
.en header .site_map .wrap01 .group1 a.impt span {
  font-weight: 900;
}
.en header .site_map .wrap01 .group3 dl dt a {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
  font-size: 20px;
}
.en footer .g2.left address {
  max-width: 80%;
}
.en .card_box01 a.ver2 figcaption .subtitle {
  font-size: 10px;
}
.en .card_box01 a.ver3 figcaption .title {
  line-height: 1.27;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 3.81em;
  -webkit-line-clamp: 3;
}
.en .card_box01 a.ver3 figcaption .subtitle {
  line-height: 1.45;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.9em;
  -webkit-line-clamp: 2;
}
.en .card_box03 a figcaption .subtitle {
  white-space: normal;
  line-height: 1.44;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.88em;
  -webkit-line-clamp: 2;
}
.en .card_box05 a figcaption .title {
  line-height: 1.29;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 3.87em;
  -webkit-line-clamp: 3;
}
.en .panel02 .panel_tit {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
}
.en #contentsWrap.sub_wrap .title_section h2 {
  font-family: 'ggcfStd', sans-serif;
  font-weight: 900;
}
.en #contentsWrap.sub_wrap.introduce .title_section h2 {
  text-transform: none;
}
.en #contentsWrap.sub_wrap.introduce .contents_section .ggc_introduce .panel_cont p {
  margin-bottom: 0;
}
.en #contentsWrap.sub_wrap.introduce .contents_section .ggc_introduce .panel_cont p.bold {
  font-size: 11px;
  font-weight: bold;
}
.en #contentsWrap.sub_wrap.introduce .contents_section .ggc_introduce .panel_cont p.bold span.eFont {
  font-size: 14px;
}
.en #contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont .col {
  margin-bottom: 15px;
}
.en #contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont .col::after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
}
.en #contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont .col:last-child {
  margin-bottom: 0;
}
.en #contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont .col .left {
  margin-bottom: 10px;
}
.en #contentsWrap.sub_wrap.introduce .contents_section .ggc_service .panel_cont .col .left img {
  display: block;
  margin: 0 auto 10px;
  width: 30%;
}
/*-------------------------------------------------------------------
    Keyframes
-------------------------------------------------------------------*/
@keyframes cursor {
  0% {
    border-right: 2px solid #fff;
  }
  50% {
    border-right: 2px solid #003c71;
  }
  100% {
    border-right: 2px solid #fff;
  }
}
